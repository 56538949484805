import React,{useState} from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, MuiUploader, PhoneInput, SelectField,ColorPicker } from "../FormFields";
import { Accordion, Card } from "react-bootstrap";

export default function UpdateRules(props) {
 
    const {
        formField: {
            rulename
        },
        values,
        setFieldValue
    } = props;

   
    return (
        <React.Fragment>
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center"> Update Rule </Typography>
            <p className="mb-1 text-center"> Update Your Unique Rules. </p>
            <p className="mb-10 text-center"> Add Different Filters for Each Rules </p>
            <hr className="w-75 pb-15 my-auto" />
            <Grid justify="center" alignItems="center" spacing={3}>
                <Grid container justify="center" alignItems="center" className="mb-10" spacing={3}>
                    <Grid item xs={12} md={7}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-lg font-weight-bold text-dark small">
                                    Rule Name *
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <InputField
                                    name={rulename.name}
                                    // label={rulename.label}
                                    fullWidth
                                    disabled={true}
                                    // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <hr className="w-100 m-0 mt-8" />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
