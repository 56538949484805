/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    white_label_logo,
    wl_primarycolor,
    n_fontcolor,
    lab_primary,
    lab_fontcolor,
    theme,
    password,
    cofirm_password,
    email,
    muti_currency,
    tax_value,
    tax_name
  }
} = kycFormModel;

const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
const numberMatch = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/
const taxnameMatch = /^[a-zA-Z0-9-_]+$/
export default function ValidationSchemaEditTax(taxinclusive){
  let validationarray = []
  if(taxinclusive){
    validationarray = [
      Yup.object().shape({
        [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
        [tax_value.name]: Yup.string().required(`${tax_value.requiredErrorMsg}`).matches(numberMatch,"Tax Value must be numeric with 2 decimal places and greater than zero"),
        [tax_name.name]: Yup.string().required(`${tax_name.requiredErrorMsg}`).matches(taxnameMatch,"Only Alpha Numeric & Hyphen are allowed")
      })
    ]
  }
    return validationarray
  };
