import React from "react";
import { Link } from "react-router-dom";
import styles from "../css/style.module.css"


const NotFound = () =>{
    return (
        <>
            <div className="container my-5">
                <div className={`row container align-items-center justify-content-center ${styles.notFoundvh}`}>
                    <div className="col-12 text-center">
                        <h2 className="m-4 display-1 font-weight-bold text-center text-dark">404</h2>
                        <p className="text-center text-muted">Page not found</p>
                        <p className="m-0">We're sorry, the page you requested could not be found.</p>
                        <p className="m-0">Please go back to home-page or contact us at  <a href="mailto:info@diamondsoncall.com">info@diamondsoncall.com</a></p>
                        <div className="text-center">
                            <Link to="/" className="btn btn-primary mt-4 px-10 " >Go to Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NotFound;