import React from "react"
import BlogMockdata from "./BlogMockdata"
import Blogcard from "./Blogcard";
import styles from "../../css/style.module.css"

const Blog = () =>{
    return(
        <>
            <div className={styles.bgF6f8fc}>
				<div className="container py-12 px-4" >
					<div className="row justify-content-center" >
						<div className="col-12 text-center" data-aos="fade-up" data-aos-once="true">
							<h2 className={styles.customH2Title}>Latest From Blog</h2>
						</div>
					</div>
					<div className={`row ${styles.blog} justify-content-center`}>
						{BlogMockdata.map((val) => {
							return(
								<Blogcard 
									key={val.id}
									imgsrc={val.imgsrc}
									title={val.title}
									auther={val.auther}
									content={val.content}
								/>
							);    
						})}
					</div>
					<div className="row justify-content-center mt-12 ">
						<div className="col-md-12 text-center pr-0">
							<a href="/blog" className={`btn ${styles.btnPrimary}`}>Read more</a>
						</div>
					</div>
				</div>
			</div>
        </>
    )
}

export default Blog;