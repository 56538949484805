/* eslint-disable */
export default {
    formId: 'kycForm',
    formField: {
      wl_naturaldiamond: {
        name: 'wl_naturaldiamond',
        label: 'Colorless Diamonds',
        icon: 'white_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      wl_naturalfancydiamond: {
        name: 'wl_naturalfancydiamond',
        label: 'Fancy Color Diamonds',
        icon: 'fancy_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      wl_lgwhitediam: {
        name: 'wl_lgwhitediam',
        label: 'Colorless Diamonds',
        icon: 'lab_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      wl_lgfancydiam: {
        name: 'wl_lgfancydiam',
        label: 'Fancy Color Diamonds',
        icon: 'lab_fancy_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      muti_currency: {
        name: 'muti_currency',
        label: 'Select Curreny',
        requiredErrorMsg: 'Please Select Any currency'
      },
      diamondmarkup: {
        name: 'diamondmarkup',
        label: 'Natural White Diamond Markup',
        requiredErrorMsg: 'Natural White Diamond Markup is Required'
      },
      diamondfancymarkup: {
        name: 'diamondfancymarkup',
        label: 'Natural Fancy Diamond Markup',
        requiredErrorMsg: 'Natural Fancy Diamond Markup is Required'
      },
      lab_diamondmarkup: {
        name: 'lab_diamondmarkup',
        label: 'Lab Grown White Diamond Markup',
        requiredErrorMsg: 'Lab Grown White Diamond Markup is Required'
      },
      lab_diamondfancymarkup: {
        name: 'lab_diamondfancymarkup',
        label: 'Lab Grown Fancy Diamond Markup',
        requiredErrorMsg: 'Lab Grown Fancy Diamond Markup is Required'
      },
      white_label_logo: {
        name: 'white_label_logo',
        label: 'Upload Logo',
        requiredErrorMsg: 'Logo is Required'
      },
      wl_primarycolor: {
        name: 'wl_primarycolor',
        label: 'Natural Primary Color',
        requiredErrorMsg: 'Natural Primary Color is Required'
      },
      n_fontcolor: {
        name: 'n_fontcolor',
        label: 'Natural Font Color',
        requiredErrorMsg: 'Natural Font Color is Required'
      },
      lab_primary: {
        name: 'lab_primary',
        label: 'Labgrown Primary Color',
        requiredErrorMsg: 'Labgrown Primary Color is Required'
      },
      lab_fontcolor: {
        name: 'lab_fontcolor',
        label: 'Labgrown Font Color',
        requiredErrorMsg: 'Labgrown Font Color is Required'
      },
      theme: {
        name: 'theme',
        label: 'Select Theme',
        classes: 'polishes',
        requiredErrorMsg: 'Theme selection is Required'
      },
      tax_value: {
        name: 'tax_value',
        label: 'Tax Value %',
        requiredErrorMsg: 'Tax Value is Required'
      },
      tax_name: {
        name: 'tax_name',
        label: 'Tax Name',
        requiredErrorMsg: 'Tax Name is Required'
      },
      password: {
        name: 'password',
        label: 'Password',
        requiredErrorMsg: 'Password is Required'
      },
      cofirm_password: {
        name: 'cofirm_password',
        label: 'Confirm Password',
        requiredErrorMsg: 'Confirm Password is Required'
      },
      email: {
        name: 'email',
        label: 'Email',
        requiredErrorMsg: 'Email id is required'
      },
      taxinclusive:{
        name: 'taxinclusive',
        label: 'taxinclusive',
        requiredErrorMsg: 'taxinclusive is required'
      },
      displayName:{
        name: 'displayName',
        label: 'Display Name',
        requiredErrorMsg: 'displayName is required'
      },
      background_image: {
        name: 'background_image',
        label: 'Upload Background Image',
        requiredErrorMsg: 'Background Image is Required'
      },
      nav_bar_color: {
        name: 'nav_bar_color',
        label: 'Nav Bar Color',
        requiredErrorMsg: 'Nav Bar Color is Required'
      },
      displaybuybtn: {
        name: 'displaybuybtn',
        label: 'Display Buy Button',
        requiredErrorMsg: 'Display Buy Button is Required'
      },
      background_color: {
        name: 'background_color',
        label: 'Background Color',
        requiredErrorMsg: 'Background Color is Required'
      },
      nav_bar_font: {
        name: 'nav_bar_font',
        label: 'Nav Bar Font Color',
        requiredErrorMsg: 'Nav Bar Font Color is Required'
      }
    }
  };
  