import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function DashboardSkeleton() {
    return (
        <>
            <div className="row m-0 mb-3 rounded p-4 shadow-sm bg-white">
                <div className="col-12 justify-content-md-between d-flex align-items-center">
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="text" width={200} height={30} />
                    <Skeleton variant="text" width={180} />
                    {/* For other variants, adjust the size with `width` and `height` */}
                </div>
            </div>
            <div className="row gutter-b mt-5">
                {Array.from(Array(4), (e, i) => {
                    return (
                        <div className="col-lg-3 col-md-6" key={i}>
                            <Skeleton variant="rect" height={153} />
                        </div>
                    );
                })}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="card card-custom bg-white gutter-b">
                  <div className="card-body">
                    <Skeleton variant="text" width={100} />
                    <div className="d-flex mt-5">
                      {Array.from(Array(2), (e, i) => {
                          return (
                            <span className="px-4" key={i}><Skeleton variant="text" width={120}  /></span>
                          );
                      })}
                    </div>
                    <div className="row mt-5 mb-4 justify-content-between">
                      {Array.from(Array(2), (e, i) => {
                        return (
                          <span className="col-md-6" key={i}><Skeleton variant="text" height={150} /></span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card bg-white card-custom gutter-b">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mt-5">
                      <Skeleton variant="text" width={100} />
                      <Skeleton variant="text" width={50} />
                    </div>
                      {Array.from(Array(3), (e, i) => {
                        return (
                          <div className="d-flex mt-5 justify-content-between align-items-end border-bottom pb-3 px-3" key={i}>
                            <div>
                              <div className="d-flex align-items-center">
                                <Skeleton variant="circle" width={15} height={15} />
                                <span className="mr-10 ml-2"><Skeleton variant="text" width={100} /></span>
                                <span><Skeleton variant="text" width={100} /></span>
                              </div>
                              <Skeleton variant="text" width={280} />
                            </div>
                            <Skeleton variant="text" width={80} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row gutter-b">
              <div className="col-md-8">
                <div className={`card card-custom bg-white gutter-b`}>
                  <div className="card-body">
                    <Skeleton variant="text" width={100} />
                    <div className="d-flex flex-nowrap customRecently custom-scrollbar my-10">
                    {Array.from(Array(4), (e, i) => {
                        return (
                          <div className="mb-5 px-2" style={{width:"280px"}} key={i}>
                            <div className="row m-0 card rounded-0">
                              <div className="col-12 p-0 d-flex align-items-center justify-content-center" >
                                <Skeleton variant="rect" height={220} width={280} />
                              </div>
                              <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                <Skeleton variant="text" width={60} />
                                <Skeleton variant="text" width={60} />
                                <Skeleton variant="text" width={20} />
                              </div>
                              <hr className="w-100 my-2" />
                              <div className="col-12 d-flex px-2 align-items-center">
                                <span className="mr-3"><Skeleton variant="circle" width={35} height={35} /></span>
                                <Skeleton variant="text" width={200} height={30}/>
                              </div>
                              <hr className="w-100 my-2" />
                              <div className="col-12 d-flex justify-content-between align-items-center">
                                <Skeleton variant="text" width={100}  height={30}/>
                                <Skeleton variant="text" width={100}  height={30}/>
                              </div>
                              <hr className="w-100 my-2" />
                              <div className="col-12 d-flex justify-content-between align-items-center">
                                <Skeleton variant="text" width={80} />
                                <Skeleton variant="text" width={40} />
                                <Skeleton variant="text" width={40} />
                                <Skeleton variant="text" width={20} />
                              </div>
                              <hr className="w-100 my-2" />
                              <div className="col-12 mb-2">
                                <Skeleton variant="text" width={180} />
                                <Skeleton variant="text" width={140} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row gutter-b">
                  <div className="col-12 d-flex justify-content-center mb-10">
                    <Skeleton variant="rect" height={400} width={500} />
                  </div>
                  <div className="col-12">
                    <div className={`card bg-white card-custom gutter-b`}>
                      <div className="card-body salesExecutiveTile">
                        <Skeleton variant="text" width={100} />
                        <div className="d-flex align-items-center mt-8">
                          <span className="mr-3"><Skeleton variant="circle" width={86} height={86} /></span>
                            <div>
                              <div className="mb-3"><Skeleton variant="text" width={100} height={30}/></div>
                              <Skeleton variant="text" width={180} />
                              <Skeleton variant="text" width={220} />
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>
    );
}
