import React from "react"
import hongkong_icon from "../../Images/hongkong_icon.svg"
import usa_icon from "../../Images/usa_icon.svg"
import australia_icon from "../../Images/australia_icon.svg"
import india_icon from "../../Images/india_icon.svg"
import styles from "../../css/style.module.css"

const LocalNumbers = () =>{
    return(
        <>
            <div className="container-fluid bg-white">
                <div className="container mt-8 mb-12">
                    <div className="row pt-5 justify-content-center " data-aos="fade-up" data-aos-once="true">
                        <div className="col-lg-12 text-center">
                            <h2 className={styles.customH2Title}>Call Sales using one of our local numbers</h2>
                        </div>
                    </div>
                    <div className={`row justify-content-center ${styles.ct_sales} mt-3 pt-6`}>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end" data-aos="fade-up" data-aos-once="true">
                            <img className="pb-8" src={hongkong_icon} alt="hongkong" title="Hong Kong" height="100" />
                            <h4>Hong Kong</h4>
                            <a href="tel:+85281999581">+852 8199 9581</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                            <img className="pb-8" src={usa_icon} alt="usa" title="USA" height="100" />
                            <h4>USA / Canada</h4>
                            <a href="tel:+14158004257">+1 415 800 4257</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                            <img className="pb-8" src={australia_icon} alt="australia" title="Australia" height="66.77" />
                            <h4>Australia</h4>
                            <a href="tel:+610280037220">+61 02 8003 7220</a>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center p-8 align-self-end" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                            <img className="pb-8" src={india_icon} alt="india" title="India" height="100" />
                            <h4>India</h4>
                            <a href="tel:+919978644970">+91 99786 44970</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LocalNumbers;
