import React from "react";
import { Accordion, Card } from "react-bootstrap";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";
import { Brand } from "./Additional/Brand";
import { KeytoSymbol } from "./Additional/KeytoSymbol";
import { Origin } from "./Additional/Origin";
import { Treatment } from "./Additional/Treatment";
import { LabTreatment } from "./Additional/LabTreatment";
import { GrowthType } from "./Additional/GrowthType";
import { Type } from "./Additional/Type";

export const Additional = (props) => {
       return (
        <>
            <Accordion className="search_accordion">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {props.language.MUI_ADDITIONALDETAILS}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            {props.diamond_type === "N" ?
                                <>
                                    <Brand language={props.language}/>
                                    <Origin language={props.language} formik={props.formik}/>
                                    <Treatment language={props.language} formik={props.formik}/>
                                </>
                            :
                                <>
                                    <GrowthType language={props.language}/>
                                    <LabTreatment language={props.language} formik={props.formik}/>
                                </>
                            }
                            {/* <Type /> */}
                            <KeytoSymbol language={props.language} formik={props.formik}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
