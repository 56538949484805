import React from "react";
import ourVision from "../../Images/our-vision.svg"
import ourMission from "../../Images/our-mission.svg"
import ourValue from "../../Images/our-value.svg"
import styles from "../../css/style.module.css"

const VisionMision = () =>{
    return(
        <>
			<div className="container-fluid bg-white">
				<div className="container my-10">
					<div className="row pt-5 align-items-center">
						<div className="col-md-12 col-lg-4 p-6" data-aos="fade-up" data-aos-once="true">
							<div className={styles.vcol}>
								<img className="pb-6" src={ourVision} alt="our vision" title="Our Vision" />
								<h3 className="pb-4">Our Vision</h3>
								<p>To deliver a new sight of comprehensive diamond purchasing service to the Jewelers around the world, through the power of innovation and technology.<br /><br /><br /></p>
							</div>
						</div>
						<div className="col-md-12 col-lg-4 p-6" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
							<div className={styles.vcol}>
								<img className="pb-6" src={ourMission} alt="our mission" title="Our Mission" />
								<h3 className="pb-4">Our &nbsp;Mission</h3>
								<p>To make all varied types of polished diamonds (Certified &amp; parcel) accessible online around the world and to design a simple online platform where Jewelers can buy and receive diamonds with single click.</p>
							</div>
						</div>
						<div className="col-md-12 col-lg-4 p-6" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
							<div className={styles.vcol}>
								<img className="pb-6" src={ourValue} alt="our value" title="Our Value" />
								<h3 className="pb-4">Our Values</h3>
								<p>A Commitment to Ethics, Innovation and Consistency. We thrive to maintain quality check and deliver conflict free diamonds consistently using innovative and sustainable methods.<br /><br /></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
    )
}

export default VisionMision;