import React from "react";


export default function ShippingInfo({userinfo,setEdit,contactbookuserinfo,language}) {
    // console.log(userinfo.shipping_address,"SHIPPING ADDRESS")
    return (
        <>
            <div className="row">
                <div className="col-12 border-bottom d-flex justify-content-between pb-3 mb-5">
                    <h3>{language.PROFILEVIEW_SHIPPING_INFORMATION}</h3>
                    <button className="btn btn-sm btn-secondary" onClick={() => setEdit(true)}>{language.PROFILE_EDIT}</button>
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.INVOICE_DETAIL_ADDRESS}</div>
                        <div dangerouslySetInnerHTML={{__html: userinfo.shipping_address}}className="font-size-lg profileShipping font-weight-bold" ></div>
                    </div>
                    {contactbookuserinfo.shipping_address !== userinfo.shipping_address?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_TELEPHONE}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.shipping_phone}</div>
                    </div>
                    {contactbookuserinfo.shipping_phone !== userinfo.shipping_phone?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_SHIPPING_EMAIL}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.shipping_email}</div>
                    </div>
                    {contactbookuserinfo.shipping_email !== userinfo.shipping_email?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}
                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >{language.PROFILE_EDIT_COMPANY_TAX}</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.company_tax}</div>
                    </div>
                    {contactbookuserinfo.company_tax !== userinfo.company_tax?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}

                </div>
                <div className="col-md-7 mb-4 d-md-flex justify-content-between align-items-end">
                    <div>
                        <div className="text-muted font-size-lg font-weight-bold" >Port Of Discharge</div>
                        <div className="font-size-lg font-weight-bold" >{userinfo.port_of_discharge}</div>
                    </div>
                    {contactbookuserinfo.port_of_discharge !== userinfo.port_of_discharge?<span className="text-primary font-weight-bold">{language.PROFILE_EDITED}</span>:""}

                </div>
                
            </div>
        </>
    );
}
