import React from "react";
import { Field } from "formik";
import {MultiSelect} from '../MultiSelect'

export const Shade = ({language,formik}) => {
    
    const ShadeData = ([
        {
            id: 1,
            value:"NONE"
        },
        {
            id: 2,
            value:"LIGHT BROWN"
        },
        {
            id: 3,
            value:"BROWN"
        },
        {
            id: 4,
            value:"LIGHT GREEN"
        },
        {
            id: 5,
            value:"GREEN"
        },
        {
            id: 6,
            value:"GREY"
        },
        {
            id: 7,
            value:"BLACK"
        },
        {
            id: 8,
            value:"PINK"
        },
        {
            id: 9,
            value:"BLUE"
        },
        {
            id: 10,
            value:"MIX TINGE"
        },
        {
            id: 11,
            value:"UNKNOWN"
        }
    ]);
    const MilkyData = ([
        {
            id: 1,
            milky:"No Milky"
        },
        {
            id: 2,
            milky:"Light Milky"
        },
        {
            id: 3,
            milky:"Milky"
        },
        {
            id: 4,
            milky:"UNKNOWN"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-md-12">
                    <p className="font-weight-bold mb-2">{language.SHADE_SHADE} &amp; {language.MILKY_MILKY}</p>
                </div>
                <div className="col-md-12 d-flex flex-wrap">
                    <div className="w-100 ">
                        <label className="col-12 p-0" htmlFor="">Shade</label>
                        <MultiSelect language={language} options={ShadeData} label={"Select Shade"} name={"SHADE"} formik={formik}/>
                        {/* {ShadeData.map((item) => (
                            <label key={item.id} className="search_btn_check iframe_setup">
                                <Field type="checkbox" name="SHADE" id={`${item.shade}_shade`} value={item.value}/>
                                <div className="flourBox eye selectBox">
                                    <span>{item.value}</span>
                                </div>
                            </label>
                         ))} */}
                    </div>
                </div>
                <div className="col-md-12 d-flex flex-wrap mt-2">
                    <div className="w-100">
                        <label className="col-12 p-0" htmlFor="">Milky</label>
                        {MilkyData.map((item) => (
                            <label key={item.id} className="search_btn_check iframe_setup">
                                <Field type="checkbox" name="Milky" id={`${item.milky}_shade`} value={item.milky}/>
                                <div className="flourBox eye selectBox">
                                    <span>{item.milky}</span>
                                </div>
                            </label>
                         ))}
                    </div>
                </div>
            </div>
        </>
    );
};
