import React from "react";
import {Shape} from "./Basic/Shape"
import {SieveSize} from "./Basic/SieveSize"
import {Color} from "./Basic/Color"
import {Clarity} from "./Basic/Clarity"
import {CaratPcs} from "./Basic/CaratPcs"

export const Basic = (props) => {
       return (
        <>
            <Shape language={props.language} formik={props.formik}/>
            <SieveSize rangeErr={props.rangeErr} language={props.language} formik={props.formik} setRangeErr={props.setRangeErr}/>
            <div className="row pb-8 border-bottom">
                <div className="col-md-6"><Color dimondType={props.dimondType} colorErr={props.colorErr} language={props.language} formik={props.formik} setColorErr={props.setColorErr}/></div>
                <div className="col-md-6"><Clarity dimondType={props.dimondType} clarityErr={props.clarityErr} language={props.language} formik={props.formik} setClarityErr={props.setClarityErr} /></div>
            </div>
            <CaratPcs language={props.language} formik={props.formik} caratErr={props.caratErr} setCaratErr={props.setCaratErr} pcsErr={props.pcsErr} setPcsErr={props.setPcsErr} minCaratErr={props.minCaratErr} setMinCaratErr={props.setMinCaratErr}/>
        </>
    );
};
