import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
export const ImageSharePopup = (props) => {
    const [showMsg, setShowMsg] = useState(false);
    const hideModel = () => {
        props.onHide();
    };
    const copyurl = () => {
        navigator.clipboard.writeText(props.src)
        setShowMsg(true)
        setInterval(() => {
            setShowMsg(false)
        }, 10000);
                
    }
    const closeModel = () => {
        props.onClose();
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="videosharemodal"
            >
                <Modal.Body className="text-center my-6">
                    <div className="screenAlert-icon screenAlert-success animate">
                        <span className="screenAlert-line screenAlert-tip animateSuccessTip"></span>
                        <span className="screenAlert-line screenAlert-long animateSuccessLong"></span>
                        <div className="screenAlert-placeholder"></div>
                        <div className="screenAlert-fix"></div>
                    </div>
                    {props.msg?<p className="font-size-h4">{props.msg}</p>:<p className="font-size-h4">Video URL Copied.</p>}
                    {showMsg ? <div className="alert alert-custom alert-light-success text-center py-4 justify-content-center">
                        Copied
                    </div>:null}
                    <div className="form-group">
                        <div className="input-group">
                            <input className="form-control" type="text" value={props.src} />
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" onClick={()=> copyurl(props.src)}>Copy</button>
                            </div>
                        </div>
                    </div>
                    {/* <p className="font-size-h4 font-weight-bold my-10">{props.src ? props.src : ""}</p> */}
                   
                    <Button className="px-5 mt-10"
                        variant="primary"
                        onClick={() => {props.responseOnOk ? responseClose(props): closeModel(props) }}
                    >
                       OK
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};
