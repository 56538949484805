import blogimg1 from  "../../Images/1622723040.jpg"
import blogimg2 from  "../../Images/1622042263.jpg"
import blogimg3 from  "../../Images/1620040642.jpg"

const BlogMockdata= [
    {
        id:"1",
        imgsrc:blogimg1,
        title:"What does blockchain mean for the Diamond Industry?",
        auther:"DIAMONDS ON CALL TEAM",
        content:"In the diamond industry, there is mystical importance of blockchain technology. This advanced tool could identify the origin of any product. You can also trace its source to the destination path. The historical origin of the diamond determines its value in the market. Tracr and many other organizations are built for tracing the history of diamonds. It is also responsible for answering the origin of that diamond—also the process of how it reached the present location. Blockchain technology is capable of determining the reputation of the diamond industry in the vision of the customers. Hence the supply chain of any diamond determines its luxurious value in the market."
    },
    {
        id:"2",
        imgsrc:blogimg2,
        title:"How online technology has disrupted the retail jewelry industry?",
        auther:"DIAMONDS ON CALL TEAM",
        content:"From nomadic times, humankind has always leaned towards the options that have made lives a little bit easier. Starting from wheels to the evolution of technology, if we closely look at our lives, we live in the web of technology, and who would deny when the web is so comforting. Advancements like Cloud solutions, mining, the creation of e-commerce platforms, and others have helped enhance businesses to unimaginable levels."
    },
    {
        id:"3",
        imgsrc:blogimg3,
        title:"A Complete Guide to Diamond Inclusions",
        auther:"DIAMONDS ON CALL TEAM",
        content:"If your search for ultimately perfect diamond has begun, then you must have come across the term inclusion or popularly known as impurities. If your finalized diamond has passed of perfect colour and perfect cut, yet the last leap of ultimate sparkle might be a hurdle to come through. The inclusion falls in the clarity grading criteria of diamonds."
    }
]
export default BlogMockdata;