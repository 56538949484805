import React from "react";
import natural from "../../Images/natural.jpg"
import lab from "../../Images/lab.jpg"
import styles from "../../css/style.module.css"

const Product = () =>{
    return(
        <>
            <div className={styles.bgF6f8fc} >
                <div className={`container ${styles.productSec}`}>
                    <div className="row justify-content-between m-0">
                        <div className="col-lg-4 pt-lg-10 text-center text-lg-left" data-aos="fade-up" data-aos-once="true">
                            <h2 className={styles.customH2Title}>Our Products</h2>
                            <p className="pr-lg-8">We provide round and fancy shape diamonds certified by trusted diamond grading organisations such as GIA, HRD, IGI and AGS.</p>
                        </div>
                        <div className="col-lg-8">
                            <div className={`row ${styles.productHome}`} data-aos="fade-up" data-aos-once="true">
                                <div className="col-md-6">
                                    <div className={`${styles.card} text-center bg-white`}>
                                        <img src={natural} alt="natural diamonds" title="Natural Diamonds" className="w-100 pb-2 pb-2"/>
                                        <h4 className=" pt-3">Natural Diamonds</h4>
                                        <p className="px-4 mb-4">Diamonds with color from D to Z. Diamonds with colors like pink, blue, green etc.</p>
                                    </div>
                                </div>
                                <div className="col-md-6" data-aos="fade-up" data-aos-once="true">
                                    <div className={`${styles.card} text-center bg-white`}>
                                        <img src={lab} alt="Lab-grown Diamond" title="Lab-grown Diamond" className="w-100 pb-2"/>
                                        <h4 className=" pt-3">Lab-grown Diamond</h4>
                                        <p className="px-4 mb-4">Diamonds Grown in Lab with color form D to Z. Diamonds Grown in lab with colors like yellow, pink, blue, green etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Product;