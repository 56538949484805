/* eslint-disable */
import * as Yup from 'yup';
import kycFormModel from './kycFormModel';
const {
  formField: {
    wl_primarycolor,
    n_fontcolor,
    lab_primary,
    lab_fontcolor,
    theme,
    email,
    muti_currency,
    tax_name,
    tax_value,
    website_url,
    multilanguage
  }
} = kycFormModel;

const numberMatch = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/
const taxnameMatch = /^[a-zA-Z0-9-_]+$/
const webregex = /^(https?|ftp):\/\/[^\s\/$.?#]+\/?[^\/\s]*$/;
export default function ValidationSchema(taxinclusive){
  let validationarray = [
  Yup.object().shape({
    [wl_primarycolor.name]: Yup.string().required(`${wl_primarycolor.requiredErrorMsg}`),
    [n_fontcolor.name]: Yup.string().required(`${n_fontcolor.requiredErrorMsg}`),
    [lab_primary.name]: Yup.string().required(`${lab_primary.requiredErrorMsg}`),
    [lab_fontcolor.name]: Yup.string().required(`${lab_fontcolor.requiredErrorMsg}`),
    [theme.name]: Yup.string().required(`${theme.requiredErrorMsg}`),
    [multilanguage.name]: Yup.string().required(`${multilanguage.requiredErrorMsg}`),
  }),

  Yup.object().shape({
    [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
    [tax_value.name]: Yup.string().required(`${tax_value.requiredErrorMsg}`).matches(numberMatch,"Tax Value must be numeric with 2 decimal places and greater than zero"),
    [tax_name.name]: Yup.string().required(`${tax_name.requiredErrorMsg}`).matches(taxnameMatch,"Only Alpha Numeric & Hyphen are allowed")
  }),
  
  Yup.object().shape({
    [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
    [website_url.name]: Yup.string().required(`${website_url.requiredErrorMsg}`).matches(webregex,"Provide Domain Name in Proper Format. Example: https://example.com")
  }),
  ]
  if(!taxinclusive){
    validationarray[1] = Yup.object().shape({
      [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
      // [tax_value.name]: Yup.string().required(`${tax_value.requiredErrorMsg}`).matches(numberMatch,"Tax Value must be numeric with 2 decimal places and greater than zero"),
      // [tax_name.name]: Yup.string().required(`${tax_name.requiredErrorMsg}`).matches(taxnameMatch,"Only Alpha Numeric & Hyphen are allowed")
    })
  }
  return validationarray
};
