/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useMemo} from "react";
import cs from "../css/widgets.module.css";
import { FaHandHolding } from "react-icons/fa";

export const TotalOrderPlaceTiles = (props) => {

  const userData = useMemo(()=>localStorage.getItem("userData"),[])
  const user = JSON.parse(userData)

  return (
    <>
      <div className={`card card-custom gutter-b ${cs.OrderPlaceTiles} ${cs.dashTileHeight}`}  >
        {user.CustomerType !=="7" && user.CustomerType !=="11" ? (
          <a href="/confirmgoods">
            <div className="small-box cart-info-box">
                <div className="inner">
                    <p className="my-2 mt-5 font-size-h6 font-weight-bold">{props.language.DASHBOARD_TOTAL_ORDERS}</p>
                    <p className="display-3 mt-5 font-weight-bold">{props.totalOrderCount ? props.totalOrderCount : "0"}</p>
                </div>
                <div className="icon">
                    <img alt="" src="/media/General/cart_dashboard.svg" />
                </div>
            </div>
          </a>
        ) : (
          <div className="small-box cart-info-box">
              <div className="inner">
                  <p className="my-2 mt-5 font-size-h6 font-weight-bold">{props.language.DASHBOARD_TOTAL_ORDERS}</p>
                  <p className="display-3 mt-5 font-weight-bold">{props.totalOrderCount ? props.totalOrderCount : "0"}</p>
              </div>
              <div className="icon">
                  <img alt="" src="/media/General/cart_dashboard.svg" />
              </div>
          </div>
        )}
       
        {/* <a  href="#" >
            <div className="card-body">
              <div className="row align-items-end">
                <div className="col-8">
                  <div className={cs.bgIcon}>
                      <FaHandHolding className={cs.handIcon} />
                  </div>
                <p className="text-light font-weight-bold font-size-lg mt-2 mb-0">{props.language.DASHBOARD_TOTAL_ORDERS}</p>
                </div>
                <div className="col-4 text-right">

                  <div className="text-white font-weight-bold display-3 mt-1"> {props.totalOrderCount ? props.totalOrderCount : "0"} </div>
                </div>
              </div>
            </div>
          </a> */}
      </div>
    </>
  );
}
