import React from "react";
import styles from "../../css/style.module.css"
const Blogcard = (props) => {
    return (
        <>
            <div className="col-md-4 my-5" data-aos="fade-up" data-aos-once="true" >
                <a href=" ">
                    <div className={`text-center mx-auto ${styles.BlogImg}`}>
                        <img src={props.imgsrc} alt="Blog img" />
                    </div>	
                </a>
                <a href=" ">
                    <h5 className={`mb-0 mt-4 ${styles.customH5Title}`}>{props.title && props.title.substring(0, 35)} ...</h5>
                </a>
                <div className="d-flex justify-content-between my-1">
                    <p className="text-nowrap align-self-center m-0">
                        <span className={styles.blogdate}>BY</span>
                        <a href=" " className={`${styles.blogcategory} pl-2`}>{props.auther}</a>
                    </p>
                </div>
                <p className="l-24">{props.content && props.content.substring(0, 140)} ...<a href=" ">&nbsp;Read more</a></p>
            </div>
        </>
    )
}

export default Blogcard;