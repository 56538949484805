import React from "react";
import { Field } from "formik";

export const Percentage = ({language,formik}) => {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const validValue = value.match(/^\d*\.?\d{0,2}$/);
        if (validValue) {
          formik.setFieldValue(name, value);
        }
      };
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                {/* <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.SEARCHDIAMOND_PREFERENCE_PERCENTAGE}</p>
                </div> */}
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label className="text-uppercase">Table %</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Table_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Min_Table_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Table_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Max_Table_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label className="text-uppercase">Depth %</label>
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Depth_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Min_Depth_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Depth_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Max_Depth_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>RATIO</label>
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Ratio"
                                        min="0"
                                        step="0.01"
                                        id="Min_Ratio"
                                        placeholder="From"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        onBlur={formik.handleBlur}
                                    />
                                {/* <div className="input-group">
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div> */}
                            </div>
                            <div className="form-group mb-0 w-100">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Ratio"
                                        min="0"
                                        step="0.01"
                                        id="Max_Ratio"
                                        placeholder="To"
                                        autoComplete="off"
                                        onChange={handleInputChange}
                                        onBlur={formik.handleBlur}
                                    />
                                {/* <div className="input-group">
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
