/* eslint-disable */
import kycFormModel from './kycFormModel';
import axios from 'axios';
import { NODE_API_URL } from '../../../../../env_config';
const {
  formField: {
    naturaldiamond,
    naturalfancydiamond,
    lgwhitediam,
    lgfancydiam,
    diamondshape,
    carat_from,
    carat_to,
    diamondcolor,
    diamondfancy_color,
    diamondfancy_intensity,
    diamondfancy_overtone,
    diamondclarity,
    lab_val,
    cuts,
    diamondflour,
    polishes,
    symmetries,
    diamondshade,
    milky,
    eyeclean,
    price_from,
    price_to,
    total_price_from,
    total_price_to,
    media,


    lab_shape,
    lab_carat_from,
    lab_carat_to,
    lab_color,
    lab_fancy_color,
    lab_fancy_intensity,
    lab_fancy_overtone,
    lab_clarity,
    lab_lab,
    lab_cuts,
    lab_polishes,
    lab_symmetries,
    lab_flourselect,
    lab_shadeselect,
    lab_milky,
    lab_eyeclean,
    lab_min_dollarperct,
    lab_max_dollarperct,
    lab_total_price_from,
    lab_total_price_to,
    lab_all_media,

    primarycolor,
    secondary,
    fontcolor,
    diamondmarkup,
    diamondfancymarkup,
    lab_diamondmarkup,
    lab_diamondfancymarkup,
    website_url,
    inquiry_email,
    inquiry_cc_email,
    multi_currency,
    multi_language,
    dbfetch,
    noprice
  }
} = kycFormModel;
export const fetchData = async(userinfo) => {
  const userData = JSON.parse(localStorage.getItem("userData"))
  const finalData = {
      "user_id":userinfo.id,
      "api_id":userinfo.api_id
  }
  const promise = await axios({
    method: "POST",
    url: `${NODE_API_URL}/API/fetchIframeData`,
    headers: { "Content-Type": "application/json" },
    data: finalData,
  }).then(res => {
    if (res && res.data && res.data.data && res.data.data !== "Record Not Found!") {
      // console.log(res.data.data.shape.split(','), "fetchIframeData")
      // setCCModeWhiteLabelData(res.data.data)
      return {
        [naturaldiamond.name]: res.data.data.naturaldiamond?1:'',
        [naturalfancydiamond.name]: res.data.data.naturalfancydiamond?1:'',
        [lgwhitediam.name]: res.data.data.lgwhitediam?1:'',
        [lgfancydiam.name]: res.data.data.lgfancydiam?1:'',
        [diamondshape.name]: res.data.data.shape.split(','),
        [carat_from.name]: res.data.data.min_carat,
        [carat_to.name]: res.data.data.max_carat,
        [diamondcolor.name]: res.data.data.color.split(','),
        [diamondfancy_color.name]: res.data.data.diamondfancy_color.split(','),
        [diamondfancy_intensity.name]: res.data.data.diamondfancy_intensity.split(','),
        [diamondfancy_overtone.name]: res.data.data.diamondfancy_overtone.split(','),
        [diamondclarity.name]: res.data.data.clarity.split(','),
        [lab_val.name]: res.data.data.lab.split(','),
        [cuts.name]: res.data.data.cut.split(','),
        [diamondflour.name]: res.data.data.fluorescence.split(','),
        [polishes.name]: res.data.data.polish.split(','),
        [symmetries.name]: res.data.data.symmetry.split(','),
        [diamondshade.name]: res.data.data.shade.split(','),
        [milky.name]: res.data.data.milky.split(','),
        [eyeclean.name]: res.data.data.eyeclean.split(','),
        [price_from.name]: res.data.data.total_price_from,
        [price_to.name]: res.data.data.total_price_to,
        [total_price_from.name]: res.data.data.min_dollarperct,
        [total_price_to.name]: res.data.data.max_dollarperct,
        [media.name]: res.data.data.media,


        [lab_shape.name]: res.data.data.lab_shape.split(','),
        [lab_carat_from.name]: res.data.data.lab_min_carat,
        [lab_carat_to.name]: res.data.data.lab_max_carat,
        [lab_color.name]: res.data.data.lab_color.split(','),
        [lab_fancy_color.name]: res.data.data.lab_fancy_color.split(','),
        [lab_fancy_intensity.name]: res.data.data.lab_fancy_intensity.split(','),
        [lab_fancy_overtone.name]: res.data.data.lab_fancy_overtone.split(','),
        [lab_clarity.name]: res.data.data.lab_clarity.split(','),
        [lab_lab.name]: res.data.data.lab_lab.split(','),
        [lab_cuts.name]: res.data.data.lab_cut.split(','),
        [lab_polishes.name]: res.data.data.lab_polish.split(','),
        [lab_symmetries.name]: res.data.data.lab_symmetry.split(','),
        [lab_flourselect.name]: res.data.data.lab_fluorescence.split(','),
        [lab_shadeselect.name]: res.data.data.lab_shade.split(','),
        [lab_milky.name]: res.data.data.lab_milky.split(','),
        [lab_eyeclean.name]: res.data.data.lab_eyeclean.split(','),
        [lab_min_dollarperct.name]: res.data.data.lab_min_dollarperct,
        [lab_max_dollarperct.name]: res.data.data.lab_max_dollarperct,
        [lab_total_price_from.name]: res.data.data.lab_total_price_from,
        [lab_total_price_to.name]: res.data.data.lab_total_price_to,
        [lab_all_media.name]: res.data.data.lab_media,


        // [muti_currency.name]: 'INR',
        [diamondmarkup.name]: res.data.data.diamondmarkup,
        [diamondfancymarkup.name]: res.data.data.diamondfancymarkup,
        [lab_diamondmarkup.name]: res.data.data.lab_diamondmarkup,
        [lab_diamondfancymarkup.name]: res.data.data.lab_diamondfancymarkup,
        // [white_label_logo.name]: [],
        [primarycolor.name]: res.data.data.primarycolor_code,
        [secondary.name]: res.data.data.secondarycolor_code,
        [fontcolor.name]: res.data.data.fontcolor_code,
        [website_url.name]: res.data.data.website_url,
        [inquiry_email.name]: res.data.data.inquiry_email,
        [inquiry_cc_email.name]: res.data.data.inquiry_cc_email,
        [noprice.name]: res.data.data.no_price,
        [dbfetch.name]:true
      }
    }
    else {
      return {
        [naturaldiamond.name]: '',
        [naturalfancydiamond.name]: '',
        [lgwhitediam.name]: '',
        [lgfancydiam.name]: '',
        [diamondshape.name]: [],
        [carat_from.name]: '0.18',
        [carat_to.name]: '10.00',
        [diamondcolor.name]: [],
        [diamondfancy_color.name]: [],
        [diamondfancy_intensity.name]: [],
        [diamondfancy_overtone.name]: [],
        [diamondclarity.name]: [],
        [lab_val.name]: [],
        [cuts.name]: [],
        [diamondflour.name]: [],
        [polishes.name]: [],
        [symmetries.name]: [],
        [diamondshade.name]: [],
        [milky.name]: [],
        [eyeclean.name]: [],
        [price_from.name]: '0',
        [price_to.name]: '999999',
        [total_price_from.name]: '0',
        [total_price_to.name]: '999999',
        [media.name]: '0',


        [lab_shape.name]: [],
        [lab_carat_from.name]: '0.18',
        [lab_carat_to.name]: '10.00',
        [lab_color.name]: [],
        [lab_fancy_color.name]: [],
        [lab_fancy_intensity.name]: [],
        [lab_fancy_overtone.name]: [],
        [lab_clarity.name]: [],
        [lab_lab.name]: [],
        [lab_cuts.name]: [],
        [lab_polishes.name]: [],
        [lab_symmetries.name]: [],
        [lab_flourselect.name]: [],
        [lab_shadeselect.name]: [],
        [lab_milky.name]: [],
        [lab_eyeclean.name]: [],
        [lab_min_dollarperct.name]: '0',
        [lab_max_dollarperct.name]: '999999',
        [lab_total_price_from.name]: '0',
        [lab_total_price_to.name]: '999999',
        [lab_all_media.name]: '0',


        // [muti_currency.name]: 'INR',
        [diamondmarkup.name]: '12',
        [diamondfancymarkup.name]: '12',
        [lab_diamondmarkup.name]: '12',
        [lab_diamondfancymarkup.name]: '12',
        // [white_label_logo.name]: [],
        [primarycolor.name]: '#002173',
        [secondary.name]: '#ff0000',
        [fontcolor.name]: '#ffffff',
        [website_url.name]: '',
        [inquiry_email.name]: userinfo.mail,
        [inquiry_cc_email.name]: '',
        [dbfetch.name]:false
      }
    }
  })

  // const promise = ({
  //   [naturaldiamond.name]: '',
  //   [naturalfancydiamond.name]: '',
  //   [lgwhitediam.name]: '',
  //   [lgfancydiam.name]: '',
  //   [diamondshape.name]: [],
  //   [carat_from.name]: '0.18',
  //   [carat_to.name]: '10.00',
  //   [diamondcolor.name]: [],
  //   [diamondfancy_color.name]: [],
  //   [diamondfancy_intensity.name]: [],
  //   [diamondfancy_overtone.name]: [],
  //   [diamondclarity.name]: [],
  //   [lab_val.name]: [],
  //   [cuts.name]: [],
  //   [diamondflour.name]:[],
  //   [polishes.name]: [],
  //   [symmetries.name]: [],
  //   [diamondshade.name]: [],
  //   [milky.name]: [],
  //   [eyeclean.name]: [],
  //   [price_from.name]: '0',
  //   [price_to.name]: '999999',
  //   [total_price_from.name]: '0',
  //   [total_price_to.name]: '999999',
  //   [media.name]: '0',


  //   [lab_shape.name]: [],
  //   [lab_carat_from.name]: '0.18',
  //   [lab_carat_to.name]: '10.00',
  //   [lab_color.name]: [],
  //   [lab_fancy_color.name]: [],
  //   [lab_fancy_intensity.name]: [],
  //   [lab_fancy_overtone.name]: [],
  //   [lab_clarity.name]: [],
  //   [lab_clarity.name]: [],
  //   [lab_lab.name]: [],
  //   [lab_cuts.name]: [],
  //   [lab_polishes.name]: [],
  //   [lab_symmetries.name]: [],
  //   [lab_flourselect.name]: [],
  //   [lab_shadeselect.name]: [],
  //   [lab_milky.name]: [],
  //   [lab_eyeclean.name]: [],
  //   [lab_min_dollarperct.name]: '0',
  //   [lab_max_dollarperct.name]: '999999',
  //   [lab_total_price_from.name]: '0',
  //   [lab_total_price_to.name]: '999999',
  //   [lab_all_media.name]: '0',


  //   // [muti_currency.name]: 'INR',
  //   [diamondmarkup.name]: '12',
  //   [diamondfancymarkup.name]: '12',
  //   [lab_diamondmarkup.name]: '12',
  //   [lab_diamondfancymarkup.name]: '12',
  //   // [white_label_logo.name]: [],
  //   [primarycolor.name]: '#002173',
  //   [secondary.name]: '#ff0000',
  //   [fontcolor.name]: '#ffffff',
  //   [website_url.name]: '',
  //   [inquiry_email.name]: mail,
  //   [inquiry_cc_email.name]: '',
  // })
  // console.log(promise,"PROMISEE")
  return promise
}

