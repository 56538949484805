import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { BsSortDown } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function SupplierSortingDropdown({ sorting, setSorting }) {
    const options = [
        { label: "Supplier Name", values: [{ id: "supplier_asc", text: "A TO Z" }, { id: "supplier_desc", text: "Z TO A" }] },
        { label: "Diamond Count", values: [{ id: "count_asc", text: "Low To High" }, { id: "count_desc", text: "High To Low" }] },
    ];

    const getSelectedOption = () => {
        for (let group of options) {
            for (let option of group.values) {
                if (option.id === sorting) {
                    return `${group.label} (${option.text})`;
                }
            }
        }
        return "Sort By";
    };

    const handleSelect = (value) => {
        setSorting(value);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="clear" className="btn-hover-light-dark">
                <BsSortDown /> {getSelectedOption()} <RiArrowDropDownLine />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ maxHeight: '400px', padding: 0 }}>
                <PerfectScrollbar>
                    {options.map(group => (
                        <React.Fragment key={group.label}>
                            <Dropdown.ItemText  className='border-bottom' style={{ padding:" 0.35rem 1.25rem"}}>
                                <strong>{group.label}</strong>
                            </Dropdown.ItemText>
                            {group.values.map(option => (
                                <Dropdown.Item 
                                    style={{ padding:" 0.35rem 1.25rem"}}
                                    key={option.id} 
                                    onClick={() => handleSelect(option.id)}
                                    active={sorting === option.id}
                                >
                                    {option.text}
                                </Dropdown.Item>
                            ))}
                        </React.Fragment>
                    ))}
                </PerfectScrollbar>
            </Dropdown.Menu>
        </Dropdown>
    );
}
