/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";

export const IFrameRejected = (props) => {

    return (
        <>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">
                   <h3>{props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_REJECTEDMSG}</h3>
                   <Alert className="my-5" variant="danger">
                        {props.language.DIAMOND_FEED_REJECTEDMSG1} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_REJECTEDMSG2}
                    </Alert>
                   <p className="text-muted">{props.language.DIAMOND_FEED_REJECTEDMSG3} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_REJECTEDMSG4}</p>
                   <p className="text-muted">{props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG6}</p>
                </div>
                <div className="api-home-svg api-failed"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div>
            </div>
        </>
    );
};
