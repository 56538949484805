import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid ,Button } from '@material-ui/core';
import {toAbsoluteUrl} from "../../../../_helpers";

export default function ProfileSuccess() {

//   function Copyurl() {
//     var range = document.createRange();
//     range.selectNode(document.getElementById("copyurl"));
//     window.getSelection().removeAllRanges(); // clear current selection
//     window.getSelection().addRange(range); // to select text
//     document.execCommand("copy");
//     window.getSelection().removeAllRanges();// to deselect
//     alert("Your URL is copied to clipboard");
// }

  return (
    <React.Fragment>
        <Grid container justify='center' align="center">
          <Grid item xs={12} sm={7}>
            <Typography className="my-10 text-center" variant="h4" align="center">
                And it's done! You've successfully set up our CC Mode.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className="screenAlert-icon screenAlert-success animate">
                <span className="screenAlert-line screenAlert-tip animateSuccessTip"></span>
                <span className="screenAlert-line screenAlert-long animateSuccessLong"></span>
                <div className="screenAlert-placeholder"></div>
                <div className="screenAlert-fix"></div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
       
            <Typography className="font-weight-bold" align="center">
                Now you'll be able to enjoy a seamless interface with your brand identity, 
            </Typography>
            <Typography className="font-weight-bold" align="center">
                markup prices and multi-currency. Simply switch your CCMode on to be at the top of your game!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} className=' d-flex justify-content-center mt-4 align-items-center'>
                <input type="text" value="https://ccmode.diazoom.com/" readonly id="copyurl" className="form-control m-0 w-25" /> 
                <button className="btn btn-icon btn-warning text-white ml-1" data-toggle="tooltip" data-placement="top" title="Copy URL" onClick={() => {navigator.clipboard.writeText("https://ccmode.diazoom.com/")}}><i className="fa fa-copy"></i></button>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="my-10"
              component={Link} to="/ccmode"
              onClick={() => window.location.reload(true)}
            >
               Ok
            </Button>
          </Grid>
        </Grid>
    </React.Fragment>
  );
}
