import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../_helpers";
import { useHistory } from "react-router-dom";
// import { IoCloseSharp } from 'react-icons/io5';
// import { Backdrop,CircularProgress,} from "@material-ui/core";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const CompareDiamond = (props) => {
    // const [loading, setLoading] = React.useState(false);
    const [isSubmitting ] = useState(false)
    // const [openLoader, setOpenLoader] = React.useState(false);
    const history = useHistory();
    // const [data, setData] = useState(props.diamondselected);
    // console.log(data);
    // const hideModel = () => {
    //     props.onHide();
    // };
    // const CompareClick = () => {
    //     props.onHide();
    // };
    const closeModel = () => {
        props.onHide();
    };
    // const responseClose = (props) => {
    //     props.responseOnOk()
    // };
    const RouteToCompare = () => {
        let comparestones = props.diamondselected.map(value => value.id.toString())
        localStorage.setItem("comparestones",JSON.stringify(comparestones))
        history.push({pathname: `/comparison`, state: {data:props.diamondselected}})
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                // size="xl"
                // backdrop="static"
                keyboard={false}
                // centered
            >
                <Modal.Header>
                    <Modal.Title>
                        {props.language.DIAMOND_COMPARISION_SELDIAMONDS}
                        <div className="text-muted font-size-sm">{props.language.DIAMOND_COMPARISION_PAR1}</div>
                    </Modal.Title>
                    <button type="button" className="close" onClick={() => closeModel()}><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body >
                    <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll"
                        style={{ maxHeight: "500px", position: "relative" }}
                    >
                      { props.diamondselected ? props.diamondselected.map((data)=>(
                            <div className={`d-flex w-100 ${ props.diamondselected.diamond_type === "L" ? "labTab" : "natTab" } align-items-center justify-content-between border-bottom py-2`}>
                                <div className="d-flex">
                                    <div className="text-center p-0">
                                        {data.aws_image &&  data.aws_image !=="null" ? (
                                            <img
                                                className="img-fluid mr-2"
                                                alt={data.C_Shape}
                                                src={data.aws_image}
                                                width="45px"
                                                onError={e => {
                                                    e.currentTarget.src = toAbsoluteUrl(
                                                        `/media/shape/${data.C_Shape ? data.C_Shape.toLowerCase() : ""}-no.png`
                                                        )
                                                    }}
                                            />
                                        ) : (
                                            <img
                                                className="img-fluid mr-2"
                                                alt={data.C_Shape}
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                )}
                                                onError={e => {
                                                    e.currentTarget.src = toAbsoluteUrl(
                                                        `/media/shape/noimage.jpg`
                                                    )
                                                }}
                                                width="45px"
                                                style={{ backgroundColor: "#f4f4f4" }}
                                            />
                                        )}
                                    </div>
                                    <div className="pl-2 p-0 text-left">
                                        <div className={`mb-1 ${data.diamond_type === "N" ? "natTab" : "labTab"}`}>
                                            <span className={`${ data.diamond_type === "N" ? data.C_Color === "fancy" ? "F" : "W" : data.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                                {data.diamond_type === "N" ? data.C_Color === "fancy"  ? "F" : "W" : data.C_Color === "fancy" ? "LF" : "L"}
                                            </span>
                                            <span onClick={() => history.push({ pathname: `/detail/${data.diamond_type === "N" ? "N" : "L"}-${data.id}` })} className={` cursor-pointer small ml-2`}>
                                                {data.diamond_type === "N" ? "N" : "L"}-{data.id}
                                            </span>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                className=" mx-2 text-dark small"
                                                href={
                                                    data.Lab === "IGI"
                                                        ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                        : data.Lab === "GIA"
                                                        ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                        : data.Lab === "HRD"
                                                        ? `http://ws2.hrdantwerp.com/HRdata.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                        : data.Lab === "GCAL"
                                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                        : data.Certi_link
                                                }
                                            >
                                                {data.Lab}-{data.Certi_NO}
                                            </a>
                                            
                                        </div>
                                        <span className="text-dark font-weight-bold d-block">
                                            {data.C_Shape}{" "}
                                            {data.C_Weight.toFixed(2)}{" "}
                                            {data.C_Color === "fancy" ? (
                                                <>
                                                    {data.f_intensity}{" "}{data.f_overtone}{" "}{data.f_color}{" "}
                                                </>
                                            ) : (
                                                <>{data.C_Color}{" "}</>
                                            )}
                                            {data.C_Clarity}{" "}{data.C_Cut}{" "}{data.C_Polish}{" "}{data.C_Symmetry}{" "}
                                            {data.C_Fluorescence}
                                        </span>
                                    </div>
                                </div>
                            </div>
                      )) : null }
                    </PerfectScrollbar>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary" className="mr-2 font-weight-bold" onClick={() => closeModel(props)}>
                        {props.language.CONFIRM_GOODS_POPUP_CLOSE}
                    </Button>
                    <Button disabled={isSubmitting} className="px-8 font-weight-bold" variant="primary" onClick={() =>RouteToCompare()}>
                        {props.language.COMPAREBTN}
                        {/* {loading && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop
                            sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                            open={openLoader}
                            // onClick={handleCloseLoader}
                        >
                            <CircularProgress color="secondary" />
                        </Backdrop> */}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
