import React from "react";
import { Formik, Form, Field,} from "formik";
import { Link } from "react-router-dom";
import * as Yup from 'yup';
import styles from "../css/style.module.css"

const Register = () =>{
    const validate = Yup.object({
        firstName: Yup.string()
            .required('First name is Required'),
        lastName: Yup.string()
            .required('Last Name is Required'),
        email: Yup.string()
            .email("Email is invalid")
            .required('Email is Required'),
        password: Yup.string()
            .min(8, "Password must be at least 8 charectors" )
            .required('Password is Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null],'Password must match')
            .required('Confirm Password is Required'),
        companyname: Yup.string()
            .required('Company Name is Required'),
        contact1: Yup.string()
            .required('Contact Number is Required'),
        city: Yup.string()
            .required('City Name is Required'),
        country: Yup.string()
            .required('Country Name is Required')
    })     
    return (
        <>
            <title>Register - Diamonds on Call Limited</title>
            <Formik 
                initialValues={{
                    firstName:'',
                    lastName:'',
                    email:'',
                    password:'',
                    confirmPassword:'',
                    companyname:'',
                    contact1:'',
                    city:'',
                    country:''
                }}
                onSubmit={async(values, { setSubmitting}) => {
                   
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 1000);
                }}
                validationSchema = {validate}
            >
                {(formik,isSubmitting) => (
                    <div className={styles.web_login}>
                        <div className="container">
                            <h1 className={`text-left ${styles.titleH3}`}>Create an Account</h1>
                            <span className="text-muted font-weight-bold">Please enter the following information, it's free.</span>
                            <div className="row justify-content-center align-self-center my-4">
                                <Form>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h3 className={styles.customH3title}>Personal Information</h3>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.firstName && formik.errors.firstName) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="firstName"
                                                                placeholder="Enter first name"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.firstName && formik.errors.firstName ? (
                                                                <div className="invalid-feedback">{formik.errors.firstName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.lastName && formik.errors.lastName) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="lastName"
                                                                placeholder="Enter last name"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.lastName && formik.errors.lastName ? (
                                                                <div className="invalid-feedback">{formik.errors.lastName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12" >
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.email && formik.errors.email) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="email"
                                                                placeholder="Enter Email address"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="invalid-feedback">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.password && formik.errors.password) ? ' is-invalid' : ''}`}
                                                                type="password"
                                                                name="password"
                                                                placeholder="Enter password"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <div className="invalid-feedback">{formik.errors.password}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.confirmPassword && formik.errors.confirmPassword) ? ' is-invalid' : ''}`}
                                                                type="password"
                                                                name="confirmPassword"
                                                                placeholder="Confirm your password"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                                <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h3 className={styles.customH3title}>Company Information</h3>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.companyname && formik.errors.companyname) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="companyname"
                                                                placeholder="Enter company name"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.companyname && formik.errors.companyname ? (
                                                                <div className="invalid-feedback">{formik.errors.companyname}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.contact1 && formik.errors.contact1) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="contact1"
                                                                placeholder="Enter contact number"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.contact1 && formik.errors.contact1 ? (
                                                                <div className="invalid-feedback">{formik.errors.contact1}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.city && formik.errors.city) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="city"
                                                                placeholder="Enter city name"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.city && formik.errors.city ? (
                                                                <div className="invalid-feedback">{formik.errors.city}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="sum_tab mb-4">
                                                            <Field
                                                                className={`form-control form-control-solid h-auto py-5 px-6 ${(formik.touched.country && formik.errors.country) ? ' is-invalid' : ''}`}
                                                                type="text"
                                                                name="country"
                                                                placeholder="Enter country name"
                                                                autoComplete="off"
                                                            />
                                                            {formik.touched.country && formik.errors.country ? (
                                                                <div className="invalid-feedback">{formik.errors.country}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="">
                                                    <div className="g-recaptcha" id="recaptcha" data-sitekey="6LcA4M4UAAAAAPEThMezf4BBDQh3YedGu4ut71oA"></div>
                                                    <span className="msg-error error text-danger"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-md-12 col-xs-12">
                                                <div className="sum_tab mb-4 text-center">
                                                    <button type="submit" className={`submit btn ${styles.btnPrimary} px-5 py-2 shadow-sm my-4` }disabled={formik.isSubmitting || !formik.isValid}>{formik.isSubmitting ? "Please wait..." : "Submit"}</button>
                                                    <button type="reset" className={`btn ${styles.btnSecondary} px-5 py-2 my-4 mx-2 shadow-sm border`}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                <div className="col-12">
                                    <div className="sum_tab mb-4 text-center">
                                        <p className="m-0">By Pressing 'Join Us' you agreed to our <Link to="/terms" className={styles.A2}> Terms and Conditions</Link>.</p>
                                        <p>We'll occasionally send you account-related emails.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    )
}
export default Register;