/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {toAbsoluteUrl} from "../../../_helpers";

export const SavedSearchtile = (props) => {
  return (
    <>
      <div className={`card card-custom gutter-b bg-white`}  >
          <div className="card-body savedSearchTile p-0 ">
              {/* <span className="small_title">{props.language.DASHBOARD_SAVEDSEARCH}</span> */}
              <img className="img-fluid" alt="ccmode" src={toAbsoluteUrl(`/media/General/ccmode_dashboard.gif`)}/>
          </div>
      </div>
    </>
  );
}