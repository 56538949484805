import React, { useState ,useEffect} from 'react';
import { Grid, Typography,InputLabel } from '@material-ui/core';
import { InputField, CheckboxField, SelectField,PhoneInput } from '../FormFields';
import axios from 'axios';
import { BASE_URL } from "./../../../../../env_config"

import MuiUploader from '../FormFields/MuiUploader';
import { NODE_API_URL } from '../../../../../env_config';
const work_profile_data = [
  
  {
    value: 'CEO',
    label: 'CEO'
  },
  {
    value: 'Sales-Manager',
    label: 'Sales-Manager'
  },
  {
    value: 'Purchase-Manager',
    label: 'Purchase-Manager'
  },
  {
    value: 'account-manager',
    label: 'account-manager'
  },
  {
    value: 'secretary',
    label: 'secretary'
  },
  {
    value: 'business-development-executives',
    label: 'business-development-executives'
  },
  {
    value: 'other',
    label: 'other'
  }
];

export default function PersonalInfoForm(props) {
  const {
    formField: {
      firstName,
      lastName,
      email,
      contanct_no,
      work_profile,
      passport_id,
      passport_id_file,
    //   useAddressForPaymentDetails
    },
    setFirstFile,
    setFieldValue,
    setPassportFileName,
    FirstFile,
    values,
    
  } = props;
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"))
    const finalData = {
        "id":userData.CustomerId,
        "user_id":userData.CustomerId
    }
    let apiurl = "/API/KYC/viewData"
    if(props.userinfo.kyc_status === "KYC_PEND" || props.userinfo.kyc_status === "KYC_REJ"){
      apiurl = "/API/getKycEditData"
    }
    axios({
        method: "POST",
        url: `${NODE_API_URL}${apiurl}`,
        headers: { "Content-Type": "application/json" },
        data: finalData,
    }).then(res => {
        if(res && res.data){
            console.log(res.data,"res.data")
            let apires = res.data
            if(props.userinfo.kyc_status === "KYC_PEND" || props.userinfo.kyc_status === "KYC_REJ"){
              apires = res.data.data
            }
           const GetWorkProfile = (workprofile) => {
            let id = null
            if(workprofile){
              work_profile_data.map(value => {
                if(value.label.toLowerCase() === workprofile.toLowerCase()){
                  id = value.value
                }
              })
            }
            // console.log(id,"idididid")
            return id
           }
           function replaceAll(string, search, replace) {
            return string.split(search).join(replace);
          }
          function removeTags(str) {
            if ((str===null) || (str===''))
                return false;
            else
                str = str.toString().replaceAll("&nbsp;"," ");
                  
            // Regular expression to identify HTML tags in
            // the input string. Replacing the identified
            // HTML tag with a null string.
            return str.replace( /(<([^>]+)>)/ig, '');
        }
          let shippingaddress = apires.shipping_address.replaceAll("<br>","\n")
          shippingaddress = removeTags(shippingaddress)
          setFieldValue("firstName",apires.fname)
          setFieldValue("lastName",apires.lname)
          setFieldValue("email",apires.mail)
          setFieldValue("contanct_no",apires.mobile)
          setFieldValue("passport_id",apires.passport_id)
          setFieldValue("passport_id_file",apires.passport_file)
          setFieldValue("work_profile",GetWorkProfile(apires.work_profile))
          setFieldValue("company_name",apires.cname)
          setFieldValue("address1",apires.address)
          setFieldValue("address2",apires.secaddress)
          setFieldValue("city",apires.city)
          setFieldValue("state",apires.state)
          setFieldValue("zipcode",apires.com_zip)
          setFieldValue("country",apires.country)
          setFieldValue("telephone",apires.contact2)
          setFieldValue("fax",apires.fax)
          setFieldValue("invoice_email",apires.invoiceemail)
          setFieldValue("website",apires.website)
          setFieldValue("comp_reg_no",apires.com_reg_no)
          setFieldValue("comp_reg_no_file",apires.com_reg_doc)
          setFieldValue("nature_of_business",apires.nature_buss)
          setFieldValue("business_est_date",apires.buss_esta_date)
          setFieldValue("name_of_partner",apires.director_name)
          setFieldValue("contact_of_partner",apires.directory_contact)
          setFieldValue("shipping_address1",shippingaddress)
          // setFieldValue("shipping_address2",shipaddress2)
          // setFieldValue("shipping_city",shipcity)
          // setFieldValue("shipping_state",shipstate)
          // setFieldValue("shipping_zipcode",shipzip)
          // setFieldValue("shipping_country",apires.country)
          setFieldValue("shipping_telephone",apires.shipping_phone)
          setFieldValue("shipping_email",apires.shipping_email)
          setFieldValue("tax_id",apires.company_tax)
          setFieldValue("port_of_discharge",apires.port_of_discharge)
        }
    }).catch(err => {
        console.log(err)
    })
  },[])
  return (
    <React.Fragment>
      <Typography className="mb-10 border-bottom font-weight-bolder pb-5" variant="h5">
        {props.language.PROFILEVIEW_USER_INFORMATION}
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={7}>
          <InputField name={firstName.name} label={props.language.PROFILE_FIRST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={lastName.name} label={props.language.PROFILE_LAST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={email.name} label={props.language.PROFILE_EMAIL} fullWidth disabled={true}/>
        </Grid>
        <Grid item xs={12} sm={7}>
          {/* <InputField name={contanct_no.name} label={contanct_no.label} fullWidth /> */}
          <InputLabel className="font-size-h6 font-weight-bold">
            {props.language.PROFILE_CONTACT_NUMBER}
          </InputLabel>
          <PhoneInput name={contanct_no.name} label={props.language.PROFILE_CONTACT_NUMBER} fullWidth onChange={(value) => setFieldValue("contanct_no",value)} />
        </Grid>
        <Grid item xs={12} sm={7}>
                    <InputField name={passport_id.name} label={props.language.PROFILE_IDENTITY_NUMBER} fullWidth />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_IDENTITY_PROOF}
                    </InputLabel>
                    <MuiUploader
                        name={passport_id_file.name}
                        label={passport_id_file.label}
                        data={passport_id_file}
                        FirstFile={FirstFile}
                        filefield={"passport_id_file"}
                        setFirstFile={setFirstFile}
                        setPassportFileName={setPassportFileName}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                  {values.passport_id_file ? 
                        <span> Previously Uploaded : {values.passport_id_file ? 
                            <a href={process.env.REACT_APP_ENV === "production"?`https://www.diamondsoncall.com/assets/documents/${values.passport_id_file}`:`${BASE_URL}/assets/documents/${values.passport_id_file}`} target="_blank" rel="noreferrer" className="font-weight-bold">{values.passport_id_file}</a>
                                :"N/A"}
                        </span> 
                    : ""}
                  
                </Grid>
        <Grid item xs={12} sm={7}>
          <SelectField
            name={work_profile.name}
            label={props.language.PROFILE_WORK_PROFILE}
            data={work_profile_data}
            fullWidth
          />
        </Grid>
        
        {/* <Grid item xs={12} sm={7}>
          <CheckboxField
            name={useAddressForPaymentDetails.name}
            label={useAddressForPaymentDetails.label}
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
