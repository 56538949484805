import React from "react";
import {
    makeStyles,
    Snackbar,
    SnackbarContent,
} from "@material-ui/core";
import clsx from "clsx";
import green from "material-ui/colors/green";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaRegCopy } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: green[600],
    },
}));
export const CopyUrlAlert = ({text}) => {
    const [alertOpen, setAlertOpen] = React.useState(false);
    const classes = useStyles();

    function handleClose(event, reason) {
        setAlertOpen(false);
    }
    const copyurl = () => {
        setAlertOpen(true);
    }

    function MySnackbarContentWrapper(props) {
        const { className, message, onClose, variant, ...other } = props;

            

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

    return (
        <>
            <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Click to Copy </Tooltip> } >
                <FaRegCopy className="cursor-pointer mr-2" onClick={()=>copyurl(navigator.clipboard.writeText(text))} />
            </OverlayTrigger>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`${text} is copied to clipboard`}
                />
            </Snackbar>
        </>
    )
};
