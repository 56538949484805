import React ,{useEffect} from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Radio ,
//   FormLabel
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    group: {
      margin: theme.spacing(1, 0),
      display: 'flex',
      flexDirection: 'row'
    },
  }));
export default function MediaAssets(props) {
  const { label,data, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const classes = useStyles();
  useEffect(() => {
    if(!(props.values && props.values[props.name] && props.values[props.name].length)){
      props.setFieldValue(props.name,["ALL"])
    }
  },[props.values])
//   const [value, setValue] = React.useState('female');
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <span className='text-danger small'>{error}</span>;
    }
  }

  function _onChange(e) {
    props.setFieldValue(props.name,[e.target.value])
  }
  return (
    <>
    <FormControl component="fieldset" className={classes.formControl}>
        {/* <FormLabel component="legend">Gender</FormLabel> */}
        <RadioGroup
          aria-label={rest.name}
          name={rest.name}
          className={classes.group}
          value={props.values[props.name]}
          onChange={_onChange}
        >
            {data.map((item) => (
            <>
                <FormControlLabel checked={props.values && props.values[props.name] && props.values[props.name].length?props.values[props.name][0] === item.value:false} value={item.value} control={<Radio />} label={item.label} />
            </>
        ))}
        </RadioGroup>
        <p>{_renderHelperText()}</p>
      </FormControl>
        {/* <FormControl {...rest}>
          <FormControlLabel
            value={field.checked}
            checked={field.checked}
            control={<Checkbox {...field} onChange={_onChange} />}
            label={label}
          />
          {_renderHelperText()}
        </FormControl> */}
    </>
  );
}
