import React from "react";
import { Field } from "formik";

export const Clarity = ({language}) => {
    const ClarityData = ([
        {
            id: 1,
            clarity:"FL"
        },
        {
            id: 2,
            clarity:"IF"
        },
        {
            id: 3,
            clarity:"VVS1"
        },
        {
            id: 4,
            clarity:"VVS2"
        },
        {
            id: 5,
            clarity:"VS1"
        },
        {
            id: 6,
            clarity:"VS2"
        },
        {
            id: 7,
            clarity:"SI1"
        },
        {
            id: 8,
            clarity:"SI2"
        },
        {
            id: 9,
            clarity:"SI3"
        },
        {
            id: 10,
            clarity:"I1"
        },
        {
            id: 11,
            clarity:"I2"
        },
        {
            id: 12,
            clarity:"I3"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.CLARITY_CLARITY}</p>
                </div>
                <div className="col-lg-10">
                    {ClarityData.map((item) => (
                        <label key={`${item.id}_clarity`} className="search_btn_check">
                            <Field type="checkbox" name="Clarity" id={`${item.clarity}_clarity`} value={item.clarity}/>
                            <div className="clarityBox selectBox">
                                <span>{item.clarity}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};