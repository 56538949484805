/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory,useLocation } from "react-router-dom";
export const PreviewIframe2 = (props) => {
    // const navigate = useNavigate();
    let history = useHistory();
    const location = useLocation()
    return (
        <div className="container-fluid" >
            <div className="card bg-white custom-card">
                <div className="card-body" style={{height:"72vh"}}>
                    <iframe src={process.env.REACT_APP_ENV === "production"?`https://nile.diazoom.com?auth=${location.state}`:`https://staging.aflatoon.diazoom.com?auth=${location.state}`} title='Embeded Code' height='100%' width='100%' style={{border: 'none'}}></iframe>
                </div>
                <div className="card-footer text-right">
                    <button className="btn btn-secondary" onClick={() => history.push({ pathname: "/iframe-setting"})}>Close Preview</button>
                </div>
            </div>
        </div>
    );
};
