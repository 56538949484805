import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles({
  formControl: {
    // minWidth: 120,
    // maxWidth: 300,
  },
});


function SelectFieldMarkup(props) {
    console.log("SelectFieldMarkup")
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const classes = useStyles();

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disableScrollLock: true
};
console.log(selectedValue,"selectedValueselectedValue")
  return (
    <FormControl {...rest} error={isError} className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} value={props.values[props.name]?props.values[props.name]:""}  MenuProps={MenuProps} onChange={(e) => {
        if(props.values.caratpricemarkups && props.values.caratpricemarkups.length){
          props.setSelectedMarkupOption(e.target.value)
          props.setSwalOpenMarkup(true)
        }
        else{
          props.setFieldValue("markupoption",e.target.value)
          props.setFieldValue("caratpricemarkups",[])
        }
      }}>
        <MenuItem value="" disabled>
            Select {label}
          </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectFieldMarkup.defaultProps = {
  data: []
};

SelectFieldMarkup.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectFieldMarkup;
