/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState}from "react";
import { FaLongArrowAltUp ,FaLongArrowAltDown} from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import {Modal,Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import { SwalWarn } from "../../../Popup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { ImageExpandPopupCommon } from "../../tiles/ImageExpandPopupCommon";

export const Tracklist = (props) => {
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const history = useHistory()

    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);

    const DetailSemiAprrove = () => {
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    function numberWithCommas(x) {
        if(!x){
            return "0.00"
        }
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const getshortlistedsymbol = (responsecurrency) => {
        let symbol = "$"
        if(responsecurrency === "INR"){
          symbol = "₹"
        }
        if(responsecurrency === "USD"){
          symbol = "$"
        }
        if(responsecurrency === "AUD"){
          symbol = "$"
        }
        if(responsecurrency === "CAD"){
          symbol = "$"
        }
        if(responsecurrency === "HKD"){
          symbol = "$"
        }
        if(responsecurrency === "CNY"){
          symbol = "¥"
        }
        if(responsecurrency === "EUR"){
          symbol = "€"
        }
        if(responsecurrency === "GBP"){
          symbol = "£"
        }
        if(responsecurrency === "NZD"){
          symbol = "$"
        }
        if(responsecurrency === "JPY"){
          symbol = "¥" 
        }
        if(responsecurrency === "CHF"){
          symbol = "₣"
        }
        if(responsecurrency === "SGD"){
            symbol = "$"
            
        } if(responsecurrency === "THB"){
            symbol = "฿"
            
        } if(responsecurrency === "MYR"){
            symbol = "RM"
            
        } if(responsecurrency === "BND"){
            symbol = "$"
            
        } if(responsecurrency === "DKK"){
            symbol = "kr."
            
        } if(responsecurrency === "SEK"){
            symbol = "kr"
            
        }
        return symbol
      }

    return (
        <>
            {/* {props ? console.log("props", props) : null} */}
            <div className={`row border-bottom align-items-center position-relative ${props.stone_status !==1 ? "cursor-na" : ""}`}>
                {props.stone_status !== 1? <span className="CanNotHold label label-outline-danger label-pill label-inline bg-danger-o-30 px-5">Sold Out</span> :""}
                <div className={`col-md-8 mb-3 mt-2 ${props.stone_status !==1 ? "CanNotHoldDiv" : ""}`}>
                    <div className="d-flex">
                        <div className="text-center">
                        {props.ext_status === "Success" || props.aws_image?<ImageExpandPopupCommon data={props}/>:<img
                                    className="img-fluid mr-2"
                                    alt={props.C_Shape}
                                    src={toAbsoluteUrl(
                                        `/media/shape/${props.C_Shape ? props.C_Shape.toLowerCase() : ""}-no.png`
                                    )}
                                    width="50px"
                                    style={{ backgroundColor: "#f4f4f4" }}
                                />}
                        </div>
                        <div className="pl-2 p-0 text-left">
                            <div className={`mb-1 ${props.diamond_type === "N" || props.diamond_type === "W"  ? "natTab" : "labTab"} font-weight-bold`}>
                                <span className={`${ props.diamond_type === "N" || props.diamond_type === "W" ? props.C_Color === "fancy" ? "F" : "N" : props.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                    {props.diamond_type === "N" || props.diamond_type === "W" ? props.C_Color === "fancy"  ? "NF" : "N" : props.C_Color === "fancy" ? "LF" : "L"}
                                </span>
                                {/* {props.stone_status ===1 ? 
                                    <span onClick={() => history.push({ pathname: `/detail/${props.diamond_type === "L"?"L":"N"}-${props.id}` })} className={`text-primary text-hover-primary cursor-pointer small ml-1`}>
                                        {props.diamond_type === "N" || props.diamond_type === "W" ? "N" : "L"}-{props.id}
                                    </span>
                                : 
                                    <span className={`text-primary small ml-1`}>
                                        {props.diamond_type === "N" || props.diamond_type === "W" ? "N" : "L"}-{props.id}
                                    </span>
                                } */}
                                {(user.CustomerType ==="7" || user.CustomerType ==="11") ? 
                                    <span className="text-primary small cursor-pointer" onClick={(e) => DetailSemiAprrove()}>
                                        {props.diamond_type === "N" || props.diamond_type === "W"? "N" : "L"}-{props.id}
                                    </span>
                                : props.stone_status ===1 ?
                                    <Link className="text-primary text-hover-primary small" onClick={() => history.push({ pathname: `/detail/${props.diamond_type === "L"?"L":"N"}-${props.id}` })}>
                                        {props.diamond_type === "N" || props.diamond_type === "W" ? "N" : "L"}-{props.id}
                                    </Link> : 
                                    <span className="text-primary small">
                                        {props.diamond_type === "N" || props.diamond_type === "W" ? "N" : "L"}-{props.id}
                                    </span>
                                }
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-primary mx-2 small"
                                    href={
                                        props.Lab === "IGI"
                                            ? `https://www.igi.org/verify-your-report/?r=LG${props.Certi_NO}`
                                            : props.Lab === "GIA"
                                            ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                            : props.Lab === "HRD"
                                            ? `http://ws2.hrdantwerp.com/HRprops.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                            : props.Lab === "GCAL"
                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                            : props.Certi_link
                                    }
                                >
                                    {props.Lab}: {props.Certi_NO}
                                </a>
                            </div>

                            <span className="text-dark font-weight-bold d-block">
                                {props.C_Shape}{" "}
                                {parseFloat(props.C_Weight).toFixed(2)}{" "}
                                {props.C_Color === "fancy" ? (
                                    <>
                                        {props.f_intensity}{" "}{props.f_overtone}{" "}{props.f_color}{" "}
                                    </>
                                ) : (
                                    <>{props.C_Color}{" "}</>
                                )}
                                {props.C_Clarity}{" "}{props.C_Cut}{" "}{props.C_Polish}{" "}{props.C_Symmetry}{" "}
                                {props.C_Fluorescence}
                            </span>
                        </div>
                    </div> 
                    {/* <div>
                        <span
                            className={`${
                                props.diamond_type === "N"
                                    ? props.C_Color === "fancy"
                                        ? "F"
                                        : "W"
                                    : props.C_Color === "fancy"
                                    ? "LF"
                                    : "L"
                            }_diamond mr-3 font-size-sm`}
                        >
                            {props.diamond_type === "N"
                                ? props.C_Color === "fancy"
                                    ? "F"
                                    : "W"
                                : props.C_Color === "fancy"
                                ? "LF"
                                : "L"}
                        </span>
                        <span className="font-size-sm">
                            <span className="font-weight-bold font-size-sm">
                                Stock Id:{" "}
                            </span>
                            {(user.CustomerType ==="7" || user.CustomerType ==="11") ? 
                                <span className="text-primary cursor-pointer" onClick={(e) => DetailSemiAprrove()}>
                                    {props.diamond_type === "N" ? "N" : "L"}-{props.id}
                                </span>
                            : props.stone_status ===1 ?
                                <Link onClick={() => history.push({ pathname: `/detail/${props.diamond_type === "N" ? "N" : "L"}-${props.id}` })}>
                                    {props.diamond_type === "N" ? "N" : "L"}-{props.id}
                                </Link> : 
                                 <span className="text-primary">
                                    {props.diamond_type === "N" ? "N" : "L"}-{props.id}
                                </span>
                            }
                        </span>
                        {(user.CustomerType !=="7" && user.CustomerType !=="11") ? 
                            <a
                                href={
                                    props.Lab === "IGI"
                                        ? `https://www.igi.org/verify-your-report/?r=LG${props.Certi_NO}`
                                        : props.Lab === "GIA"
                                        ? `https://www.gia.edu/report-check?reportno=${props.Certi_NO}`
                                        : props.Lab === "HRD"
                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                        : props.Lab === "GCAL"
                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.Certi_NO}`
                                        : props.Certi_link
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="ml-5 font-size-sm"
                            >
                                {props.Lab}: {props.Certi_NO}
                            </a>
                        : null }
                        
                    </div>
                    <div>
                        <span className="text-dark font-weight-bold">
                            {props.C_Shape}{" "}
                            {props.C_Weight.toFixed(2)}{" "}
                            {(props.C_Color === "fancy") ?
                                (
                                    <>
                                        {props.f_intensity}{" "}
                                        {props.f_overtone}{" "}
                                        {props.f_color}{" "}
                                    </>
                                ) : (
                                    <>
                                        {props.C_Color}{" "}
                                    </>
                                )
                            }
                            {props.C_Clarity}{" "}
                            {props.C_Cut}{" "}
                            {props.C_Polish}{" "}
                            {props.C_Symmetry}{" "}
                            {props.C_Fluorescence}{" "}
                        </span>
                    </div> */}
                </div>
                <div className={`col-md-4 text-right d-flex justify-content-end  ${props.stone_status !==1 ? "CanNotHoldDiv" : ""}`}>
                    <div>
                        <div className="text-muted font-weight-bold">
                            {props.symbol}/CT
                            {
                                props.stone_status === 1 ?
                                    props.conversionrate !== 1 ? 
                                        <>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        ${numberWithCommas(props.calculateprice.T_C_Rate)}
                                                    </Tooltip>
                                                }
                                            >
                                                <span> {props.symbol}{numberWithCommas(props.calculateprice.T_C_Rate * props.conversionrate)}</span>
                                            </OverlayTrigger>
                                        </> 
                                    :
                                        <span className="ml-1"> {props.symbol}{numberWithCommas(props.calculateprice.T_C_Rate * props.conversionrate)}</span>
                                :null
                            }
                        </div>
                        <div className="text-dark font-weight-bold">
                            { props.stone_status === 1 ?
                                props.conversionrate !== 1 ? 
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    ${numberWithCommas(props.calculateprice.total_our_price)}
                                                </Tooltip>
                                            }
                                        >
                                            <span> { props.symbol}{numberWithCommas(props.calculateprice.total_our_price *  props.conversionrate)}</span>
                                        </OverlayTrigger>
                                    </>
                                :
                                    <span> { props.symbol}{numberWithCommas(props.calculateprice.total_our_price *  props.conversionrate)}</span>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        { 
                            props.tracklisted_price_usd * props.currency_rate === props.calculateprice.total_our_price * props.currentCurrRate
                            ? null
                            : props.tracklisted_price_usd * props.currency_rate < props.calculateprice.total_our_price * props.currentCurrRate
                            ?
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Tracklisted Cost {getshortlistedsymbol(props.currency)}/CT <b>{getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd/props.C_Weight * props.currency_rate)} </b>
                                            <br/>Tracklisted Cost price<b> {getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd * props.currency_rate)}</b> 
                                        </Tooltip>
                                    }
                                >
                                    <span><FaLongArrowAltUp className='text-danger cursor-pointer font-size-h3' /></span>
                                </OverlayTrigger>
                            :
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Tracklisted Cost {getshortlistedsymbol(props.currency)}/CT <b>{getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd/props.C_Weight * props.currency_rate)} </b>
                                            <br/>Tracklisted Cost price<b> {getshortlistedsymbol(props.currency)}{numberWithCommas(props.tracklisted_price_usd * props.currency_rate)}</b> 
                                        </Tooltip>
                                    }
                                >
                                    <span><FaLongArrowAltDown className='text-success cursor-pointer  font-size-h3' /></span>
                                </OverlayTrigger>
                        }
                    </div>
                    {/* <div className="text-danger font-weight-bold"><FaLongArrowAltUp /> {props.pricehike}</div> */}
                </div>
            </div>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
        </>
    );
};
