import React ,{useEffect} from "react";
import { Grid,  InputLabel } from "@material-ui/core";
import { InputField } from "../FormFields";

export default function LoginFrom(props) {

    useEffect(() => {
        if(props.iframesetting){
            // props.setFieldValue("white_label_logo",props.iframesetting.Logo)
            // props.setFieldValue("theme",props.iframesetting.Theme)
            // props.setFieldValue("wl_primarycolor",props.iframesetting.NaturalPrimaryColor)
            // props.setFieldValue("n_fontcolor",props.iframesetting.NaturalFontColor)
            // props.setFieldValue("lab_primary",props.iframesetting.LabPrimaryColor)
            // props.setFieldValue("lab_fontcolor",props.iframesetting.LabFontColor)
            // props.setFieldValue("muti_currency",props.iframesetting.Currency)
            // props.setFieldValue("tax_name",props.iframesetting.TaxName)
            // props.setFieldValue("tax_value",props.iframesetting.TaxValue)
            props.setFieldValue("cc_email",props.iframesetting.ifm_inquiry_cc_email)
            props.setFieldValue("website_url",props.iframesetting.ifm_website_url)
        }
    },[props.iframesetting])
    const {
        formField: {
            email,
            cc_email,
            website_url
        },
      
    } = props;

    useEffect(() => {
        props.setFieldValue("email",props.userinfo.mail)
    },[])


    return (
        <React.Fragment>
            <Grid container justify="start" alignItems="center" spacing={3} className="my-10">
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.IFRAME_SETUP_WEBSITEURL}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                        <InputField
                            name={website_url.name}
                            label={props.language.IFRAME_SETUP_WEBSITEURL}
                            placeholder="https://"
                            fullWidth
                            disabled={true}
                            className="bg-white"
                        />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.IFRAME_SETUP_EMAIL}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                        <InputField
                            name={email.name}
                            label={props.language.IFRAME_SETUP_EMAIL}
                            fullWidth
                            disabled={true}
                            className="bg-white"
                        />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.IFRAME_SETUP_ADDITIONAL_EMAIL}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                name={cc_email.name}
                                label={props.language.IFRAME_SETUP_ADDITIONAL_EMAIL}
                                fullWidth
                            
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
