import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";
export default function ApiRequested(){
    return(
        <>
        <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
            <div className="col-12">
               <h3>API Request Under Review</h3>
               <Alert className="my-5" variant="success">
               Your Diamond API request is received , we will review and activate your API as soon as possible.
                </Alert>
               <p className="text-muted">Your Diamond API request is received , we will review and activate your API as soon as possible.</p>
               <p className="text-muted">Usually it takes up-to 48 hours to activate your API.</p>
               <p className="text-muted">For any questions contact your account executive.</p>
            </div>
            <div className="api-home-svg api-success"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div>
        </div>
    </>
    )
}