import React ,{useEffect,useState} from "react";
import { NODE_API_URL } from "../../../../env_config";
import axios from "axios";
import { toAbsoluteUrl } from "../../../_helpers";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Tab, Alert, Nav } from "react-bootstrap";
import PersonalInfo from "./profileView/PersonalInfo"
import CompanyInfo from "./profileView/CompanyInfo"
import ShippingInfo from "./profileView/ShippingInfo"
import UploadLogo from "../KycUpdate/FormFields/UploadLogo";
import { CiUser } from "react-icons/ci";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { LiaShippingFastSolid } from "react-icons/lia";
export default function ProfileView({userinfo,setEdit,language}) {
    const [profileinfo,setProfileInfo] = useState(userinfo)
    const [rejectedcomment,setRejectedComment] = useState(null)
    useEffect(() => {
        if(userinfo.kyc_status === "KYC_PEND"){
            axios({
                method: "POST",
                url: NODE_API_URL + "/API/getKycEditData",
                headers: { "Content-Type": "application/json" },
                data: {
                  "user_id":userinfo.id
                },
              }).then((res) => {
                if (res && res.data && res.data.success) {
                    // console.log(res.data.data.isprofileapproved,"success")
                    setProfileInfo(res.data.data)
                }
              });
        }
        if(userinfo.kyc_status === "KYC_REJ"){
            axios({
                method: "POST",
                url: NODE_API_URL + "/API/getKycEditData",
                headers: { "Content-Type": "application/json" },
                data: {
                  "user_id":userinfo.id
                },
              }).then((res) => {
                if (res && res.data && res.data.success) {
                    setRejectedComment(res.data.data.reject_comment_cus)
                    setProfileInfo(res.data.data)

                }
              });
        }
    },[])
    return (
        <>
            <Tab.Container id="profileTab" defaultActiveKey="personal">
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className="profileLogoBox mx-auto">
                            {userinfo.company_logo?
                                <img
                                    alt="profile"
                                    className="img-fluid profileLogo rounded-circle bg-light p-2"
                                    width="125px"
                                    src={process.env.REACT_APP_ENV === "production"?`https://www.diamondsoncall.com/assets/documents/customer_logo/${userinfo.company_logo}`:`https://alpha.diamondsupload.com/assets/customer_logo/${userinfo.company_logo}`}
                                /> 
                            :
                                <img
                                    alt="profile"
                                    className="img-fluid profileLogo rounded-circle bg-light p-2"
                                    width="125px"
                                    src={toAbsoluteUrl(`/media/General/usericon.png`)}
                                />
                            }
                        </div>
                        <div className="font-size-h4 mt-5 text-capitalize font-weight-bold">
                            {userinfo.cname}
                        </div>
                        <div className="font-size-h6 text-muted mt-1 text-capitalize ">
                            <FaMapMarkerAlt className="mr-2" />
                            {userinfo.city}, {userinfo.country}
                        </div>
                        <UploadLogo/>
                        <div className="mt-10">
                        {userinfo.kyc_status === "KYC_PEND" ?(
                            <Alert className="mb-0" variant="warning">
                                 Your updated KYC is under review, please wait for approval.
                            </Alert>
                        ) : userinfo.kyc_status === "NO_KYC" ? (
                            <Alert className="mb-0" variant="danger">
                                 Your KYC not completed
                            </Alert>
                        ) : userinfo.kyc_status === "KYC_APPR" ? (
                            <Alert className="mb-0" variant="success">
                                 Your KYC Approved
                            </Alert>
                        ) : userinfo.kyc_status === "KYC_REJ" ? (
                            <Alert className="mb-0" variant="danger">
                                 Your KYC Rejected 
                                { rejectedcomment ? <div className="font-italic font-weight-bold font-size-lg">{rejectedcomment}</div> : ""}
                            </Alert>
                        ) :( "")}
                            
                        </div>
                        <Nav variant="pills" className="flex-column mt-md-10 mt-3 profile_nav custom_nav">
                            <Nav.Item>
                                <Nav.Link eventKey="personal"><CiUser className="mr-2"/>{language.PROFILEVIEW_USER_INFORMATION}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="company"><HiOutlineBuildingOffice2 className="mr-2"/>{language.PROFILEVIEW_COMPANY_INFORMATION}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="shipping"><LiaShippingFastSolid className="mr-2"/>{language.PROFILEVIEW_SHIPPING_INFORMATION}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="col-md-8 profile-card bg-white rounded p-8">
                        <Tab.Content>
                            <Tab.Pane eventKey="personal">
                                <PersonalInfo userinfo={profileinfo} setEdit={setEdit} contactbookuserinfo={userinfo} language={language}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="company">
                                <CompanyInfo userinfo={profileinfo} setEdit={setEdit} contactbookuserinfo={userinfo} language={language}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="shipping">
                                <ShippingInfo userinfo={profileinfo} setEdit={setEdit} contactbookuserinfo={userinfo} language={language}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </>
    );
}
