import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function CompareSkeleton() {
    return (
        <>
        <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <Skeleton variant="text" width={200} height={30} />
                        <Skeleton variant="text" width={400} height={20} />
                    </div>
                    <div className="card-toolbar flex-md-nowrap">
                        <Skeleton className="mr-3" variant="text" width={120} height={45} />
                        <Skeleton className="mr-3" variant="text" width={140} height={45} />
                        <Skeleton className="mr-3" variant="text" width={50} height={40} />
                        <Skeleton className="mr-3" variant="text" width={50} height={40} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 col-md-2">
                            <div className="row">
                                <div className="col-12" style={{opacity:'0'}}>
                                <Skeleton variant="rectangle" width={300}  height={390}/>
                                </div>

                                <div className="col-12 mt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>D</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>P</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center text-primary font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>A</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                <Skeleton variant="text" width={200} height={30} />
                                </div>
                            </div>
                        </div>
                        <div className="col-8 col-md-10 custom-scrollbar">
                            <div className="row" style={{minWidth:'650px'}}> 
                                
                                
                                <div className="col-3" key={1}>
                                <Skeleton variant="rectangle" width={300}  height={390}/>
                                    <div className="row text-center">
                                    <div className="col-12 mt-3 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                    </div>
                                </div>
                                <div className="col-3" key={2}>
                                <Skeleton variant="rectangle" width={300}  height={390}/>
                                    <div className="row text-center">
                                    <div className="col-12 mt-3 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                    </div>
                                </div>
                                <div className="col-3" key={3}>
                                <Skeleton variant="rectangle" width={300}  height={390}/>
                                    <div className="row text-center">
                                    <div className="col-12 mt-3 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                    </div>
                                </div>
                                <div className="col-3" key={4}>
                                <Skeleton variant="rectangle" width={300}  height={390}/>
                                    <div className="row text-center">
                                    <div className="col-12 mt-3 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                <div className="col-12 bg-gray-100 p-4 d-flex justify-content-center">
                                    <Skeleton variant="text" width={150} height={27} />

                                </div>
                                <div className="col-12 py-3 d-flex justify-content-center flex-wrap">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                    <Skeleton variant="text" width={200} height={30} />
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </>
    );
}
