import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { orderData, paymentData, shippingData } from "./Faqs/FaqData";
import styles from "../css/style.module.css"

const Faqs = () => {
  
  return (
    <>
      <title>FAQs - Diamonds on Call Limited</title>
      <div className={`${styles.jumbotron} ${styles.abouttitle} text-center`}>
        <h1 className={styles.customH1Title}>FAQs</h1>
      </div>
      <div className="contanier-fluid bg-white">
        <div className="container my-12">
        <Accordion className={styles.accordion}>
            <Card className={styles.accordionItem}>
              <Accordion.Toggle className={`${styles.accordionButton}`} as={Card.Header} eventKey="0">
                Order
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body className={styles.accordionBody}>
                  <Accordion className={styles.accordion}>
                    {orderData.map((val) => {
                      return (
                          <Card className={styles.accordionItem}>
                            <Accordion.Toggle className={`${styles.accordionButton}`} as={Card.Header} eventKey={val.id} key={val.id}>
                              {val.title}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={val.id}>
                              <Card.Body className={styles.accordionBody}>{val.content}</Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      })}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className={styles.accordionItem}>
            <Accordion.Toggle className={`${styles.accordionButton}`} as={Card.Header} eventKey="1">
              Payment
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body className={styles.accordionBody}>
                  <Accordion className={styles.accordion}>
                    {paymentData.map((val) => {
                      return (
                        <Card className={styles.accordionItem}>
                          <Accordion.Toggle className={`${styles.accordionButton}`} as={Card.Header} eventKey={val.id} key={val.id}>
                            {val.title}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={val.id}>
                            <Card.Body className={styles.accordionBody}>{val.content}</Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className={styles.accordionItem}>
              <Accordion.Toggle className={`${styles.accordionButton}`} as={Card.Header} eventKey="2">
              Shipping
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body className={styles.accordionBody}>
                  <Accordion className={styles.accordion}>
                    {shippingData.map((val) => {
                      return (
                        <Card className={styles.accordionItem}>
                          <Accordion.Toggle className={`${styles.accordionButton}`} as={Card.Header} eventKey={val.id} key={val.id}>
                            {val.title}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={val.id}>
                            <Card.Body className={styles.accordionBody}>{val.content}</Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </>
  );
};
export default Faqs;
