/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useFormik } from "formik";
import { NODE_API_URL } from "../../../env_config";
import {  TextField } from "@material-ui/core";
import * as yup from "yup";
import {
    Alert
 } from "react-bootstrap";
// import {BASE_URL} from "../../../env_config"
import axios from "axios"
import { useState } from "react";
import { FaRegSave } from "react-icons/fa";
const validationSchema = yup.object({
    currentPassword: yup
        .string("Enter your current password")
        .required("Password is required"),
    newPassword: yup
        .string("Enter your new password")
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required")
        .matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})","Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
    retypePassword: yup
        .string("retype your new password")
        .oneOf([yup.ref("newPassword"), null], "Password must match")
        .min(8, "Password should be of minimum 8 characters length")
        .required("retype Password is Required")
        .matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})","Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
});

export const Security = (props) => {
    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            retypePassword: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // alert(JSON.stringify(values, null, 2));
            // console.log(values,"values")
            const userData = JSON.parse(localStorage.getItem("userData"))
            const finalData = {
                "id":userData.CustomerId,
                oldPassword:values.currentPassword,
                newPassword:values.newPassword
            }
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/verifyPassword`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            }).then((res) => {
                if (res && res.data) {
                    // setDashData(res.data);
                    // setSalesData(res.data.salesExecutiveDetail);
                    // // console.log("data", res);
                    // console.log(res.data,"FETCHING")
                    setSuccessMessage(res.data)
                    // setData(res.data)
                    setErrorMessage()
                }
            }).catch(error => {
                // console.log(error.response.data,"ERROR")
                setErrorMessage(error.response.data)
                setSuccessMessage()
            })
        },
    });

    // const [openLoader, setOpenLoader] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);

    // const enableLoading = () => {
    //     setLoading(true);
    //     setOpenLoader(!openLoader);
    // };

    // const disableLoading = () => {
    //     setLoading(false);
    //     setOpenLoader(false);
    // };
    const [errmessage,setErrorMessage] = useState()
    const [successmsg,setSuccessMessage] = useState()
    return (
        <div className="row">
            <div className="col-12 mb-4 setting-title">{props.language.SETTING_CHANGE_YOUR_PASSWORD}</div>
            <div className="col-12">
                {errmessage?<Alert variant="danger">
                {errmessage}
                </Alert>:""}
                {successmsg?<Alert variant="success">
                    {successmsg}
                </Alert>:""}
                {/* <Alert variant="primary">
                    This is a primary message. Thank you
                </Alert>
                <Alert variant="warning">
                    This is a warning message. Thank you
                </Alert> */}
                <form onSubmit={formik.handleSubmit}>
                    <div className="col-md-6">
                        <TextField
                            id="currentPassword"
                            label={props.language.SETTING_CURENT_PASSWORD}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                            variant="outlined"
                            value={formik.values.currentPassword}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.currentPassword &&
                                Boolean(formik.errors.currentPassword)
                            }
                            helperText={
                                formik.touched.currentPassword &&
                                formik.errors.currentPassword
                            }
                        />
                        <TextField
                            id="newPassword"
                            label={props.language.SETTING_NEW_PASSWORD}
                            type="password"
                            autoComplete="new-password"
                            margin="normal"
                            variant="outlined"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.newPassword &&
                                Boolean(formik.errors.newPassword)
                            }
                            helperText={
                                formik.touched.newPassword &&
                                formik.errors.newPassword
                            }
                        />
                        <TextField
                            id="retypePassword"
                            label={props.language.SETTING_RETYPE_PASSWORD}
                            type="text"
                            autoComplete="retype-password"
                            margin="normal"
                            variant="outlined"
                            value={formik.values.retypePassword}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.retypePassword &&
                                Boolean(formik.errors.retypePassword)
                            }
                            helperText={
                                formik.touched.retypePassword &&
                                formik.errors.retypePassword
                            }
                        />

                        <div className="d-md-flex justify-content-between align-items-center mt-5">
                            <button
                                className="btn btn-hover-light-dark"
                                type="submit"
                            >
                                <FaRegSave className="mr-2" />
                                {props.language.SETTING_PASSWORD_SAVE}
                            </button>
                            {/* <a href={`${BASE_URL}/forgot`}>i forgot my password</a> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
