import * as React from "react";
import { Skeleton } from "@material-ui/lab";
export default function DetailSkeleton() {
    return (
        <>
            <div className="container">
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <Skeleton variant="text" width={100} />
                            </div>
                            <div className="col-md-6 d-flex flex-lg-nowrap flex-wrap justify-content-center">
                                <div className="detail_img_box mt-4 order-lg-2">
                                    <Skeleton className="mr-2" variant="rect" width={400} height={400} />
                                </div>
                                <ul className="mt-4 mr-3 no-bullets pl-0 d-flex d-lg-block order-lg-1">
                                    <li className="mx-md-0 mx-2">
                                        <Skeleton className="m-1" variant="rect" width={50} height={50} />
                                        <Skeleton className="m-1" variant="rect" width={50} height={50} />
                                        <Skeleton className="m-1" variant="rect" width={50} height={50} />
                                        <Skeleton className="m-1" variant="rect" width={50} height={50} />
                                        <Skeleton className="m-1" variant="rect" width={50} height={50} />
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 my-3 d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <Skeleton className="mr-2" variant="text" width={100}  />
                                            <Skeleton variant="circle" width={16} height={16} />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Skeleton className="mr-2" variant="text" width={100}  />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Skeleton className="mr-2" variant="text" width={100}  />
                                            <Skeleton variant="text" width={25} height={25}  />
                                        </div>
                                    </div>
                                    <div className="col-10 align-self-center">
                                        <Skeleton variant="text" width={300} height={30} />
                                    </div>
                                    <div className="col-2 align-self-center">
                                        <Skeleton variant="circle" width={40} height={40} />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <Skeleton variant="text" width={120} />
                                    </div>
                                    <div className="col-12 mt-4 d-flex">
                                        <Skeleton variant="text" width={100} />
                                        <Skeleton className="ml-8" variant="text" width={120} />
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="price_border_right"></div>
                                                <div className="px-0 d-flex align-items-center">
                                                    <Skeleton variant="text" width={80} height={25} />
                                                    <Skeleton className="ml-2" variant="circle" width={16} height={16} />
                                                </div>
                                                <div className={`d-flex small align-items-center`}> 
                                                    <Skeleton variant="text" width={30} height={15} />
                                                    <Skeleton className="ml-8" variant="text" width={30} height={15} />
                                                </div>
                                                <div>
                                                    <Skeleton variant="text" width={120} height={40} /> 
                                                </div>
                                                <div>
                                                    <Skeleton variant="text" width={80} height={10} /> 
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="px-0 d-flex align-items-center">
                                                    <Skeleton variant="text" width={80} height={25} />
                                                    <Skeleton className="ml-2" variant="circle" width={16} height={16} />
                                                </div>
                                                <div className={`d-flex small align-items-center`}> 
                                                    <Skeleton variant="text" width={30} height={15} />
                                                    <Skeleton className="ml-8" variant="text" width={30} height={15} />
                                                </div>
                                                <div>
                                                    <Skeleton variant="text" width={120} height={40} /> 
                                                </div>
                                                <div>
                                                    <Skeleton variant="text" width={80} height={10} /> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 d-flex">
                                        <Skeleton className="mr-4" variant="rect" width={120} height={40} />
                                        <Skeleton variant="rect" width={80} height={40} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3 min-card-header-height p-0">
                        <div className="card-title m-0">
                            {Array.from(Array(3), (e, i) => {
                                return (
                                    <Skeleton className="mr-4" variant="text" width={100} height={20} />
                                );
                            })}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {Array.from(Array(3), (e, i) => {
                                return (
                                    <div className="col-md-4">
                                        {Array.from(Array(4), (e, i) => {
                                            return (
                                                <div className="row mb-2">
                                                    <div className="col-6"><Skeleton className="mr-4" variant="text" width={100} height={20} /></div>
                                                    <div className="col-6"><Skeleton className="mr-4" variant="text" width={100} height={20} /></div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
