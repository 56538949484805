import React from 'react';
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

export const MockShortListColumn = (language,userinfo) => [

    {
        Header:language.CCMODE_SETUP_APPROVED_LISTNAME,
        accessor:'ListName',
        className:"align-middle text-center",
    },
    {
        Header:language.CCMODE_SETUP_APPROVED_CONSUMERNAME,
        accessor:'ConsumerName',
        className:"align-middle text-center",
    },
    {
        Header:language.WISHLIST_DATE,
        accessor: "CreatedAt",
        Cell: (row) => (
            <>
                { row.value ? moment.tz(row.value, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
            </>
        ),
        className:"text-center align-middle",
    },
    {
        Header:language.CCMODE_SETUP_APPROVED_NOSTONES,
        accessor:'ShortListedStones',
        className:"text-center align-middle",
    },
    {
        Header:language.SEARCHLIST_TOTALCARAT,
        // accessor:'Carats',
        accessor: (d) => {
            return (
                <>
                    <span className="text-primary"> {d.Carats.toFixed(2)}</span >
                </>
            );
        },
        className:"text-center align-middle",
    },
   
 
]