import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { IoCloseOutline, IoImageOutline } from "react-icons/io5";
export const ImagePopupCommon = (props) => {
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imageload, setImageLoad] = useState(false);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && show) {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [show]);
    const ImageUrl = (data) => {
        let image = "";
        if (data.ext_status === "Success") {
            image = data.ext_img_runtime;
        } else if (data.aws_image) {
            image = data.aws_image;
        }
        // console.log(image,"imageimage")
        return image;
    };
    return (
        <>
            {ImageUrl(props.data) ? (
                <div>
                    <IoImageOutline
                        className="cursor-pointer font-size-h5 text-primary mr-2"
                        onClick={() => handleShow()}
                    />
                     <Modal
                        className="p-0 video-modal"
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-fullscreen"
                    >
                        <div className="modal-header-custom">
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Close </Tooltip> } >
                                <IoCloseOutline   className="modal-icon" onClick={handleClose} />
                            </OverlayTrigger>
                        </div>
                        <Modal.Body className="modal-body-custom">
                            {!imageload ? 
                                <div className="loader-container">
                                    <CircularProgress />
                                <h6>Please Wait...</h6>
                            </div> : null}
                             {ImageUrl(props.data) && (
                               <img
                                    src={ImageUrl(props.data)}
                                    alt={props.C_Shape}
                                    style={{ objectFit: "cover" }}
                                    height="500px"
                                    width="500px"
                                    onLoad={() => setImageLoad(true)}
                                />
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            ) : (
              <span style={{ width: "28px" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            )}
        </>
    );
};
