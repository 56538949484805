import React ,{useEffect} from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { Accordion, Card } from "react-bootstrap";
import { InputField, DatePickerField, PhoneInput, SelectField } from "../FormFields";
import {
    makeStyles,
    Snackbar,
    SnackbarContent,
} from "@material-ui/core";
import clsx from "clsx";
import green from "material-ui/colors/green";

export default function LoginFrom(props) {
    
    const [alertOpen, setAlertOpen] = React.useState(false);
    function handleClose(event, reason) {
        setAlertOpen(false);
    }
  
    const useStyles = makeStyles((theme) => ({
        success: {
            backgroundColor: green[600],
        },
        
    }));

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }
    useEffect(() => {
        if(props.ccmodesetting){
            // props.setFieldValue("white_label_logo",props.ccmodesetting.Logo)
            // props.setFieldValue("theme",props.ccmodesetting.Theme)
            // props.setFieldValue("wl_primarycolor",props.ccmodesetting.NaturalPrimaryColor)
            // props.setFieldValue("n_fontcolor",props.ccmodesetting.NaturalFontColor)
            // props.setFieldValue("lab_primary",props.ccmodesetting.LabPrimaryColor)
            // props.setFieldValue("lab_fontcolor",props.ccmodesetting.LabFontColor)
            // props.setFieldValue("muti_currency",props.ccmodesetting.Currency)
            // props.setFieldValue("tax_name",props.ccmodesetting.TaxName)
            // props.setFieldValue("tax_value",props.ccmodesetting.TaxValue)
            // props.setFieldValue("email",props.ccmodesetting.mail)
            // props.setFieldValue("password",props.ccmodesetting.Password)
        }
    },[props.ccmodesetting])
    const {
        formField: {
            email,
            password,
            cofirm_password
        },
      
    } = props;
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        // console.log(props.userinfo,"userData")
        props.setFieldValue("email",props.userinfo.mail)
    },[])

    const copyurl = () => {
        navigator.clipboard.writeText("https://ciccio2.diazoom.com/")
        // alert("Copied to clipboard")
        setAlertOpen(true);
    }
    return (
        <React.Fragment>
            <Grid container justify="start" alignItems="center" spacing={3} className="my-5">
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_LOGIN_FORM_EMAIL}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                        <InputField
                            name={email.name}
                            label={props.language.CCMODE_SETUP_LOGIN_FORM_EMAIL}
                            fullWidth
                            disabled={true}
                            inputProps={{
                                style: { color: 'rgb(50, 50, 50)' }
                            }}
                        />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_LOGIN_FORM_PASSWORD}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                name={password.name}
                                label={props.language.CCMODE_SETUP_LOGIN_FORM_PASSWORD}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_LOGIN_FORM_CPASSWORD}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                name={cofirm_password.name}
                                label={props.language.CCMODE_SETUP_LOGIN_FORM_CPASSWORD}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.SETTING_CCMODE_ACCESS_LINK}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <input type="text" value="https://ciccio2.diazoom.com/" disabled id="copyurl" className="form-control disabled m-0"/> 
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fa fa-copy cursor-pointer" onClick={() => copyurl()}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
               
            </Grid>
            
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`Copied to Clipboard`}
                />
            </Snackbar>
        </React.Fragment>
    );
}
