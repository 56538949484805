import React from "react";
import { Field } from "formik";
import {toAbsoluteUrl} from "../../../../_helpers";
import SVG from "react-inlinesvg";
import {OverlayTrigger,Tooltip} from "react-bootstrap";

export const Shape = ({language,formik}) => {
    const ShapeData = ([
        {
            id: 1,
            shape:"round",
            value:"ROUND",
            checked:true
        }
    ]);
    React.useEffect(() => {
        formik.setFieldValue("shape", "ROUND");
        // formik.setFieldValue("minSize", minSize);
        // formik.setFieldValue("maxSieve", maxSize);
        // console.log("123");
    }, []);
    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-12">
                    <p className="font-size-lg font-weight-bold mb-0">{language.SHAPE_SHAPE}</p>
                </div>
                <div className="col-lg-12 flex-wrap mt-3 d-flex">
                    {ShapeData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="radio" name="shape" id={`${item.shape}_shape`} value={item.value} checked={item.checked}/>
                            {/* <span></span> */}
                            <div className="shapeBox selectBox">
                                <SVG src={toAbsoluteUrl(`/media/shape/svg_shape/parcel_${item.shape}.svg`)} />
                                <br/>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip" >{item.shape}</Tooltip>} >
                                    <span className="text-uppercase text-truncate">{item.shape}</span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};
