import React from "react";
import { Field } from "formik";
import {MultiSelect} from '../MultiSelect'

export const Origin = ({language,formik}) => {
    const originData = ([
        {
            id: 1,
            value:"Australia"
        },
        {
            id: 2,
            value:"Angola"
        },
        {
            id: 3,
            value:"Botswana"
        },
        {
            id: 4,
            value:"Brazil"
        },
        {
            id: 5,
            value:"Canada"
        },
        {
            id: 6,
            value:"Congo"
        },
        {
            id: 7,
            value:"DTC"
        },
        // {
        //     id: 8,
        //     value:"India" //new
        // },
        // {
        //     id: 9,
        //     value:"Indonesia" //new
        // },
        {
            id: 10,
            value:"Lesotho"
        },
        {
            id: 11,
            value:"Liberia"
        },
        {
            id: 12,
            value:"Namibia"
        },
        {
            id: 13,
            value:"Russia"
        },
        {
            id: 14,
            value:"Sierra Leone"
        },
        {
            id: 15,
            value:"South Africa"
        },
        {
            id: 16,
            value:"Tanzania"
        },
        // {
        //     id: 17,
        //     value:"United State" //new
        // },
        {
            id: 18,
            value:"Zimbabwe"
        },
        {
            id: 19,
            value:"Other"
        },
        // {
        //     id: 20,
        //     value:"Unknown"
        // },
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.MUI_ORIGIN}</p>
                </div>
                <div className="col-lg-12">
                    <MultiSelect language={language} options={originData} label={"Select Origin"} name={"origin"} formik={formik}/>
                    {/* {originData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="origin" id={`${item.origin}_origin`} value={item.value}/>
                            <div className="treatBox selectBox">
                                <span>{item.value}</span>
                            </div>
                        </label>
                    ))} */}
                </div>
            </div>
        </>
    );
};