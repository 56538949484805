import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function ApiDataSkeleton() {
    return (
        <>
            <div className="row">
                <div className="col-12 py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <Skeleton className="mr-3" variant="text" width={120} height={45} />
                        <Skeleton  variant="text" width={5} height={40} />
                    </div>
                    <div className="d-flex align-items-center">
                        <Skeleton className="mr-3" variant="text" width={35} height={45} />
                    </div>
                </div>
                <div className="col-12 pt-7 bg-white pb-0 mt-3">
                    <div className="table-responsive w-100">
                        <table className="table table-hover">
                            <thead className="custom_datatable_head">
                                <tr>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={120} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={100} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={120} height={20} /></th>
                                </tr>
                            </thead>
                           <tbody >
                                {Array.from(Array(6), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td> <Skeleton className="mx-auto" variant="text" width={120} height={20} /> </td>
                                            <td><Skeleton className="mx-auto" variant="text" width={70} height={20} /> </td>
                                            <td><Skeleton className="mx-auto" variant="text" width={100} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={80} height={30} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={40} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={150} height={20} /></td>
                                        </tr>
                                    );
                                })}
                           </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-between">
                    <Skeleton className="mr-3" variant="text" width={100} height={40} />
                    <Skeleton className="mr-3" variant="text" width={200} height={50} />
                    <Skeleton className="mr-3" variant="text" width={200} height={50} />
                </div>
            </div>
        </>
    );
}
