import React from "react";
import { Field } from "formik";

export const Percentage = ({language}) => {
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                {/* <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.SEARCHDIAMOND_PREFERENCE_PERCENTAGE}</p>
                </div> */}
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>TABLE %</label>
                                <div className="input-group input-group-sm">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Table_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Min_Table_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group input-group-sm">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Table_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Max_Table_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex align-items-end mt-2">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>DEPTH %</label>
                                <div className="input-group input-group-sm">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Min_Depth_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Min_Depth_Prc"
                                        placeholder="Min"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                            <div className="form-group mb-0 w-100">
                                <div className="input-group">
                                    <Field
                                        className="form-control"
                                        type="number"
                                        name="Max_Depth_Prc"
                                        min="0"
                                        step="0.01"
                                        id="Max_Depth_Prc"
                                        placeholder="Max"
                                        autoComplete="off"
                                    />
                                    <div className="input-group-append"><span className="input-group-text">%</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-items-end">
                            <div className="form-group mb-0 w-100 mr-2">
                                <label>RATIO</label>
                                <Field
                                    className="form-control"
                                    type="number"
                                    name="Min_Ratio"
                                    min="0"
                                    step="0.01"
                                    id="Min_Ratio"
                                    placeholder="From"
                                    autoComplete="off"
                                />
                                
                            </div>
                            <div className="form-group mb-0 w-100">
                                <Field
                                    className="form-control"
                                    type="number"
                                    name="Max_Ratio"
                                    min="0"
                                    step="0.01"
                                    id="Max_Ratio"
                                    placeholder="To"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
