import React, { useEffect } from "react"
import { makeStyles } from '@material-ui/core';
// import {InputLabel} from '@material-ui/core';
import {MenuItem} from '@material-ui/core';
import { FormControl } from "@material-ui/core";
import { GetCookies,SetCookies } from "../../../../_helpers/Cookies";
import {Select} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
export default function MultiLingual(){
    const classes = useStyles();
    const [language, setLanguage] = React.useState('en');
    const handleChange = (event) => {
        setLanguage(event.target.value)
        SetCookies("language",event.target.value)
        window.location.reload(false);

    }
    useEffect(() => {
        GetCookies("language").then(response => {
            // console.log(response,"LANGUAGECOOKIES")
            if(response){
                setLanguage(response)
            }
            else{
                setLanguage("en")
                SetCookies("language","en")
            }
        })
    },[])
    return(
        <div className="MultiLingual">
            <FormControl className={classes.formControl}>
              {/* <InputLabel id="demo-simple-select-label">Language</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                onChange={handleChange}
                variant="outlined"
                margin="dense"
              >
                  <MenuItem value={"en"}>English</MenuItem>  
                  {/* <MenuItem value={"hi"}>Hindi</MenuItem> */}
                  <MenuItem value={"es"}>Spanish</MenuItem>
              </Select>
            </FormControl>
        </div>
    )
}