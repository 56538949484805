/* eslint-disable */
import kycFormModel from './kycFormModel';
const {
  formField: {
    firstName,
    lastName,
    email,
    contanct_no,
    passport_id,
    passport_id_file,
    work_profile,
    useAddressForPaymentDetails,
    company_name,
    logo,
    address1,
    address2,
    city,
    state,
    zipcode,
    country,
    telephone,
    fax,
    invoice_email,
    website,
    comp_reg_no,
    comp_reg_no_file,
    nature_of_business,
    business_est_date,
    name_of_partner,
    contact_of_partner,
    shipping_address1,
    shipping_address2,
    shipping_city,
    shipping_state,
    shipping_zipcode,
    shipping_country,
    shipping_telephone,
    shipping_email,
    tax_id,
    port_of_discharge,
    same_as_shipping_address
  }
} = kycFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [contanct_no.name]: '',
  [passport_id.name]: '',
  [passport_id_file.name]: '',
  [work_profile.name]: '',
  [useAddressForPaymentDetails.name]: false,
  [company_name.name]: '',
  [logo.name]: '',
  [address1.name]: '',
  [address2.name]: '',
  [city.name]: '',
  [state.name]: '',
  [zipcode.name]: '',
  [country.name]: '',
  [telephone.name]: '',
  [fax.name]: '',
  [invoice_email.name]: '',
  [website.name]: '',
  [comp_reg_no.name]: '',
  [comp_reg_no_file.name]: '',
  [nature_of_business.name]: '',
  [business_est_date.name]: '',
  [name_of_partner.name]: '',
  [contact_of_partner.name]: '',
  [shipping_address1.name]: '',
  [shipping_address2.name]: '',
  [shipping_city.name]: '',
  [shipping_state.name]: '',
  [shipping_zipcode.name]: '',
  [shipping_country.name]: '',
  [shipping_telephone.name]: '',
  [shipping_email.name]: '',
  [tax_id.name]: '',
  [port_of_discharge.name]: '',
  [same_as_shipping_address.name]: false,
};
