import React from "react";
import ContactForm from "./Contact/ContactForm";
import LocalNumbers from "./Contact/LocalNumbers";
import Map from "./Contact/Map";
import Maplink from "./Contact/Maplink";
import styles from "../css/style.module.css"

const Contact = () =>{
    const googleMapsApiKey = "AIzaSyDOMP9G41YDFTPRj4y_K9KWbkZT29LvPL4";
    const modalMapStyles = [{
        "elementType": "geometry",
            "stylers": [{
                "color": "#f5f5f5"
            }]
        },
        {
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#616161"
            }]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [{
                "color": "#f5f5f5"
            }]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#bdbdbd"
            }]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
                "color": "#eeeeee"
            }]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#757575"
            }]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
                "color": "#e5e5e5"
            }]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#9e9e9e"
            }]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
                "color": "#ffffff"
            }]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#757575"
            }]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
                "color": "#dadada"
            }]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#616161"
            }]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#9e9e9e"
            }]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [{
                "color": "#e5e5e5"
            }]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [{
                "color": "#eeeeee"
            }]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
                "color": "#c9c9c9"
            }]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#9e9e9e"
            }]
        }
    ];
    return (
        <>
            <title>Contact us - Diamonds on Call Limited</title>
            <div className={`${styles.jumbotron} ${styles.abouttitle} text-center`}>
                <h1 className={styles.customH1Title}>Get in Touch</h1>
            </div>
            <LocalNumbers />
            <ContactForm />
            <div className={styles.web_contact_map}>
                <div className={`map-canvas ${styles.mapCanvas}`} id="map">
                    <Map
                        apiKey={googleMapsApiKey}
                        // center={[lat, lng]}
                        styles={modalMapStyles}
                        zoom={12}
                    />
                </div>
            </div>
            <div className="container-fluid bg-white">
                <div className={`container ${styles.ct_bottom_add}`}>
                    <div className="row ">
                        <Maplink />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;