/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Header from "./FrontComponent/Header"
import HomeRoutes from "./HomeRoutes"
import Footer from "./FrontComponent/Footer"
import Aos from "aos";
import "../../../../../node_modules/aos/dist/aos.css"
// import { Link, Switch, Redirect } from "react-router-dom";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
// import { ContentRoute } from "../../../../_metronic/layout";
// import Login from "./Login";
// import Registration from "./Registration";
// import ForgotPassword from "./ForgotPassword";
// import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {

  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Header />
      <HomeRoutes />
      <Footer />
    </>
  );
}
