import React from "react";

export const CheckBox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <div className="d-flex align-items-center justify-content-center">
            <label className="checkbox checkbox-outline align-self-center">
                <input
                    type="checkbox"
                    title="Select"
                    ref={resolvedRef}
                    {...rest}
                />
                <span className="mr-2"></span>
            </label>
        </div>
    );
});
