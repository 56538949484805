/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";

export const CcmodeRequested = (props) => {

    return (
        <>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">
                   <h3>{props.language.CCMODE_PENDING_REVIEW}</h3>
                   <Alert className="my-5" variant="success">
                        {props.language.CCMODE_PENDING_REVIEW1}
                    </Alert>
                   <p className="text-muted">{props.language.CCMODE_PENDING_REVIEW2}</p>
                   <p className="text-muted">{props.language.CCMODE_PENDING_REVIEW3}</p>
                </div>
                
                <img src={toAbsoluteUrl("/media/svg/icons/Custom/ccmode_green.svg")} alt="ccmode" className="img-fluid" width="50%" style={{ position: "absolute", bottom: "0", right: "0"}}/>
            </div>
        </>
    );
};
