import React, { Component } from "react";
import Typist from "react-typist";

class AnimatedText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typing: true
    };
    this.done = this.done.bind(this);
  }

  done = () => {
    this.setState({ typing: false }, () => {
      this.setState({ typing: true });
    });
  };

  render() {
    return (
      <div className="text-box">
        {this.state.typing && (
          <Typist
            onTypingDone={this.done}
            cursor={{ show: false, blink: false }}
            className="typed"
          >
            From Live Inventory
            <Typist.Backspace count={20} delay={2550} />
            With Independent Quality Check
            <Typist.Backspace count={31} delay={1100} />
            &amp; Consolidate Orders
            <Typist.Backspace count={20} delay={90} />
            With Quickest Shipment
            <Typist.Backspace count={23} delay={50} />
          </Typist>
        )}
      </div>
    );
  }
}

export default AnimatedText;
