import React from "react";
import save from "../../Images/save.png"
import certificate from "../../Images/certificate.png"
import strategy from "../../Images/strategy.png"
import api from "../../Images/api.png"
import styles from "../../css/style.module.css"

const Benefits = () =>{
    return(
        <>
        <div className={`${styles.bgF6f8fc} py-12`}>
			<div className="container">
				<div className="row justify-content-center " data-aos="fade-up" data-aos-once="true">
					<div className="col-md-9 col-lg-8 text-center justify-content-center">
						<h2 className={styles.customH2Title}>Benefits Of Partnering With Us</h2>
						<p>Partnering with us help you grow your Jewelery business with ease. you can focus on catering your customers and we will always have your back.</p>
					</div>
				</div>
			</div>
			<div className={`container ${styles.pws}`}>
				<div className="row justify-content-center ">
					<div className="col-lg-3 col-md-6 col-sm-12 p-3" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
						<div className={`bg-white ${styles.card}`}>
							<div className="">
								<img src={save} alt="no hidden cost" title="No Hidden Cost" height="64" width="63"/>
								<h4 className={`${styles.customH4Title} pt-8`}>No Hidden Cost</h4>
								<p>No signup fee, No extra charge. Pay what you see.</p>
							</div>
						</div>	
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 p-3" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
						<div className={`bg-white ${styles.card}`}>
							<div className="">
								<img src={certificate} alt="independent diamond quality check certificate" title="Independent quality check certificate" height="64" width="66"/>
								<h4 className={`${styles.customH4Title} pt-8`}>Quality Check</h4>
								<p>Ensuring all the diamonds ordered meets global quality standards including Shade, lustre &amp; eye clean.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 p-3" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
						<div className={`bg-white ${styles.card}`}>
							<div className="">
								<img src={strategy} alt="one stop solution" title="One Stop Solution" height="64" width="64"/>
								<h4 className={`${styles.customH4Title} pt-8`}>One Stop Solution</h4>
								<p>Buy, pay, ship and track, everything at one place.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-12 p-3" data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
						<div className={`bg-white ${styles.card}`}>
							<div className="">
								<img src={api} alt="API confirmations" title="API Confirmations" height="64" width="72"/>
								<h4 className={`${styles.customH4Title} pt-8`}>API Confirmations</h4>
								<p>Hold &amp; confirm using API and don&#8242;t miss any order on your website.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        </>
    )
}
export default Benefits;