const orderData = [
    {
        id: "0",
        title: " How do I place an order for diamonds?",
        content: "By Selecting diamond(s) you like and pressing on confirm button, you can place the order on the website.",
    },
    {
        id: "1",
        title: "Where are the diamonds Located?",
        content: "Diamonds are located in different locations such as Hong Kong, India, Israel, USA etc.",
    },
    {
        id: "2",
        title: "All diamonds on the website are available?",
        content: "All the diamonds you see are 99.99% available. But there are negligible chances that other buyer places the order for the same diamond at the same time and the diamonds becomes unavailable.",
    },
    {
        id: "3",
        title: " Can I hold a diamond?",
        content: "Yes you can hold the Diamond, but it will be released after intimating you, as soon as other buyer wants to confirm the diamond.",
    },
    {
        id: "4",
        title: "Do you make quality check for diamonds?",
        content: "Yes, we make physical quality checks twice for every diamond. Once you order and once before shipping. Thus making sure you get exactly what you ordered.",
    },
    {
        id: "5",
        title: "How do I know if diamond is without any tinge/milky?",
        content: "We have a special section for tinge and milky so that you know what is the intensity for tinge and milky. After you order if our grading team finds out that diamond has tinge or milky, we will intimate you before processing the order.",
    },
    {
        id: "6",
        title: "How do I know if diamond is eye clean?",
        content: "We have a special section for Eye clean, where you can know if diamond is eye clean with the naked eye. After you order if our grading team finds out that diamond is not eye clean, we will intimate you before processing the order.",
    },
    {
        id: "7",
        title: "What if I want to cancel the order?",
        content:  "Any diamond can be cancelled before the order is approved. Once the order is approved, it cannot be cancelled.",
    },
    {
        id: "8",
        title: "How much time does it take to approve the order?",
        content:  "It takes minimum 10 minutes to maximum 24 hours to approve the order.",
    },
    {
        id: "9",
        title: "Why is diamond order rejected?",
        content:  "Order may be rejected due to many reasons such as • Diamond already sold • Your payment is not processed on time • Quality of diamond doesn't match For exact details contact your sales executive.",
    },
    {
        id: "10",
        title: "What is Wish List?",
        content:  "Wish list is a section created to short list your selection. Once you add the diamond to your wish list, you can track the availability of the diamond live.",
    },
    {
        id: "11",
        title: "Is there any return policy?",
        content:  "We do not have any return policy.",
    },
];
const paymentData = [
    {
        id: "0",
        title: " What payment options do you have?",
        content: "Right now we only have payment by wire. Soon we will be introducing other payment options.",
    },
    {
        id: "1",
        title: "Who pays the bank wire charges?",
        content: "All the bank wire charges including the inter-mediatory bank charges are to be borne by the buyer.",
    }
];
const shippingData = [
    {
      id: "0",
      title: "When is the diamond Shipped?",
      content: "Diamonds will be shipped once the payment is credited in our bank account.",
    },
    {
      id: "1",
      title: "When will I receive the diamond?",
      content: "You will receive the diamond with the fastest shipping availability to your country. For example: USA , CANADA – next day delivery, AUSTRALIA, ITALY, GERMANY – 2 days, etc.",
    },
    {
        id: "2",
        title: "Who are your shipping partners?",
        content: "Diamonds will be shipped with FEDEX or UPS.",
    },
    {
        id: "3",
        title: "Is the shipment free?",
        content: "Once your total order reaches 7000 USD your shipping will be free. For lower value there will be shipping fee ranging from 50 USD to 120 USD.",
    },
    {
        id: "4",
        title: "Do I have to pay the import duty?",
        content: "Most of the countries have an import duty for diamonds, all the import duty and clearing agency fee are to be paid by the buyer.",
    },
    {
        id: "5",
        title: "Can we change the shipping address?",
        content: "Most of the countries have an import duty for diamonds, all the import duty and clearing agency fee are to be paid by the buyer.",
    },
    {
        id: "6",
        title: "Do I have to pay shipping fee per stone or per shipment?",
        content: "Shipping fee is charged on the number of shipment not on diamonds ordered. You can choose to have one shipment for multiple orders or different shipments for different orders.",
    },
    {
        id: "7",
        title: "Do you charge extra for insurance?",
        content: "Every Shipment is covered with insurance, door to door.",
    },
    {
        id: "8",
        title: "Can I pick up the diamond at your office/pick up locations?",
        content: "Yes, You can choose to pick up the diamond at our office/pick up locations. Please contact our sales executive for more details.",
    },
];

export {orderData,paymentData,shippingData}