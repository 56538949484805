/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { BsLightningFill,BsCircle,BsCheckCircleFill, BsArrowLeftShort } from "react-icons/bs";
import { FaAward, FaCalendarAlt, FaUserCircle } from "react-icons/fa";
import { IoCloseSharp } from 'react-icons/io5';
// import StockAPI from "./StockAPI.json"
import { toAbsoluteUrl } from "../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ConfirmGoods,HoldPopup,SwalWarn } from "../Popup";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Checkbox } from "@material-ui/core";
import SVG from "react-inlinesvg";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { AddCompareDiamond } from "../Popup/AddCompareDiamond";
import CompareSkeleton from "./CompareSkeleton";
import { LazyImageComponent } from "../widgets/tiles/LazyImageComponent";
import { ImagePopupCommon } from "../widgets/tiles/ImagePopupCommon";
import { VideoPopupCommon } from "../widgets/tiles/VideoPopupCommon";
import { LiaAwardSolid } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import { CopyUrlAlert } from "../Popup/CopyUrlAlert";
export const Comparison = (props) => {
    
    const [comparedata,setCompareData] = React.useState([])
    const [showcompare,setShowCompare] = React.useState(false)
    const [update,setUpdate] = React.useState(false)
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
        setSelected(newSelected);
      };
      const [swalWarnOpen,setSwalWarnOpen] = useState(false)
      const [alertMsg,setAlertMsg] = useState()
      const [diamondselected,setDiamondSelected] = useState([])
      const [buypopup,setBuyPopUp] = useState(false)
      const [holdpopup,setHoldPopUp] = useState(false)
      const [buypopupcinr,setBuyPopUpInr] = useState(0)
      const [buypopupctotal,setBuyPopUpTotal] = useState(0)
      const [totalcarat,setTotalCarat] = useState(0)
      const [totalinrcar,setTotalInrCar] = useState(0)
      const [diamondcount,setDiamondcount] = useState(0)
      const [totalourprice,setTotalOurPrice] = useState(0)
      const [mainimageclick,setMainImageClick] = useState(false)
      const [loader,setLoader] = useState(true)
      const [removedata,setRemoveData] = useState(null)
      const userData = JSON.parse(localStorage.getItem("userData"))
      useEffect(() => {
        const getlocalstorage = localStorage.getItem("comparestones")
        if(getlocalstorage){
            let comparestones = JSON.parse(getlocalstorage)
            localStorage.setItem("comparestones",JSON.stringify(comparestones))
            const finalData = {
              "Stock_id":comparestones.toString(),
              "Certi_NO":comparestones.toString(),
              "CustomerId":userData.CustomerId
            }
            axios({
              method: "POST",
              url: `${NODE_API_URL}/API/searchById`,
              headers: {
                  "Content-Type": "application/json",
              },
              data: finalData,
            })
            .then(function(res) {
                setLoader(false)
              setCompareData(res.data.data)
            })
            .catch(function(res) {
                setLoader(false)

            });
        }

      },[update])
      useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selected.map(value => {
            const finddata = comparedata.find(element => element.id.toString() === value.toString())
            if(finddata){
                temptotalcarat += finddata.C_Weight
                temptotalinrcar += (selected.length>0?finddata.OurRate/selected.length:0)
                temptotalourprice += finddata.OurPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * props.conversionrate):0)
        setTotalOurPrice(temptotalourprice * props.conversionrate)
    },[selected])
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
      const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = comparedata.find(val => value.toString() === val.id.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            // }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = comparedata.find(val => value.toString() === val.id.toString())
            if(finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
        setDiamondcount(diamondcount)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }  
      console.log(comparedata,"comparedatacomparedata")
      const RemoveStone = (data) => {
        console.log(data,"REMOVESTONES")
        OpenSwal()
        const getlocalstorage = localStorage.getItem("comparestones")
        if(getlocalstorage){
            let stones = JSON.parse(getlocalstorage).filter(val => val !== data.id.toString())
            stones = stones.filter(val => val !== data.Certi_NO.toString())
            localStorage.setItem("comparestones",JSON.stringify(stones))
            let newselected = selected.filter(diamond => diamond.toString() !== data.id.toString()) 
            setSelected(newselected)
            setUpdate(!update)
            setSwalWarnOpen(false)
            setRemoveData(null)
        }
      }
      const OpenSwal = (data) => {
        setSwalWarnOpen(true)
        setAlertMsg("are you sure you want to remove this stone?")
        setRemoveData(data)
      }
      const CloseSwal = () => {
        setSwalWarnOpen(false)
        setRemoveData(null)
      }
      const [mainimage,setMainImage] = useState()
      const [mainshape,setMainShape] = useState()
      const MainImageLoad = (diamonddata) => {
        console.log(diamonddata,"diamonddata")
        if(diamonddata.aws_image){
            setMainImageClick(true)
            setMainImage(diamonddata.aws_image)
            setMainShape(diamonddata.C_Shape)
        }else{
            setMainImageClick(true)
            setMainImage(`/media/shape/${diamonddata.C_Shape.toLowerCase()}-no.png`)
            setMainShape(diamonddata.C_Shape)
        }
      }
      const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.C_Color){
            title += " "
            if(row.C_Color.toLowerCase() === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
               
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        console.log(title.length)
        return title.length > 36 ? `${title.substring(0, 40)}...`: title
        // return title
    }
    const deselectAll = () => {
        setSelected([])
      };
    return (
        <>
            <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language}/>  
            <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language}/>  

            {loader?<CompareSkeleton/>:<div className="card card-custom card-sticky gutter-b">
                {selected.length>0?
                    <>
                        <div className="card-header custom-scrollbar flex-nowrap text-nowrap selecetedHeader" style={{minHeight:"50px"}}>
                            <div className="d-flex justify-content-center align-items-center natTab">
                                <button type="button" className="btn btn-primary mr-2 px-8 text-nowrap"  onClick={() => BuyDiamond(selected) }> <BsLightningFill className='font-size-h5' />{props.language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => HoldDiamond(selected) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {props.language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                                <span className="selected-diamond-box mx-2">
                                    <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {selected.length} {props.language.DIAMOND_COMPARISION_SELDIAMONDS} | {props.language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {props.symbol}{numberWithCommas(parseFloat(totalourprice))}
                                </span>
                                <span className="cursor-pointer btn btn-hover-light-dark mr-2"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Back </span>
                            </div>
                        </div>
                    </>
                :
                    <div className="card-header p-0" style={{minHeight:"50px"}}>
                        <div className="card-title align-items-center">
                            <div className="d-flex align-items-center">
                                {/* <span className="cursor-pointer btn btn-light-warning btn-sm mr-2 " onClick={()=>props.setShortlistSwitchByMail(false)}><BsArrowLeftShort /> Back</span> */}
                                <span className="card-label font-weight-bolder text-dark">  {props.language.DIAMOND_COMPARISION_DC} ({comparedata.length})
                                </span>
                            </div>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                            <span className="cursor-pointer btn btn-hover-light-dark mr-2"   onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Back </span>
                        </div>
                    </div>
                }
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 col-md-2">
                            <div className="row">
                                <div className="col-12" style={{opacity:'0'}}>
                                    <div className="row m-0 card rounded-0 grid_box gridData">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative" style={{height:"220px"}}>
                                            <img className="img-fluid" alt="shape"  src={toAbsoluteUrl(`/media/shape/round-no.png`)} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className=" d-inline-block cursor-pointer font-weight-bold" >N</div>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-4" />
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <div>
                                                <div className=" d-inline-block cursor-pointer font-weight-bold" >N</div>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2">
                                            <div className="text-center">
                                                <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/round.svg`)} />
                                                <span className="text-uppercase small">round</span >
                                            </div>
                                            <span className="text-dark font-weight-bold text-capitalize font-size-h5 flex-wrap d-flex ml-1"> 0.20 ROUND D IF EX EX EX NON NON NON </span>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between ">
                                            <span className="text-muted font-size-xl font-weight-bold">$/CT: <span className=" font-size-xl">123</span ></span>
                                            <span className="text-muted font-size-xl font-weight-bold">TOTAL: <span className=" font-size-xl">123</span ></span>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                                            <FaAward />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mb-5 px-2">
                                            <span>E</span>
                                            <span>S</span>
                                        </div>
                                    </div>
                                </div>
                          
                                <div className="col-12 mt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>D</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    {/* <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_STOCKID}</div> */}
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_DIAMONDTYPE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_SHAPE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CARAT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_COLOR}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CLARITY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CUT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_POL}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_SYMM}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_FLOU}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_LAB}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_CERTIFICATE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.SEARCHLIST_LOCATION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_DELIN}</div>
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>P</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    <div className="font-weight-bold mt-3">{props.language.MUI_MEASUREMENT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_TABLE}%</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_DEPTH}%</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_CROWN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_PAVILION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GIRDLE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GIRDLECONDTION}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_CULET}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_FEED_NATURAL_CHOOSENATURAL_FANCY_INTENSITY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DETAIL_OVERTONE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.CARAT_FANCY} {props.language.SEARCHLIST_COLOR}</div>
                                </div>
                                <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                    <span style={{opacity:'0'}}>A</span>
                                </div>
                                <div className="col-12 bg-gray-200 py-3">
                                    <div className="font-weight-bold mt-3">{props.language.MUI_SHADE}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_MILKY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_EYECLEAN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.DIAMOND_COMPARISION_AVIAIL}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_TREATMENT}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_KEY}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_BRAND}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_ORIGIN}</div>
                                    <div className="font-weight-bold mt-3">{props.language.MUI_GROWTHTYPE}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 col-md-10 custom-scrollbar">
                            <div className="row" style={{minWidth:'1500px'}}> 
                                { comparedata ? comparedata.slice(0, 4).map((data) =>(
                                    <div className={`compare-diamond-col-3 ${data.diamond_type === "N" ? "natTab" : "labTab"}`} key={data.id}>
                                        <div className="row m-0 card bg-white mx-auto grid_box gridData">
                                            <div className="col-12 p-0 d-flex align-items-center justify-content-center bg-gray-200 position-relative ribbon ribbon-top ribbon-left" style={{height:"220px"}}>
                                                {data.diamond_type === "N" || data.diamond_type === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                    
                                                { (data.diamond_type === "L" ) && (data.brown && data.brown === "HPHT")  ? 
                                                <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                                                    HPHT
                                                </div> : null }
                                                { (data.diamond_type === "L" ) && (data.brown && data.brown === "CVD")  ? 
                                                <div class="ribbon-target bg-light-success-solid-40 custom-left-ribbon" style={{top :"180px",left:"-3px"}}>
                                                    CVD
                                                </div> : null }

                                                <div className="gridCheck">
                                                    <Checkbox
                                                        checked={isSelected(data.id)}
                                                        className=""
                                                        inputProps={{ 'aria-labelledby': props.labelId }}
                                                        onClick={(event) => handleClick(event, data.id)}
                                                        icon={<BsCircle />} checkedIcon={<BsCheckCircleFill />}
                                                    />
                                                </div>
                                                <button type="button" className="close gridClose btn btn-light btn- btn-hover- font-weight-bolder font-size-h3" onClick={() => OpenSwal(data)}>
                                                    <span aria-hidden="true"><IoCloseSharp /></span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                                                <SwalWarn show={swalWarnOpen} message={alertMsg} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => RemoveStone(removedata)} onHide={() => CloseSwal()}/> 

                                                <LazyImageComponent data={data}/>
                                            </div>
                                            <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <div className="d-inline-block cursor-pointer" >{data.diamond_type + "-" + data.id}</div>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `Business Process`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `Guaranteed`
                                                                    : data.availability === "Not Available"
                                                                    ? `Not Available`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `Subject to Prior sale`
                                                                    : `Unknown`
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="availIco mx-2"
                                                            alt={data.availability}
                                                            src={toAbsoluteUrl(`/media/svg/${
                                                                data.availability === 'Memo' || data.availability === 'Hold'
                                                                    ? `availability_primary.svg`
                                                                    : data.availability === "Guaranteed"
                                                                    ? `availability_success.svg`
                                                                    : data.availability === "Not Available"
                                                                    ? `availability_danger.svg`
                                                                    : data.availability === "Subject to Prior sale"
                                                                    ? `availability_warning.svg`
                                                                    : `availability_blank_white.svg`
                                                            }`)}
                                                        />
                                                        
                                                    </OverlayTrigger>
                                                </div>
                                                <div >
                                                    {data.Lab && userData.CustomerType !=="7" && userData.CustomerType !=="11" ? <>{data.Lab}:</> : ""}{data.Certi_NO &&  userData.CustomerType !=="7" && userData.CustomerType !=="11" ? (
                                                        <>
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="mx-2 text-dark text-left"
                                                                href={
                                                                    data.Lab === "IGI"
                                                                        ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                                        : data.Lab === "GIA"
                                                                        ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                                        : data.Lab === "HRD"
                                                                        ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                                        : data.Lab === "GCAL"
                                                                        ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                                        : data.Certi_link
                                                                }
                                                            >
                                                                {data.Certi_NO}
                                                            </a>
                                                            <CopyUrlAlert text={data.Certi_NO}/>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                    
                                                </div>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between align-items-center">
                                                <div className="d-flex advTag justify-content-end align-items-center">
                                                    {/* shade */}
                                                    {renderShadeLabel(props.shade)}
                                                    
                                                    {data.Milky &&data.Milky.toLowerCase() === "no milky" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        NO MILKY
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-no_milky label-pill label-inline label-sm  mr-1 px-2">NO.ML</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {data.Milky &&data.Milky.toLowerCase() === "luster low" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Luster Low
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-luster_low label-pill label-inline label-sm  mr-1 px-2">LL</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {data.Milky &&data.Milky.toLowerCase() === "light milky" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        LIGHT MILKY
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-light_milky label-pill label-inline label-sm  mr-1 px-2">L.ML</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {data.Milky &&data.Milky.toLowerCase() === "milky" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        MILKY
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-milky label-pill label-inline label-sm  mr-1 px-2">H.ML</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    {/* eyeclean */}
                                                    {data.EyeC &&data.EyeC.toLowerCase() === "yes" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        100% Eye Clean
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-info label-pill label-inline label-sm  mr-1 px-2">100% EC</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }
                                                    {data.EyeC &&data.EyeC.toLowerCase() === "no" ?
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        No Eye Clean
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-danger label-pill label-inline label-sm  mr-1 px-2">EC: NO</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    :
                                                        null
                                                    }

                                                    { (props.diamond_type === "L" ) && (props.lab_treat &&data.lab_treat.toLowerCase() === "as grown") ? 
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        As Grown
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-success label-pill label-inline label-sm  px-2">As-G</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    : 
                                                        null
                                                    }
                                                    {/* natural treated */}
                                                    { (props.diamond_type === "N" ||data.diamond_type === "W") && (props.green &&data.green.toLowerCase() !== "none")  ? 
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Treated
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="label font-weight-bold label-light-danger label-pill label-inline label-sm  px-2">Tx</span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    : 
                                                        null
                                                    }
                                                </div>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <ImagePopupCommon data={data}/>
                                                    <VideoPopupCommon data={data} userinfo={userData}/>
                                                    {data.Certi_NO  && (
                                                        <span>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                                                }
                                                            >
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="mr-2 text-primary text-left"
                                                                    href={
                                                                    data.Lab === "IGI"
                                                                            ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                                            :data.Lab === "GIA"
                                                                            ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                                            :data.Lab === "HRD"
                                                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.Certi_NO}`
                                                                            :data.Lab === "GCAL"
                                                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                                            :data.Certi_link
                                                                    }
                                                                >
                                                                    <LiaAwardSolid />
                                                                </a>
                                                            </OverlayTrigger>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex align-items-center  px-2">
                                                <div className="text-center">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                {data.C_Shape}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${data.C_Shape.toLowerCase()}.svg`)} />
                                                    </OverlayTrigger>
                                                    <div className="text-uppercase small">{data.C_Shape}</div >
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            {data.C_Weight.toFixed(2)} {data.C_Shape}{" "}
                                                            {(data.C_Color ==="fancy") ? (<>{data.f_intensity} {data.f_overtone} {data.f_color}</>): data.C_Color}{" "}
                                                            {data.C_Clarity} {data.C_Cut}{" "}
                                                            {data.C_Polish} {data.C_Symmetry} {data.C_Fluorescence}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="text-dark  font-weight-bold text-capitalize font-size-h5 flex-wrap grid_title ml-1">
                                                        {GenerateTitle(data)}
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between align-items-end">
                                                <div className="d-flex flex-column">
                                                    <div className="small" >{data.discount_main ? <span className={`${(data.discount_main >= 0) ? 'text-success' : 'text-danger'}`}>{data.discount_main}%</span> : <span className={`text-success`}>0.00%</span>}</div>
                                                    {
                                                        props.symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(data.OurRate)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="text-muted small">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(data.OurRate * props.conversionrate)}</span ></span>
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="text-muted small">{props.symbol}/CT: <span className="text-dark font-size-xl">{props.symbol}{numberWithCommas(data.OurRate * props.conversionrate)}</span ></span>
                                                    }
                                                </div>
                                                <div className="grid-total-title">
                                                    <span className="text-dark small">{props.symbolname}</span>
                                                    {
                                                        props.symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(data.OurPrice)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="font-size-h3">{props.symbol}{numberWithCommas(data.OurPrice* props.conversionrate)}</span >
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="font-size-h3">{props.symbol}{numberWithCommas(data.OurPrice* props.conversionrate)}</span >
                                                    } 
                                                </div>
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 d-flex justify-content-between px-2 mtdr">
                                                <span className="text-muted font-size-sm">M: <span className="text-dark font-size-sm">{data.C_Length}*{data.C_Width}*{data.C_Depth}</span ></span>
                                                <span className="text-muted font-size-sm">T: <span className="text-dark font-size-sm">{data.C_TableP.toFixed(2)}%</span ></span>
                                                <span className="text-muted font-size-sm">D: <span className="text-dark font-size-sm">{data.C_DefthP.toFixed(2)}%</span ></span>
                                                {data.Ratio ? <span className="text-muted font-size-sm">R: <span className="text-dark font-size-sm">{data.Ratio?data.Ratio.toFixed(2):"-"}</span ></span> : ""}
                                            </div>
                                            <hr className="w-100 my-2" />
                                            <div className="col-12 mb-3 px-2">
                                                <span className="d-flex text-muted justify-content-between align-items-center font-size-sm">
                                                    <div>
                                                        <span className="text-dark-25 mr-2"><FaCalendarAlt /></span> 
                                                        {props.language.SEARCHGRID_SHIPPINGDAYS}: <span className="font-size-sm ml-1 text-dark ">{<>{data.shipping_days}{" "}{props.language.MUI_DAYS} </>}</span>
                                                    </div>
                                                    {data.country ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            className="text-capitalize"
                                                            overlay={
                                                                <Tooltip id="tooltip">{data.country}</Tooltip>
                                                            }
                                                        >
                                                            <img
                                                                className="flagIco mr-1"
                                                                alt={data.value}
                                                                src={toAbsoluteUrl(
                                                                    `/media/map/${data.country.toLowerCase()}.png`
                                                                )}
                                                                onError={e => {
                                                                    e.currentTarget.src =toAbsoluteUrl(
                                                                        `/media/map/other.png`
                                                                        )
                                                                    }}
                                                            />
                                                        </OverlayTrigger>
                                                    ) : (
                                                        null
                                                    )}
                                                </span>
                                                {data.show_supplier === 0 ? (
                                                    ""
                                                ):(
                                                    <>
                                                    {userData.CustomerType !=="7" && userData.CustomerType !=="11" ? (
                                                    <OverlayTrigger
                                                            placement="top"
                                                            className="text-capitalize"
                                                            overlay={<Tooltip id="tooltip">{data.C_Name}</Tooltip>}
                                                        >
                                                            <span className="d-flex text-muted align-items-center font-size-sm mt-2" >
                                                                <span className="text-dark-25 mr-2">
                                                                    <FaUserCircle className="mr-2"/> 
                                                                    {props.language.MUI_SUPPLIER}: 
                                                                    <span className="font-size-sm ml-1 text-dark text-truncate">{data.C_Name}</span>
                                                                </span>
                                                            </span>
                                                        </OverlayTrigger>
                                                    ):(
                                                        <span className="d-flex text-muted align-items-center font-size-sm mt-2" >
                                                            <span className="text-dark-25 mr-2">
                                                                <FaUserCircle className="mr-2" /> {props.language.MUI_SUPPLIER}: 
                                                                <span className="font-size-sm ml-1 text-dark text-truncate">************</span>
                                                                </span>
                                                            </span>
                                                    )} </> 
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-12">
                                                <div className=" d-flex align-items-center justify-content-between border px-3">
                                                    <label className="checkbox my-2">
                                                        <input 
                                                        type='checkbox'
                                                        checked={isSelected(data.id)}
                                                        className=""
                                                        onClick={(event) => handleClick(event, data.id)}
                                                         />
                                                        <span className="mr-2"></span>
                                                        {props.language.DIAMOND_COMPARISION_SELECTBUY}
                                                    </label> 
                                                    
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="text-center p-0">
                                                    {data.aws_image &&  data.aws_image !=="null" ? (
                                                        <img
                                                            className="img-fluid compareDiam mr-2"
                                                            alt={data.C_Shape}
                                                            src={data.aws_image}
                                                            style={{ backgroundColor: "#f4f4f4"}}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="img-fluid compareDiam mr-2"
                                                            alt={data.C_Shape}
                                                            src={toAbsoluteUrl(
                                                                `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                                            )}
                                                            height="130px"
                                                            style={{ backgroundColor: "#f4f4f4"}}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3 text-center">
                                                <div className="text-muted small font-weight-bold"> $/CT ${data.OurRate.toFixed(2)} </div>
                                                <div className=" font-size-h3 font-weight-bold">${data.OurPrice.toFixed(2)} </div>
                                                <div className={`${(data.discount_main >= 0) ? 'text-success' : 'text-danger'} small font-weight-bold`}>{data.discount_main}% </div>
                                            </div>*/}
                                            <div className="col-12 mt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                                {props.language.DETAIL_DIAMONDDETAILS}
                                            </div> 
                                            <div className="col-12 py-3 text-center">
                                                {/* <div className="mt-3">{data.diamond_type}-{data.id}</div> */}
                                                <div className="mt-3 ">{data.diamond_type === "L" ? "Lab Grown Diamond" : "Natural Diamond"}</div>
                                                <div className="mt-3 text-capitalize">{data.C_Shape?data.C_Shape:"-"}</div>
                                                <div className="mt-3">{data.C_Weight?data.C_Weight.toFixed(2):"-"}</div>
                                                <div className="mt-3">{data.C_Color?data.C_Color:"-"}</div>
                                                <div className="mt-3">{data.C_Clarity?data.C_Clarity:"-"}</div>
                                                <div className="mt-3">{data.C_Cut?data.C_Cut:"-"}</div>
                                                <div className="mt-3">{data.C_Polish?data.C_Polish:"-"}</div>
                                                <div className="mt-3">{data.C_Symmetry?data.C_Symmetry:"-"}</div>
                                                <div className="mt-3">{data.C_Fluorescence?data.C_Fluorescence:"-"}</div>
                                                <div className="mt-3">{data.Lab?data.Lab:"-"}</div>
                                                <div className="mt-3">
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className=""
                                                        href={
                                                            data.Lab === "IGI"
                                                                ? `https://www.igi.org/viewpdf.php?r=${data.Certi_NO}`
                                                                : data.Lab === "GIA"
                                                                ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                                : data.Lab === "HRD"
                                                                ? `http://ws2.hrdantwerp.com/HRdata.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                                : data.Lab === "GCAL"
                                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                                : data.Certi_link
                                                        }
                                                    >
                                                        {data.Certi_NO?data.Certi_NO:"-"}
                                                    </a>
                                                    <CopyUrlAlert text={data.Certi_NO}/>
                                                </div>
                                                <div className="mt-3">{data.country ? data.country : "-"}</div>
                                                <div className="mt-3">{data.shipping_days?data.shipping_days:"-"} days</div>
                                            </div>
                                            <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                                {props.language.MUI_PARAM_DETAILS}
                                            </div>
                                            <div className="col-12 py-3 text-center">
                                                <div className="mt-3">{data.C_Length?data.C_Length.toFixed(2):"0.00"}*{data.C_Width?data.C_Width.toFixed(2):"0.00"}*{data.C_Depth?data.C_Depth.toFixed(2):"0.00"}</div>
                                                <div className="mt-3">{data.C_TableP?data.C_TableP:"0.00"}%</div>
                                                <div className="mt-3">{data.C_DefthP?data.C_DefthP:"0.00"}%</div>
                                                <div className="mt-3">{data.Crn_Ht ? <>{data.Crn_Ht}%</> : "-"}, {data.Crn_Ag ? <>{data.Crn_Ag}º</> : "-"}</div>
                                                <div className="mt-3">{data.Pav_Dp ? <>{data.Pav_Dp}%</> : "-"}, {data.Pav_Ag ? <>{data.Pav_Ag}º</> : "-"}</div>
                                                <div className="mt-3">{data.gridle_per ? data.gridle_per : "-"}</div>
                                                <div className="mt-3">{data.gridle ? data.gridle : "-"}</div>
                                                <div className="mt-3">{data.cutlet ? data.cutlet : "-"}</div>
                                                <div className="mt-3">{data.f_intensity ? data.f_intensity : "-"}</div>
                                                <div className="mt-3">{data.f_overtone ? data.f_overtone : "-"}</div>
                                                <div className="mt-3">{data.f_color ? data.f_color : "-"}</div>

                                            </div>
                                            <div className="col-12 pt-3 bg-gray-100 p-5 text-center  font-weight-bold font-size-lg">
                                                {props.language.MUI_ADDITIONALDETAILS}
                                            </div>
                                            <div className="col-12 py-3 text-center">
                                                <div className="mt-3">{data.shade ? data.shade : "-"}</div>
                                                <div className="mt-3">{data.Milky ? data.Milky : "-"}</div>
                                                <div className="mt-3">{data.EyeC ? data.EyeC : "-"}</div>
                                                <div className="mt-3">
                                                    {data.availability === "Memo" ||
                                                        data.availability === "Hold"
                                                            ? `Business Process`
                                                            : data.availability === "Guaranteed"
                                                            ? `Guaranteed`
                                                            : data.availability === "Not Available"
                                                            ? `Not Available`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `Subject to Prior sale`
                                                            : `Unknown`}
                                                </div>
                                                {/* <div className="mt-3">{data.treatment ? data.treatment : "-"}</div> */}
                                                <div className="mt-3">{data.diamond_type === "L" ?data.lab_treat?data.lab_treat === "Treated"?"Post Growth Treatment":data.lab_treat:data.green?data.green:"-":data.green?data.green:"-"}</div>
                                                <div className="mt-3 text-truncate mx-auto"  style={{maxWidth:"110px"}}>
                                                    {data.Key_Symbols ?
                                                        <>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        {data.Key_Symbols}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span> {data.Key_Symbols}</span>
                                                            </OverlayTrigger> 
                                                        </>
                                                    : "-"}
                                                </div>
                                                <div className="mt-3">{data.canada_mark ? data.canada_mark : "-"}</div>
                                                <div className="mt-3">{data.diamond_type === "N" && data.brown ? data.brown : "-"}</div>
                                                <div className="mt-3">{data.diamond_type === "L" && data.brown ? data.brown : "-"}</div>
                                        
                                            </div>
                                        </div>
                                    </div>
                                )) : null}
                                {comparedata.length === 0?<>
                                <div className="col-3 compareEmptyBox text-center" key={0}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={1}>
                                   <div> <button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 1?<>
                                <div className="col-3 compareEmptyBox" key={1}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 2?<>
                                <div className="col-3 compareEmptyBox" key={2}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                {comparedata.length === 3?<>
                                <div className="col-3 compareEmptyBox" key={3}>
                                    <div><button className="btn btn-primary mr-2 text-nowrap"onClick={() => setShowCompare(true)}>Add Diamond</button></div>
                                </div>
                                </>:""}
                                <AddCompareDiamond show={showcompare} close={() => setShowCompare(false)} onHide={() => setShowCompare(false)} setUpdate={setUpdate} update={update}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};
