/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState,useEffect } from "react";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import moment from "moment-timezone";
import { Grid, InputLabel } from "@material-ui/core";

                    
export function TimeZone({ ccmodesetting,setFieldValue }) {

    const [tz, setTz] = useState(ccmodesetting && ccmodesetting.timeZone !== null ? ccmodesetting.timeZone : "Etc/GMT");
    const [datetime, setDatetime] = useState(moment());
// console.log(ccmodesetting && ccmodesetting.timeZone !== null ? ccmodesetting.timeZone:"NO")
// console.log(ccmodesetting)
// console.log(setFieldValue)
    useMemo(() => {
        const tzValue = tz.value ?? tz;
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);
   
    useEffect(() => {
        // setTz(ccmodesetting && ccmodesetting.timeZone !== null ? ccmodesetting.timeZone: "Etc/GMT")
        if(ccmodesetting && ccmodesetting.timeZone ){
            setTz(ccmodesetting && ccmodesetting.timeZone !== null ? ccmodesetting.timeZone: tz)
        }
        else{
            setFieldValue("timeZone",Intl.DateTimeFormat().resolvedOptions().timeZone)
            setTz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
    },[ccmodesetting])

    useEffect(() => {
        setFieldValue("timeZone",tz.value ? tz.value : tz)
    },[tz])
    return (
        <>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                    <InputLabel className="font-size-h6 font-weight-bold">
                        Select TimeZone *
                    </InputLabel>
                </Grid>
                <Grid item xs={6}>
                    <TimezoneSelect
                        value={tz.value ? tz.value : tz}
                        displayValue={"UTC"}
                        onChange={setTz}
                        timezones={{
                            ...allTimezones,
                            "America/Lima": "Pittsburgh",
                            "Europe/Berlin": "Frankfurt",
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}
