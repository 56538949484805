/* eslint-disable */
export default {
    formId: 'kycForm',
    formField: {
      naturaldiamond: {
        name: 'naturaldiamond',
        label: 'Colorless Diamonds',
        icon: 'white_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      naturalfancydiamond: {
        name: 'naturalfancydiamond',
        label: 'Fancy Color Diamonds',
        icon: 'fancy_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      lgwhitediam: {
        name: 'lgwhitediam',
        label: 'Colorless Diamonds',
        icon: 'lab_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      lgfancydiam: {
        name: 'lgfancydiam',
        label: 'Fancy Color Diamonds',
        icon: 'lab_fancy_dia_icon',
        requiredErrorMsg: 'Please Select'
      },
      diamondshape: {
        name: 'diamondshape',
        label: 'Diamond Shape',
        requiredErrorMsg: 'Please Select Diamond Shape'
      },
      carat_from: {
        name: 'carat_from',
        label: 'From',
        requiredErrorMsg: 'Please Select From value'
      },
      carat_to: {
        name: 'carat_to',
        label: 'To',
        requiredErrorMsg: 'Please Select To Value'
      },
      diamondcolor: {
        name: 'diamondcolor',
        label: 'Colorless Diamond',
        requiredErrorMsg: 'Please Select Colorless Diamond'
      },
      diamondfancy_color: {
        name: 'diamondfancy_color',
        label: 'Colors',
        requiredErrorMsg: 'Please Select Fancy'
      },
      diamondfancy_intensity: {
        name: 'diamondfancy_intensity',
        label: 'Intensity',
        requiredErrorMsg: 'Please Select Intensity'
      },
      diamondfancy_overtone: {
        name: 'diamondfancy_overtone',
        label: 'Overtone',
        requiredErrorMsg: 'Please Select Overtone'
      },
      diamondclarity: {
        name: 'diamondclarity',
        label: 'Diamond Clarity',
        requiredErrorMsg: 'Please Select Diamond Clarity'
      },
      lab_val: {
        name: 'lab_val',
        label: 'Diamond Lab',
        classes: 'labBox',
        requiredErrorMsg: 'Please Select Diamond Lab'
      },
      cuts: {
        name: 'cuts',
        classes: 'cuts',
        label: 'Diamond Cut',
        requiredErrorMsg: 'Please Select Diamond Cut'
      },
      polishes: {
        name: 'polishes',
        classes: 'polishes',
        label: 'Diamond Polish',
        requiredErrorMsg: 'Please Select Diamond Polish'
      },
      symmetries: {
        name: 'symmetries',
        classes: 'symmetries',
        label: 'Diamond Symm',
        requiredErrorMsg: 'Please Select Diamond Symm'
      },
      diamondflour: {
        name: 'diamondflour',
        // classes: 'diamondflour',
        label: 'Diamond Fluorescence',
        requiredErrorMsg: 'Please Select Diamond Fluorescence'
      },
      diamondshade: {
        name: 'diamondshade',
        // classes: 'diamondflour',
        label: 'Shade',
        requiredErrorMsg: 'Please Select Shade'
      },
      milky: {
        name: 'milky',
        classes: 'milky',
        label: 'Milky',
        requiredErrorMsg: 'Please Select Milky'
      },
      eyeclean: {
        name: 'eyeclean',
        classes: 'eyeclean',
        label: 'eyeclean',
        requiredErrorMsg: 'Please Select Eyeclean'
      },
      price_from: {
        name: 'price_from',
        label: 'From',
        requiredErrorMsg: 'Please Select From value'
      },
      price_to: {
        name: 'price_to',
        label: 'To',
        requiredErrorMsg: 'Please Select To Value'
      },
      total_price_from: {
        name: 'total_price_from',
        label: 'From',
        requiredErrorMsg: 'Please Select From value'
      },
      total_price_to: {
        name: 'total_price_to',
        label: 'To',
        requiredErrorMsg: 'Please Select To Value'
      },
      media: {
        name: 'media',
        label: 'Media Assets',
        value: "0",
        requiredErrorMsg: 'Please Select Media Assets'
      },


      lab_shape: {
        name: 'lab_shape',
        label: 'Diamond Shape',
        requiredErrorMsg: 'Please Select Diamond Shape'
      },
      lab_carat_from: {
        name: 'lab_carat_from',
        label: 'From',
        requiredErrorMsg: 'Please Select From value'
      },
      lab_carat_to: {
        name: 'lab_carat_to',
        label: 'To',
        requiredErrorMsg: 'Please Select To Value'
      },
      lab_color: {
        name: 'lab_color',
        label: 'Colorless Diamond',
        requiredErrorMsg: 'Please Select Colorless Diamond'
      },
      lab_fancy_color: {
        name: 'lab_fancy_color',
        label: 'Colors',
        requiredErrorMsg: 'Please Select Colors'
      },
      lab_fancy_intensity: {
        name: 'lab_fancy_intensity',
        label: 'Intensity',
        requiredErrorMsg: 'Please Select Intensity'
      },
      lab_fancy_overtone: {
        name: 'lab_fancy_overtone',
        label: 'Overtone',
        requiredErrorMsg: 'Please Select Overtone'
      },
      lab_clarity: {
        name: 'lab_clarity',
        label: 'Diamond Clarity',
        requiredErrorMsg: 'Please Select Diamond Clarity'
      },
      lab_lab: {
        name: 'lab_lab',
        label: 'Diamond Lab',
        classes: 'labBox',
        requiredErrorMsg: 'Please Select Diamond Lab'
      },
      lab_cuts: {
        name: 'lab_cuts',
        classes: 'cuts',
        label: 'Diamond Cut',
        requiredErrorMsg: 'Please Select Diamond Cut'
      },
      lab_polishes: {
        name: 'lab_polishes',
        classes: 'polishes',
        label: 'Diamond Polish',
        requiredErrorMsg: 'Please Select Diamond Polish'
      },
      lab_symmetries: {
        name: 'lab_symmetries',
        classes: 'symmetries',
        label: 'Diamond Symm',
        requiredErrorMsg: 'Please Select Diamond Symm'
      },
      lab_flourselect: {
        name: 'lab_flourselect',
        // classes: 'diamondflour',
        label: 'Diamond Fluorescence',
        requiredErrorMsg: 'Please Select Diamond Fluorescence'
      },
      lab_shadeselect: {
        name: 'lab_shadeselect',
        // classes: 'diamondflour',
        label: 'Shade',
        requiredErrorMsg: 'Please Select Shade'
      },
      lab_milky: {
        name: 'lab_milky',
        classes: 'milky',
        label: 'Milky',
        requiredErrorMsg: 'Please Select Milky'
      },
      lab_eyeclean: {
        name: 'lab_eyeclean',
        classes: 'eyeclean',
        label: 'Eyeclean',
        requiredErrorMsg: 'Please Select Eyeclean'
      },
      lab_min_dollarperct: {
        name: 'lab_min_dollarperct',
        label: 'From',
        requiredErrorMsg: 'Please Select From value'
      },
      lab_max_dollarperct: {
        name: 'lab_max_dollarperct',
        label: 'To',
        requiredErrorMsg: 'Please Select To Value'
      },
      lab_total_price_from: {
        name: 'lab_total_price_from',
        label: 'From',
        requiredErrorMsg: 'Please Select From value'
      },
      lab_total_price_to: {
        name: 'lab_total_price_to',
        label: 'To',
        requiredErrorMsg: 'Please Select To Value'
      },
      lab_all_media: {
        name: 'lab_all_media',
        label: 'Media Assets',
        value: "0",
        requiredErrorMsg: 'Please Select Media Assets'
      },


      primarycolor: {
        name: 'primarycolor',
        label: 'First Main Color',
        requiredErrorMsg: 'First Main Color is Required'
      },
      secondary: {
        name: 'secondary',
        label: 'Second Main Color',
        requiredErrorMsg: 'Second Main Color is Required'
      },
      fontcolor: {
        name: 'fontcolor',
        label: 'Font Color',
        requiredErrorMsg: 'Font Color is Required'
      },
      diamondmarkup: {
        name: 'diamondmarkup',
        label: 'Natural White Diamond Markup',
        requiredErrorMsg: 'Natural White Diamond Markup is Required',
        inputProps: '%'
      },
      diamondfancymarkup: {
        name: 'diamondfancymarkup',
        label: 'Natural Fancy Diamond Markup',
        requiredErrorMsg: 'Natural Fancy Diamond Markup is Required',
        inputProps: '%'
      },
      lab_diamondmarkup: {
        name: 'lab_diamondmarkup',
        label: 'Lab Grown White Diamond Markup',
        requiredErrorMsg: 'Lab Grown White Diamond Markup is Required',
        inputProps: '%'
      },
      lab_diamondfancymarkup: {
        name: 'lab_diamondfancymarkup',
        label: 'Lab Grown Fancy Diamond Markup',
        requiredErrorMsg: 'Lab Grown Fancy Diamond Markup is Required',
        inputProps: '%'
      },
      website_url: {
        name: 'website_url',
        label: 'https://',
        requiredErrorMsg: 'Website is required'
      },
      inquiry_email: {
        name: 'inquiry_email',
        label: 'Email',
        requiredErrorMsg: 'Email id is required'
      },
      inquiry_cc_email: {
        name: 'inquiry_cc_email',
        label: 'CC Email',
        requiredErrorMsg: 'CC Email id is required'
      },
      multi_currency: {
        name: 'multi_currency',
        label: 'Select Curreny',
        requiredErrorMsg: 'Please Select Any currency'
      },
      multi_language: {
        name: 'muti_currency',
        label: 'Select Curreny',
        requiredErrorMsg: 'Please Select Any currency'
      },
      dbfetch: {
        name: 'dbfetch',
        label: 'dbfetch',
        requiredErrorMsg: 'Please Select Any dbfetch'
      },
      noprice: {
        name: 'noprice',
        label: 'noprice',
        requiredErrorMsg: 'Please Select'
      }
    }
  };
  