/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect,useState}from "react";
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import { FeedKycPending } from "./FeedKycPending";
import {FeedRequest,IFrameRequested,IFrameRejected,IFrameSuspended} from "./"
import {RulePage} from "./RulePage";
import { IframeSetupNew } from "./IframeSetupNew";
import { IframeDashboard } from "./IframeDashboard";
import { FeedNoKyc } from "./FeedNoKyc";
import { FeedKycRej } from "./FeedKycRej";
// import ApiRequested from "../apiv3/ApiRequested";
// import SubscriptionPage from "../apiv3/SubscriptionPage";
// import { APISetUp } from "./APISetUp";
// import { FeedRequestV3 } from "./FeedRequestV3";
export const DiamondFeed = (props) => {
    // console.log(customeriframeorapi.feed_request,"USERINFO")
    
    const [updatedata,setUpdateData] = useState(false)
    const [customeriframeorapi,setCustomerIframeorAPI] = useState()
    const [customerapi,setCustomerAPI] = useState([])
    const [loader,setLoader] = useState(false)
    const [feedLoader,setFeedLoader] = useState(true)
    const [iframerules,setIframeRules] = useState([])
    const [updaterule,setUpdateRules] = useState(false)
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchIframeorAPISetting`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":userData.CustomerId
            },
        }).then(supreq => {
            setFeedLoader(false)
            if(supreq && supreq.data && supreq.data.data){
                // setFeedLoader(false)
                setCustomerIframeorAPI(supreq.data.data)
            }
        })
        setLoader(true)
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchRules`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":userData.CustomerId,
            },
        }).then(apireq => {
            setLoader(false)
            if(apireq && apireq.data && apireq.data.data && apireq.data.data.length){
                // console.log(apireq.data.data,"APIIIIIII")
                setFeedLoader(false)
                setCustomerAPI(apireq.data.data)
            }
            else{
                setCustomerAPI([])
            }
        }).catch(error => {
            setLoader(false)
            setFeedLoader(false)
        })
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/fetchIframeRules`,
            headers: { "Content-Type": "application/json" },
            data: {
                "user_id":userData.CustomerId,
            },
        }).then(iframereq => {
            setLoader(false)
            if(iframereq && iframereq.data && iframereq.data.data && iframereq.data.data.length){
                // console.log(iframereq.data.data,"APIIIIIII")
                setIframeRules(iframereq.data.data)
            }
            else{
                setIframeRules([])
            }
        }).catch(error => {
            setLoader(false)
        })
    },[updatedata,updaterule])
    
    return (
        <>
            <div className="card custom-card border-0 feed-theme" id="API-request-page">
               { feedLoader ? 
                    <div className="card-body text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                    </div>
               :
                    <>
                        <div className="card-body"> 
                            {customeriframeorapi ?
                                <div className={`${customeriframeorapi.feed_type === "API" && customeriframeorapi.api === 1 && customeriframeorapi.feed_request === 1 ? "p-0" : ""}`}>
                                    {props.userinfo.kyc_status === "KYC_APPR"?<>
                                    {customeriframeorapi.feed_request === 3?<IFrameRequested feed_type={customeriframeorapi.feed_type} language={props.language}/>:""}
                                    {customeriframeorapi.feed_request === 2?<IFrameRejected feed_type={customeriframeorapi.feed_type} language={props.language}/>:""}
                                    {customeriframeorapi.feed_request === 1 && customeriframeorapi.api !== 1?<IFrameSuspended feed_type={customeriframeorapi.feed_type} language={props.language}/>:""}
                                    {customeriframeorapi.feed_type === "IFrame Plug-in" && customeriframeorapi.feed_request === 1 && customeriframeorapi.api === 1 && customeriframeorapi.feed_updated === 0?<IframeSetupNew {...props} language={props.language}/>:""}
                                    {customeriframeorapi.feed_type === "IFrame Plug-in" && customeriframeorapi.feed_request === 1 && customeriframeorapi.api === 1 && customeriframeorapi.feed_updated === 1?<IframeDashboard {...props} language={props.language} customeriframeorapi={customeriframeorapi} iframerules={iframerules} setUpdateRules={setUpdateRules} updaterule={updaterule}/>:""}
                                    {customeriframeorapi.feed_type === "API" && customeriframeorapi.api === 1 && customeriframeorapi.feed_request === 1?<RulePage {...props} customerapi={customerapi} setUpdateData={setUpdateData} updatedata={updatedata} loader={loader} setCustomerAPI={setCustomerAPI} customeriframeorapi={customeriframeorapi}/>:""}
                                    </>:props.userinfo.kyc_status === "KYC_PEND"?<FeedKycPending/>:
                                        props.userinfo.kyc_status === "NO_KYC"?<FeedNoKyc/>:
                                        props.userinfo.kyc_status === "KYC_REJ"?<FeedKycRej/>:""}
                                </div>
                            : <FeedRequest userinfo={props.userinfo} language={props.language}/> }
                        </div>
                </>
               }
            </div >
        </>
    );
};
