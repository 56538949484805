import React from "react";
import Pin from "./marker.png";

const MyMarker = ({ marker }) => {
  return (
    <>
      <img src={Pin} alt="marker" style={{ transform: 'translate(-50%, -50%)' }}/> {marker}
    </>
  );
};

export default MyMarker;
