import React from "react"
import about2 from "../../Images/about2.png"
import styles from "../../css/style.module.css"

const WhatWeDo = () =>{
    return (
        <>
			<div className="container-fluid bg-white">
				<div className="container py-12">
					<div className="row p-6">
						<div className="col-md-12 col-sm-12 col-lg-6" data-aos="fade-up" data-aos-once="true">
							<h2 className={`pb-3 ${styles.customH2Title}`}>What are we doing?</h2>
							<p className={`font-size-h4 ${styles.l30}`}>Diamonds on call is building an online platform, where diamond (certified or parcel) buying experience becomes smooth and easy for jewelers around the world.</p>
							<p className={`font-size-h4 ${styles.l30}`}>On this secured platform, where hundreds of Manufacturers &amp; Sight-holders have listed their live diamond inventory with details description, images and video, to choose from. Once you are ready, simply select your diamond and buy on <b>diamondsoncall.com</b> and checkout with single click. Now sit back and relax, we independently check the quality of each diamond you ordered and ship all diamonds in single shipment with as fast as next day delivery with live tracking.</p>
							<p className={`font-size-h4 ${styles.l30}`}>Since 2016, we served jewelers in more 26+ countries and helped them save on up-to 15% operating cost, thus increase in overall profit. Our team works 24x7 to make sure the customer buying experience is fantastic. </p>
						</div>
						<div className={`col-md-12 col-lg-6 pt-6 text-center ${styles.wawd} align-self-center`}>
							<img src={about2} alt="online diamond buying selling platform" title="online diamond buying selling platform" width="450" data-aos="fade-up" data-aos-delay="200" data-aos-once="true" />
						</div>
					</div>
				</div>
			</div>
		</>
    )
}

export default WhatWeDo; 