/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect,useState} from "react";
import { Tab , Nav } from "react-bootstrap";

import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
// import { useLocation } from "react-router-dom";
import IFrameThemeSetting from "./IFrameThemeSetting";
import IFrameTaxSetting from "./IFrameTaxSetting"
import IFrameWebsiteSetting from "./IFrameWebsiteSetting"
import IFrameEmbeddedCode from "./IFrameEmbeddedCode";
export const IframeSettingNew = (props) => {
    
    // const [updatekey,setUpdateKey] = useState()
    const [key, setKey] = useState('embeddedcode');
    // const location = useLocation()
    const [iframesetting,setIFrameSetting] = useState(null)
    const [updatekey,setUpdateKey] = useState(null)
    const [iframerules,setIframeRules] = useState([])
    useEffect(() => {
        if(props.userinfo.id){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/fetchIframeorAPISetting`,
                headers: { "Content-Type": "application/json" },
                data: {
                    user_id:props.userinfo.id
                },
            }).then(res => {
                if(res && res.data && res.data.data && res.data.data){
                    setIFrameSetting(res.data.data)
                }
            })
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/fetchIframeRules`,
                headers: { "Content-Type": "application/json" },
                data: {
                    "user_id":props.userinfo.id,
                },
            }).then(iframereq => {
                if(iframereq && iframereq.data && iframereq.data.data && iframereq.data.data.length){
                    // console.log(iframereq.data.data,"APIIIIIII")
                    setIframeRules(iframereq.data.data)
                }
                else{
                    setIframeRules([])
                }
            })
        }
    },[updatekey])
    // console.log(props.userinfo.id,"USERIDDDD")
    // console.log(iframesetting,"iframesetting")
    return (
        <div className="container-fluid feed-theme">
            <Tab.Container id="settingTab" activeKey={key} onSelect={(k) => setKey(k)}>
                <div className="row">
                    <div className="col-md-3 card custom-card border-0 rounded-0">
                        <div className="card-body p-3">
                            <Nav
                                variant="pills"
                                className="flex-column custom_nav"
                            >
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="embeddedcode">
                                        <span className="font-size-h5 px-0 col-12">IFrame Embedded Code</span >
                                        <span className="text-muted small px-0 col-12">IFrame Embedded Code</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="ccmodetheme">
                                        <span className="font-size-h5 px-0 col-12">IFrame Theme</span >
                                        <span className="text-muted small px-0 col-12">iframe theme setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="ccmodetax">
                                        <span className="font-size-h5 px-0 col-12">IFrame Currency & Tax</span >
                                        <span className="text-muted small px-0 col-12">IFrame Currency & Tax setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border-bottom">
                                    <Nav.Link className="flex-wrap" eventKey="ccmodelogin">
                                        <span className="font-size-h5 px-0 col-12">IFrame Website Setting</span >
                                        <span className="text-muted small px-0 col-12">IFrame Website Setting</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <div className="col-md-9 card bg-white custom-scrollbar custom-card rounded-0"style={{maxHeight:"85vh"}}>
                        <div className="card-body">
                            <Tab.Content>
                                <Tab.Pane eventKey="embeddedcode">
                                    <IFrameEmbeddedCode userinfo={props.userinfo} language={props.language} iframesetting={iframesetting} setUpdateKey={setUpdateKey} iframerules={iframerules}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodetheme">
                                    <IFrameThemeSetting userinfo={props.userinfo} language={props.language} iframesetting={iframesetting}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodetax">
                                    <IFrameTaxSetting userinfo={props.userinfo} language={props.language} iframesetting={iframesetting}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ccmodelogin">
                                    <IFrameWebsiteSetting userinfo={props.userinfo} language={props.language} iframesetting={iframesetting}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
};
