/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    white_label_logo,
    wl_primarycolor,
    n_fontcolor,
    lab_primary,
    lab_fontcolor,
    theme,
    password,
    cofirm_password,
    email,
    muti_currency,
    tax_name,
    tax_value
  }
} = kycFormModel;

const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
const numberMatch = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/
const taxnameMatch = /^[a-zA-Z0-9-_]+$/
export default function ValidationSchema(taxinclusive){
  let validationarray = [
  Yup.object().shape({
    [white_label_logo.name]: Yup.string().required(`${white_label_logo.requiredErrorMsg}`),
    [wl_primarycolor.name]: Yup.string().required(`${wl_primarycolor.requiredErrorMsg}`),
    [n_fontcolor.name]: Yup.string().required(`${n_fontcolor.requiredErrorMsg}`),
    [lab_primary.name]: Yup.string().required(`${lab_primary.requiredErrorMsg}`),
    [lab_fontcolor.name]: Yup.string().required(`${lab_fontcolor.requiredErrorMsg}`),
    [theme.name]: Yup.string().required(`${theme.requiredErrorMsg}`),
  }),

  Yup.object().shape({
    [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
    [tax_value.name]: Yup.string().required(`${tax_value.requiredErrorMsg}`).matches(numberMatch,"Tax Value must be numeric with 2 decimal places and greater than zero"),
    [tax_name.name]: Yup.string().required(`${tax_name.requiredErrorMsg}`).matches(taxnameMatch,"Only Alpha Numeric & Hyphen are allowed")
  }),
  
  Yup.object().shape({
    [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
    [password.name]: Yup.string().min(8, "Password should be of minimum 8 characters length") .required(`${password.requiredErrorMsg}`) .matches(passworMatch,"Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
    [cofirm_password.name]: Yup.string() .min(8, "Password should be of minimum 8 characters length").oneOf([Yup.ref("password"), null], "Password must match") .required(`${cofirm_password.requiredErrorMsg}`) .matches(passworMatch,"Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
  }),
  ]
  if(!taxinclusive){
    validationarray[1] = Yup.object().shape({
      [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
      // [tax_value.name]: Yup.string().required(`${tax_value.requiredErrorMsg}`).matches(numberMatch,"Tax Value must be numeric with 2 decimal places and greater than zero"),
      // [tax_name.name]: Yup.string().required(`${tax_name.requiredErrorMsg}`).matches(taxnameMatch,"Only Alpha Numeric & Hyphen are allowed")
    })
  }
  return validationarray
};
