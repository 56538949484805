import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import styles from "../../css/style.module.css"

const GetinTouch = () =>{
    return (
        <>
            <div className={`${styles.abouttitle} ${styles.getInTouch} text-center text-light`}>
                <div className="row justify-content-center ">
                    <div className="col-md-6 text-center">
                        <h2 className="text-light pb-3 " data-aos="fade-up" data-aos-once="true">Have Question ? Get in touch!</h2>
                        <p className={`font-size-h4 ${styles.l30} text-center`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">Start working with Diamonds On Call that can provide everything<br /> you need to buy diamonds online.</p>
                        <Link className={`btn ${styles.btnSecondary} text-center`} to="/contact" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                            <FaPhoneAlt /> Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetinTouch;