/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect} from "react";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
import { NODE_API_URL } from "../../../env_config";
import axios from "axios";

export const TransparentPricing = (props) => {
    const [convinienceFee, setConvinienceFee] = React.useState([]);
    useEffect(() => {
        let isMounted = true;
        
        const userCountry = {
            country: `${props.userinfo.country}`,
        };
       
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/getConvinienceFee`,
            headers: { "Content-Type": "application/json" },
            data: userCountry,
        }).then((res) => {
            if (res && res.data) {
                isMounted && setConvinienceFee(res.data.data);
                // console.log("data", res);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [props.userinfo.country]);

    return (
        <div className="container tpricing">
            <div className="card">
                <div className="card-body">
                    <div className="row m-0">
                        <div className="col-12 tpricing_heading">
                            {props.language.TRANSPARENT_PRICING_PAR1}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-5 price_benefits">
                            <div className="price_benefits_title"> {props.language.TRANSPARENT_PRICING_BENEFITS} </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                                <SVG className="text-center" alt="consolidated" title="consolidated" src={toAbsoluteUrl("/media/General/ic_consolidated.svg")} />
                                <span>{props.language.TRANSPARENT_PRICING_CONSOLIDATED}</span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                               
                                <SVG className="text-center" alt="qc" title="qc" src={toAbsoluteUrl("/media/General/ic_qc.svg")} />
                                <span>{props.language.TRANSPARENT_PRICING_PAR2}</span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                                <SVG className="text-center" alt="inventory" title="inventory" src={toAbsoluteUrl("/media/General/ic_inventory.svg")} />
                                <span>
                                    {props.language.TRANSPARENT_PRICING_PAR3}
                                </span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div className="price_benedits_tile px-md-4 mt-4">
                                 <SVG className="text-center" alt="help" title="help" src={toAbsoluteUrl("/media/General/ic_help.svg")} />
                                <span>
                                    {props.language.TRANSPARENT_PRICING_PAR4}
                                </span>
                                <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </div>
                        <div className="col-md-7 px-0 px-md-3">
                            <div className="row price_tile">
                                <div className="col-12 price_tile_title">
                                    <span>{props.language.TRANSPARENT_PRICING_DIAMOND_VALUE}</span>
                                    <span>{props.language.TRANSPARENT_OURPRICE}</span>
                                </div>
                                <div className="col-12 price_tile_card mb-3 mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $0 to $1,250
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">$25</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 price_tile_card mb-3 mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $1,250 to $6,250
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">2%</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 price_tile_card mb-3 mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $6,250 to $21,000
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">$125</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 price_tile_card mt-2">
                                    <div>
                                        <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                        ></i>
                                        <span className="tprice_title">
                                            $21,000 & above
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <span className="tcharges">0.60%</span>
                                            <span className="charges_sub_title">
                                                /diamond
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 tpricing_con_heading">
                            {props.language.REQ_DIAMOND_CONVINIENCE_FEE}
                        </div>
                        <div className="col-12 tpricing_con_descr">
                            {props.language.TRANSPARENT_PRICING_PAR5}
                            <br />
                            {props.language.TRANSPARENT_PRICING_PAR6}<span className="font-weight-bolder"> {convinienceFee.ups_charges ? "UPS : $" + convinienceFee.ups_charges:""} {convinienceFee.fedex_charges?" & FedEx $" + convinienceFee.fedex_charges:""} {props.userinfo.country === "Hong Kong"?"Hk Delivery : $0":""}</span >
                        </div>
                        <div className="col-12 text-muted mt-4 text-center">
                            {props.language.TRANSPARENT_PRICING_PAR7}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
