import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Media } from "./Preference/Media";
import { Price } from "./Preference/Price";
import { Eyeclean } from "./EyeClean/Eyeclean";
import { Shade } from "./EyeClean/Shade";

// import { Showonly } from "./Preference/Showonly";


export const Advanced = (props) => {
       return (
        <>
            <Accordion className="search_accordion">
                <Card>
                    <Accordion.Toggle  as={Card.Header} eventKey="1" key="1">
                        {props.language.MUI_ADVANCEDETAILS}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Eyeclean language={props.language}/>
                           <Shade language={props.language} formik={props.formik}/>
                           <Price language={props.language}/>
                           <Media language={props.language}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
