import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function ShortListSkeleton() {
    return (
        <>
             <div className="card card-custom bg-white card-sticky gutter-b">
                <div className="card-body pt-5 pb-0">
                    <div className="table-responsive custom-scrollbar w-100">
                        <table className="table table-hover ">
                            <thead className="custom_datatable_head">
                                <tr>
                                    {Array.from(Array(6), (e, i) => {
                                        return (
                                            <th key={i}><Skeleton className="mx-auto" variant="text" width={60} height={20} /></th>
                                        );
                                    })}
                                </tr>
                            </thead>
                           <tbody >
                                {Array.from(Array(8), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Skeleton className="mx-auto" variant="text" width={50} height={25} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={100} height={25} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={100} height={25} /></td>
                                            {Array.from(Array(2), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={20} height={20} /></td>
                                                );
                                            })}
                                             <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {Array.from(Array(2), (e, i) => {
                                                        return (
                                                            <Skeleton className="mr-3" variant="text" width={30} height={30} key={i}/>
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                           </tbody>
                        </table>
                    </div>
                </div>
             </div>
        </>
    );
}
