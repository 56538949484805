import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { BsSortDown } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export default function Sorting(props) {
    const options = [
        { label: "Carat", id: "C_Weight" },
        { label: "Stock ID", id: "Stock ID" },
        { label: "Country", id: "country" },
        { label: "Shape", id: "Shape" },
        { label: "Color", id: "Color" },
        { label: "Clarity", id: "C_Clarity" },
        { label: "Cut", id: "C_Cut" },
        { label: "Polish", id: "C_Polish" },
        { label: "Symmetry", id: "C_Symmetry" },
        { label: "Fluorescence", id: "C_Fluorescence" },
        { label: "Lab", id: "Lab" },
        { label: "Our Rate", id: "OurRate" },
        { label: "Our Price", id: "OurPrice" },
        { label: "Measurement", id: "Meas." },
        { label: "TableP", id: "C_TableP" },
        { label: "DepthP", id: "C_DepthP" },
        { label: "Certificate", id: "Certificate" },
        { label: "Ratio", id: "Ratio" },
    ];

    const getSelectedOption = () => {
        const selectedOption = options.find(
            option => option.id === props.sortobj.id
        );
        if (selectedOption) {
            return `${selectedOption.label} (${props.sortobj.sortorder === "asc" ? "Low to High" : "High to Low"})`;
        }
        return "Sort";
    };

    const handleSelect = (id, sortorder) => {
        props.setSortObj({ id, sortorder });
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="clear" className="btn-hover-light-dark">
                {/* <BsSortDown /> {getSelectedOption()} <RiArrowDropDownLine /> */}
                <BsSortDown /> Sort <RiArrowDropDownLine />
            </Dropdown.Toggle>

            <Dropdown.Menu >
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll"
                    style={{ maxHeight: "400px", position: "relative" }}
                >
                    {options.map(option => (
                        <React.Fragment key={option.id}>
                            <Dropdown.ItemText className='border-bottom' style={{ padding:" 0.35rem 1.25rem"}}>
                                <span className='small text-muted font-weight-bold'>{option.label}</span>
                            </Dropdown.ItemText>
                            <Dropdown.Item 
                                style={{ padding:" 0.35rem 1.25rem"}}
                                onClick={() => handleSelect(option.id, "desc")}
                                active={props.sortobj.id === option.id && props.sortobj.sortorder === "desc"}
                            >
                               <span className=''> High to Low</span>
                            </Dropdown.Item>
                            <Dropdown.Item 
                                style={{ padding:" 0.35rem 1.25rem"}}
                                onClick={() => handleSelect(option.id, "asc")}
                                active={props.sortobj.id === option.id && props.sortobj.sortorder === "asc"}
                            >
                                Low to High
                            </Dropdown.Item>
                        </React.Fragment>
                    ))}
                </PerfectScrollbar>
            </Dropdown.Menu>
        </Dropdown>
    );
}
