import React, { useState,useRef } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import { FaExternalLinkAlt,FaInfoCircle} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
// import {GiCutDiamond} from "react-icons/gi"
import {Modal,Button} from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import VideoIframe from "../../detailPage/VideoIframe";
import SVG from "react-inlinesvg";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import { HOLDORBUY } from '../../../../env_config';  
// import {ConfirmGoods,HoldPopup} from '../Popup'
import { SwalWarn } from "../../Popup";
import { TracklistStone } from '../../Popup/TracklistPopup';
import { ImageExpandPopupCommon } from '../../widgets/tiles/ImageExpandPopupCommon';
import { VideoExpandPopupCommon } from '../../widgets/tiles/VideoExpandPopupCommon';
import { MainImageExpandedDetailsPopup } from '../../widgets/tiles/MainImageExpandedDetailsPopup';
import { CopyUrlAlert } from '../../Popup/CopyUrlAlert';
export default function ExpandedDetais({row,conversionrate,symbol,language,BuyDiamond,HoldDiamond,symbolname,userinfo}){
    const [show,setShow] = useState(false)
    const [diamonddetails,setDiamondDetail] = useState([])
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);

    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast] = useState(false)
    const [wishlistmessage] = useState(null)
    // const history = useHistory()
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.C_Color){
            title += " "
            if(row.C_Color.toLowerCase() === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        return title
    }
    const RouteToDetail = (row) => {
        const url = `/detail/${row.diamond_type}-${row.id}`;
        window.open(url, '_blank');
    };
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }
  
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };
   
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    const AddToTracklist = (row) => {
        setShow(true)
        setDiamondDetail([{
            "Certi_NO":row.Certi_NO,
            "diamond_type":row.diamond_type,
            "tracklisted_price_usd":row.OurPrice,
            "tracklisted_price":Math.round(row.OurPrice * conversionrate * 100)/100
        }])
    }
    return(
       <>
       <TracklistStone show={show} setShow={setShow} language={language} diamonddetails={diamonddetails} userinfo={userinfo} symbolname={symbolname} conversionrate={conversionrate}/>
        <div className={`expandedDetails ${row.diamond_type === "N" ? "natTab" : "labTab" } ${row.C_Color ==="fancy" ? "fancyExpand" : "" }`} >
            <div className="row pt-2">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        
                            <ImageExpandPopupCommon data={row}/>
                            <VideoExpandPopupCommon  data={row} userinfo={userinfo}/>
                        {row.aws_heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                            </li>
                            
                        ):('')}
                        {row.aws_arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                            </li>
                        ):('')}
                        {row.aws_asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4 ribbon ribbon-top">
                    {row.diamond_type === "N" || row.diamond_type === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                        <MainImageExpandedDetailsPopup data={row}/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 mt-3 mb-2 d-flex align-items-center">
                            <div className="mr-2 border-right font-size-sm">
                                {row.Lab && user.CustomerType !=="7" && user.CustomerType !=="11" ? <>{row.Lab}:</> : ""}{row.Certi_NO &&  user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                                    <>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-dark mx-2 text-left"
                                            href={
                                                row.Lab === "IGI"
                                                    ? `https://www.igi.org/verify-your-report/?r=LG${row.Certi_NO}`
                                                    : row.Lab === "GIA"
                                                    ? `https://www.gia.edu/report-check?reportno=${row.Certi_NO}`
                                                    : row.Lab === "HRD"
                                                    ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.Certi_NO}`
                                                    : row.Lab === "GCAL"
                                                    ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.Certi_NO}`
                                                    : row.Certi_link
                                            }
                                        >
                                            {row.Certi_NO} <FaExternalLinkAlt />
                                        </a>
                                        <CopyUrlAlert text={row.Certi_NO}/>
                                    </>
                                ) : (
                                    ""
                                )}
                               
                            </div>
                            <div className='font-size-sm mr-2'><div className="d-inline-block mr-2 cursor-pointer" onClick={(e) => (user.CustomerType !=="7" && user.CustomerType !=="11") ? RouteToDetail() :  DetailSemiAprrove() } >{row.diamond_type + "-" + row.id}</div>
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h4 text-capitalize font-weight-bold">{GenerateTitle(row)}</span>
                        </div>
                        <div className="col-2">
                            <OverlayTrigger
                                placement="top"
                                className="text-capitalize"
                                overlay={<Tooltip id="tooltip">Click to Tracklist</Tooltip>}
                            >
                                <span className="btn btn-light text-dark btn-icon" onClick={() => AddToTracklist(row)}>
                                    <SVG className="ol text-dark" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="23px" width="23px" />
                                </span>
                            </OverlayTrigger>
                            
                        </div>
                        <div className='col-12 advTag mb-3'>
                            {/* shade */}
                            {renderShadeLabel(row.shade)}
                            
                            { row.Milky && row.Milky.toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.Milky && row.Milky.toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.Milky && row.Milky.toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.Milky && row.Milky.toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* eyeclean */}
                            { row.EyeC && row.EyeC.toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.EyeC && row.EyeC.toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* growthtype */}
                            { row.diamond_type === "L" && (row.brown && row.brown === "CVD") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                CVD
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.diamond_type === "L" && (row.brown && row.brown === "HPHT") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                HPHT
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            
                            { (row.diamond_type === "L" ) && (row.lab_treat && row.lab_treat.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (row.diamond_type === "N" || row.diamond_type === "W") && (row.green && row.green.toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                       
                        {row.show_supplier === 0 ? (
                            ""
                        ):(
                            <div className="col-12">
                                { user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                    <span className="font-size-sm text-dark mr-5"><span className="text-muted font-size-sm  text-capitalize">{language.MUI_SUPPLIER} :</span> ***************</span>
                                ) :(
                                    <span className="font-size-sm text-dark mr-5"><span className="text-muted font-size-sm  text-capitalize">{language.MUI_SUPPLIER} :</span> {row.C_Name}</span>
                                )}
                                 <span className="text-dark font-size-sm"> 
                                        <span className='text-muted'>{language.SEARCHLIST_LOCATION}:</span> {row.country ? row.country : "-"}
                                        {row.country ? (
                                            <OverlayTrigger
                                                placement="top"
                                                className="text-capitalize"
                                                overlay={
                                                    <Tooltip id="tooltip">{row.country}</Tooltip>
                                                }
                                            >
                                                <img
                                                    className="flagIco ml-1"
                                                    alt={row.value}
                                                    src={toAbsoluteUrl(
                                                        `/media/map/${row.country.toLowerCase()}.png`
                                                    )}
                                                    onError={e => {
                                                        e.currentTarget.src =toAbsoluteUrl(
                                                            `/media/map/other.png`
                                                            )
                                                        }}
                                                />
                                            </OverlayTrigger>
                                        ) : (
                                            null
                                        )}
                                    </span>
                            </div>
                        )}
                        
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark"><span className="font-size-sm text-muted text-capitalize">{language.MUI_LISTPRICE}: </span>${row.raprate?row.raprate:"0000"}</span>
                            <span className="font-size-lg text-dark ml-8"><span className="font-size-sm text-muted text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.shipping_days}{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row"> 
                            {row.show_supplier === 0 ?(
                                ""
                            ) : (
                                <div className="col-6">
                                    <div className="price_border_right"></div>
                                    <div className="px-0">
                                        <h5>
                                            {language.MUI_SUPPLIERPRICE}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Price Provided by Supplier
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                </OverlayTrigger>
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.SupplierDiscount?row.SupplierDiscount.includes('-')?
                                        <div className="d-flex small text-danger align-items-center font-size-lg">{row.SupplierDiscount}% Off<span className="ml-8 text-dark">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center font-size-lg">{row.SupplierDiscount}% PR<span className="ml-8 text-dark ">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center font-size-lg">0.00% Off<span className="ml-8 text-dark">({symbolname})</span></div>} 
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.SupplierRate)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.SupplierRate * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.SupplierRate * conversionrate)}</>
                                            </span>
                                        }
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.SupplierPrice)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.SupplierPrice * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.SupplierPrice * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            )}
                                <div className="col-6">
                                    <div className="px-0">
                                        <h5 className="position-relative">
                                            {language.MUI_OURPRICE}
                                            {row.show_supplier === 0 ? (
                                                ""
                                            ):(
                                                <>
                                                    <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                    <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                        {props => (
                                                            <Tooltip id="overlay-example" {...props}>
                                                            Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                            </Tooltip>
                                                        )}
                                                    </Overlay>
                                                </>
                                            )}
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.discount_main?row.discount_main.includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.discount_main}% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.discount_main}% PR<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>}
                                        
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.OurPrice)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.OurPrice * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.OurPrice * conversionrate)}</>
                                            </span>
                                        }  
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.OurRate)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.OurRate * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.OurRate * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex align-items-end">
                            <button type="button" className="btn btn-primary px-12 mr-3" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? BuyDiamond([{original:row}]) :  DetailSemiAprrove() }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light text-dark mr-3" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? HoldDiamond([{original:row}]) :  DetailSemiAprrove() }> 
                                <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Hold.svg")} width="22.384" height="20.075" />
                                {language.MUI_HOLDDIAMONDBTN}
                            </button>
                            <button type="button" className="btn text-dark btn-light" onClick={(e) => (user.CustomerType !=="7" && user.CustomerType !=="11") ? RouteToDetail(row) :  DetailSemiAprrove() }> 
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="22.384" height="20.075" viewBox="0 0 22.384 20.075">
                                    <g id="ic_view_details" transform="translate(-794 -623.819)">
                                        <path className="cls-1545654" id="Path_28337" data-name="Path 28337" d="M3.485.017a1.07,1.07,0,0,0-.634.436C2.739.627.071,5.681.037,5.781a.983.983,0,0,0,.013.56c.031.081,1.441,2.053,4.779,6.683,4.366,6.06,4.741,6.574,4.823,6.614a.385.385,0,0,0,.467-.093c.146-.141,9.53-12.848,9.624-13.029a.762.762,0,0,0,.068-.558c-.022-.087-.459-.938-1.442-2.808C17.595,1.674,16.933.43,16.9.386a.927.927,0,0,0-.44-.338L16.345,0,9.966,0C5.978,0,3.548,0,3.485.017ZM8.57,1.377,7.275,3.24c-.652.938-1.19,1.7-1.195,1.69S4.927,1.468,4.864,1.266L4.85,1.222H8.68Zm6.53-.049c-.263.749-1.2,3.386-1.2,3.389s-2.717-3.451-2.724-3.475.672-.02,1.979-.02h1.986l-.038.107ZM4.314,3.569c.352,1.065.642,1.941.642,1.948s-.761.013-1.691.013-1.691,0-1.691-.01.466-.894,1.036-1.974c.628-1.192,1.041-1.955,1.05-1.939s.3.894.656,1.961Zm7.117.029L12.924,5.52l-1.433.005H8.619L7.178,5.52,8.542,3.592c.75-1.061,1.37-1.926,1.379-1.923S10.608,2.54,11.431,3.6Zm5.824.064c.53,1.015.963,1.851.963,1.857s-.743.011-1.653.011c-1.563,0-1.652,0-1.643-.036s1.314-3.677,1.326-3.7.015-.01.024,0,.449.849.98,1.863ZM6.7,10.728c1.723,5.2,1.734,5.24,1.705,5.208S2.982,8.417,1.86,6.852l-.068-.094h3.6L6.7,10.728Zm6.452-3.957c0,.042-3.314,9.424-3.324,9.412S6.714,6.8,6.714,6.771,13.155,6.752,13.155,6.771Zm4.9.022c-.284.4-6.788,9.146-6.793,9.141s.719-2.072,1.605-4.6L14.48,6.754h1.8c1.737,0,1.8,0,1.772.036Z" transform="translate(793.998 623.819)" fill="#212529"/>
                                        <path className="cls-1545654" id="eye" d="M11.958,8.205A6.7,6.7,0,0,0,6.015,4.5,6.7,6.7,0,0,0,.073,8.206a.676.676,0,0,0,0,.61,6.7,6.7,0,0,0,5.943,3.705,6.7,6.7,0,0,0,5.943-3.706A.676.676,0,0,0,11.958,8.205ZM6.015,11.518A3.008,3.008,0,1,1,9.023,8.51,3.008,3.008,0,0,1,6.015,11.518Zm0-5.013a1.991,1.991,0,0,0-.529.079,1,1,0,0,1-1.4,1.4A2,2,0,1,0,6.015,6.505Z" transform="translate(804.354 631.374)" fill="#212529"/>
                                    </g>
                                </svg>
                                {language.MUI_DETAILS}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.C_Color && row.C_Color.toLowerCase() ==="fancy") ? (
                                <>
                                    <div className="font-size-h5 text-dark  mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 ">  {row.f_intensity ? row.f_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 ">  {row.f_overtone ? row.f_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 ">  {row.f_color ? row.f_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 "> {row.C_Length} * {row.C_Width} * {row.C_Depth} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 "> {row.C_TableP ? row.C_TableP : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 "> {row.C_DefthP ? row.C_DefthP : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 ">
                                    {row.Crn_Ht ? row.Crn_Ht : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 ">
                                    {row.Crn_Ag ? row.Crn_Ag : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 ">
                                    {row.Pav_Dp ? row.Pav_Dp : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 ">
                                    {row.Pav_Ag ? row.Pav_Ag : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 ">
                                {row.Ratio?row.Ratio.toFixed(2):"-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 ">
                                {row.gridle_per ? row.gridle_per : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 ">
                                {row.gridle  ? row.gridle : "-"}
                                </div>
                            </div>
                            
                        </div>
                        {(user.CustomerType !=="7" && user.CustomerType !=="11") ? (
                            <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bold mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 ">
                                        {row.canada_mark ? row.canada_mark : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 ">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 ">
                                        {row.Milky  ? row.Milky : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 ">
                                        {row.EyeC ? row.EyeC : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 "> {row.cutlet  ? row.cutlet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 ">{row.Key_Symbols ? row.Key_Symbols : "-" }</div>
                                </div>
                                    {row.diamond_type === "L" ? 
                                        <>
                                            { (row.brown) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 ">  {row.brown ? row.brown : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.lab_treat) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.lab_treat === "Treated"?"Post Growth Treatment":row.lab_treat} </div>
                                                </div>
                                            ):(row.green) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.green ? row.green : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.brown) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 ">  {row.brown ? row.brown : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.canada_mark) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 ">  {row.canada_mark ? row.canada_mark : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.green) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 ">  {row.green ? row.green : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                            ) : ("")}
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_heart} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_arrow} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_asset} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${row.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${row.Certi_NO}` }
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}