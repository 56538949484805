import React from "react";
import { Field } from "formik";

export const Media = ({language}) => {
    const MediaData = ([
        {
            id: 2,
            media:"IMAGE",
            value:"IMAGE"
        },
        {
            id: 3,
            media:"VIDEO",
            value:"VIDEO"
        },
        {
            id: 4,
            media:"HEART & ARROW",
            value:"HA"
        },
        {
            id: 5,
            media:"ASSET SCOPE",
            value:"ASSET"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.SEARCHDIAMOND_PREFERENCE_MEDIA}</p>
                </div>
                <div className="col-lg-10">
                    {MediaData.map((item) => (
                        <label key={`${item.id}_media`} className="search_btn_check">
                            <Field type="checkbox" name="Media" id={`${item.media}_media`} value={item.value}/>
                            <div className="shortcutBox selectBox">
                                <span>{item.media}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};