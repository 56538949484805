import React, { useEffect, useRef, } from "react"
import inventory from "../../Images/inventory.jpg"
import inventoryVideo from "../../videos/inventory.mp4"
import styles from "../../css/style.module.css"

const Liveinv = () =>{
    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };
    useEffect(() => {
        attemptPlay();
    }, []);
    return (
        <>
            <div className="container-fluid bg-white">
                <div className="container py-8 " id="story">
                    <div className="row align-items-center pt-3">
                        <div className={`${styles.block1} col-md-6 col-sm-12 order-md-2 order-sm-1 order-lg-2`} data-aos="fade-up" data-aos-once="true">
                            <h3 className={`p-3 ${styles.titleH3}`}>Live Inventory</h3>
                            <p className={`p-3 font-size-h4 ${styles.l30}`}>We carry live inventory from 100+ diamond manufacturers and sight-holders around the globe. You can freely search thousands of diamonds on a single platform with complete details like No BGM &amp; Eye-clean. Including HD Images and Videos we also have heart, arrow and aset images.</p>
                        </div>
                        <div className={`col-md-6 order-md-1 order-sm-2 order-lg-1 text-center ${styles.videowhy}`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                            <video
                                muted
                                loop
                                preload="none"
                                poster={inventory}
                                src={inventoryVideo}
                                id="home_video"
                                ref={videoEl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Liveinv;