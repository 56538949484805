import React from "react";
import { Field } from "formik";

export const GrowthType = ({language}) => {
    const growthTypeData = ([
        {
            id: 1,
            growthType:"CVD"
        },
        {
            id: 2,
            growthType:"HPHT"
        },
        {
            id: 3,
            growthType:"UNKNOWN"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0 text-uppercase">{language.MUI_GROWTHTYPE}</p>
                </div>
                <div className="col-lg-10">
                    <div className="row">
                        {growthTypeData.map((item) => (
                            <label key={`${item.id}_growthtype`} className="search_btn_check">
                                <Field type="checkbox" name="growthtype" id={`${item.growthType}_growthtype`} value={item.growthType}/>
                                <div className="treatBox selectBox">
                                    <span>{item.growthType}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};