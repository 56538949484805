/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cs from "../css/widgets.module.css";
// import { GiCutDiamond } from "react-icons/gi";
import { toAbsoluteUrl } from "../../../_helpers";

export const DiamondRequestTile = (props) => {
  return (
    <>
      <div className={`card card-custom gutter-b ${cs.diamondRequestTile} ${cs.dashTileHeight}`}  >
        {/* <a  href="#" > */}
            <div className="">
              <div className="small-box app-info-box p-8 flex-column justify-content-center">
                  <p className="mb-5 text-center text-white font-weight-bold">{props.language.DASHBOARD_DOWNLOAD_APP}</p>
                  <div className="row">
                      <div className="col-6">
                        <a className="mr-2 app_img" rel="noreferrer" href="https://apps.apple.com/us/app/diamonds-on-call/id1410197909" target="_blank" >
                          <img className="img-fluid" alt="" src={toAbsoluteUrl(`/media/svg/app-store.svg`)} />
                        </a>
                      </div >
                      <div className="col-6">
                        <a className="app_img" rel="noreferrer" href="https://play.google.com/store/apps/details?id=diamondsoncall.com" target="_blank" style={{maxWidth:"162px"}}>
                          <img className="img-fluid" alt="" src={toAbsoluteUrl(`/media/svg/google-play.svg`)} />
                        </a>
                      </div >
                  </div>
              </div>
            </div>
        {/* </a> */}
      </div>
    </>
  );
}
