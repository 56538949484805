/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";

export const FeedNoKyc = (props) => {

    return (
                <>
                <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                    <div className="col-12">
                       <h3>Diamond Feed Center</h3>
                       <Alert className="my-5" variant="danger">
                       Your profile is incomplete.
                        </Alert>
                        <Alert className="my-5" variant="danger">
                        Let's Finish setting up your profile. It's best to complete your and enjoy uninterrupted services.
                        </Alert>
                    </div>
                    <div className="api-home-svg api-failed"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div>
                </div>
            </>
    );
};
