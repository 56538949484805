import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { CircularProgress } from '@material-ui/core';
import { ImageSharePopup } from "./ImageSharePopup";
// import axios from "axios"
// import { NODE_API_URL } from "../../../../env_config";
export default function VideoIframe({ showVideo, handleClose, src ,data}) {
  const [frameloaded, setFrameLoaded] = useState(false)
  const [showimage,setShowImage] = useState(false)
  const FrameFunction = () => {
    // console.log("DONE")
    setFrameLoaded(true)
    // var iframe = document.getElementById('iframedata');
    // console.log(iframe.contentWindow,"iframeDoc")
    // console.log($("#iframedata"),"FRAMEID")
  }
  const copyurl = (video) => {
    navigator.clipboard.writeText(video)
    setShowImage(true)
}
useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'Escape' && showVideo) {
      handleClose();
    }
  };

  document.addEventListener('keydown', handleKeyDown);

  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, [showVideo, handleClose]);
  return (
    <div>
      <ImageSharePopup show={showimage} onClose={() => setShowImage(false)} src={src}/>
      <Modal
        show={showVideo}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        
        <Modal.Body>
          {frameloaded ? "" : <div>
            <div style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              marginTop:"50"
            }}>
              <CircularProgress
                      // style={{marginLeft: '40%'}}
              />
              <h6>Please Wait...</h6>
            </div>
          </div>}
          <iframe id="iframedata" title="Video" src={src} height='450px' width='100%' frameborder='0' onLoad={FrameFunction}></iframe>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="primary"
            onClick={() => copyurl(src)}
          >
            Share
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}