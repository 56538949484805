import React ,{useEffect} from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, DatePickerField, PhoneInput, SelectField } from "../FormFields";

export default function LoginFrom(props) {
    const {
        formField: {
            email,
            password,
            cofirm_password
        },
      
    } = props;
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        // console.log(props.userinfo,"userData")
        props.setFieldValue("email",props.userinfo.mail)
    },[])
    return (
        <React.Fragment>
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center">
                {props.language.CCMODE_SETUP_LOGIN_FORM}
            </Typography>
            <p className="mb-5 text-center">
                {props.language.CCMODE_SETUP_LOGIN_FORM_MSG1}
            </p>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                    <InputField
                        name={email.name}
                        label={props.language.CCMODE_SETUP_LOGIN_FORM_EMAIL}
                        fullWidth
                        disabled={true}
                        inputProps={{
                            style: { color: 'rgb(50, 50, 50)' }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <InputField
                        name={password.name}
                        label={props.language.CCMODE_SETUP_LOGIN_FORM_PASSWORD}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <InputField
                        name={cofirm_password.name}
                        label={props.language.CCMODE_SETUP_LOGIN_FORM_CPASSWORD}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
