import React from "react";
import about1 from "../../Images/about1.svg"
import styles from "../../css/style.module.css"

const Title = () =>{
    return (
        <>
            <div className={`${styles.jumbotron} ${styles.abouttitle} text-center`}>
				<h1 className={styles.customH2Title}>Why Us</h1>
			</div>
            <div className="container-fluid bg-white">
				<div className="container py-8 py-xl-15 px-xl-20">
					<div className="row justify-content-center p-6">
						<div className="col-md-6 col-sm-12 ">
							<img className="pb-3" src={about1} alt="transparency and trust" title="Transparency and trust" width="80" data-aos="fade-up" data-aos-once="true" />
							<h2 className={styles.titleH2} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">We prioritize your comfort when you buy diamonds online.</h2>
						</div>
						<div className="col-md-6 col-sm-12 pt-14" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
							<p className={`pt-12 ${styles.l30}`}>Diamonds on call is a solution to all your hassle, when you buy diamonds. We put together innovation, technology and experience to enhance your diamond buying experience and to provide accurate information.</p>
						</div>
					</div>
				</div>
			</div>
		</>
    )
}

export default Title;