import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function ProformaSkeleton() {
    return (
        <>
            
            <div className="row bg-white m-0 border-left-primary mb-5">
                <div className="col-12 px-5 py-2">
                    <Skeleton variant="text" width={260} height={40}/>
                </div>
            </div>
            <div className="card bg-white custom-card gutter-b">
                <div className="card-header py-3 px-5 d-flex justify-content-between align-items-center border-bottom-0">
                    <div>
                        <Skeleton variant="text" width={200} height={30}/>
                        <Skeleton variant="text" width={150} height={10}/>
                    </div>
                    <div className="d-flex">
                        <Skeleton className="mr-3" variant="rect" width={90} height={30}/>
                        <Skeleton variant="rect" width={70} height={30}/>
                    </div>
                </div>
                    <div className="row border-bottom m-0 mt-5 pb-4">
                        <div className="col-12"><Skeleton className="ml-5" variant="text" width={100} height={25}/></div>
                    </div>
                <div className="card-body pb-8">
                    <div className="row m-0">
                        <div className="col-md-3">
                            <Skeleton className="mb-4" variant="text" width={120} height={25}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                        </div>
                        <div className="col-md-3">
                            <Skeleton className="mb-4" variant="text" width={120} height={25}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={220} height={10}/>
                        </div>
                        <div className="col-md-3">
                            <Skeleton className="mb-4" variant="text" width={120} height={25}/>
                            <Skeleton className="mb-1" variant="text" width={280} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={160} height={10}/>
                            <Skeleton className="mb-1" variant="text" width={200} height={10}/>
                        </div>
                        <div className="col-md-3">
                            <Skeleton className="mb-4" variant="text" width={120} height={25}/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={10}/>
                                    <Skeleton className="mb-1" variant="text" width={60} height={10}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={10}/>
                                    <Skeleton className="mb-1" variant="text" width={60} height={10}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={10}/>
                                    <Skeleton className="mb-1" variant="text" width={100} height={10}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={10}/>
                                    <Skeleton className="mb-1" variant="text" width={100} height={10}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card bg-white custom-card gutter-b">
                <div className="card-header py-5 border-bottom-0">
                    <Skeleton variant="text" width={150} height={30}/>
                    <div className="table-responsive w-100 mt-5">
                        <table className="table table-hover ">
                            <thead className="custom_datatable_head">
                                <tr>
                                    <th><Skeleton className="mx-auto" variant="text" width={20} height={30} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                    <th><Skeleton className="mx-auto" variant="text" width={80} height={20} /></th>
                                </tr>
                            </thead>
                           <tbody >
                                {Array.from(Array(2), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Skeleton className="mx-auto" variant="text" width={20} height={30} /></td>
                                            <td className="text-center">
                                                <div className="d-flex justify-content-center align-items-end">
                                                    <Skeleton className="mr-5" variant="rect" width={50} height={50} />
                                                    <div>
                                                        <div className="d-flex align-items-center">
                                                            <Skeleton variant="circle" width={15} height={15} />
                                                            <span className="mr-10 ml-2"><Skeleton variant="text" width={100} /></span>
                                                            <span><Skeleton variant="text" width={100} /></span>
                                                        </div>
                                                        <Skeleton variant="text" width={280} />
                                                    </div>
                                                </div>
                                            </td>
                                            <td> <Skeleton className="mx-auto" variant="text" width={50} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={90} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={80} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={120} height={20} /></td>
                                            <td><Skeleton className="mx-auto" variant="text" width={120} height={20} /></td>
                                        </tr>
                                    );
                                })}
                           </tbody>
                        </table>
                    </div>
                    <div className="row p-5 mt-5">
                        <div className="col-md-9"></div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={20}/>
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={20}/>
                                    <Skeleton className="mb-1" variant="text" width={80} height={20}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                    <Skeleton className="mb-1" variant="text" width={80} height={20}/>
                                </div>
                                <div className="col-12 d-flex justify-content-between">
                                    <Skeleton className="mb-1" variant="text" width={80} height={20}/>
                                    <Skeleton className="mb-1" variant="text" width={120} height={20}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card bg-white custom-card gutter-b">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <Skeleton className="mb-1" variant="text" width={120} height={30}/>
                            <Skeleton className="mb-1" variant="text" width={200} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={180} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={140} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={240} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={140} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={120} height={15}/>
                        </div>
                        <div className="col-md-8">
                            <Skeleton className="mb-1" variant="text" width={120} height={30}/>
                            <Skeleton className="mb-1" variant="text" width={'100%'} height={15}/>
                            <Skeleton className="mb-4" variant="text" width={'80%'} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={'100%'} height={15}/>
                            <Skeleton className="mb-4" variant="text" width={'80%'} height={15}/>
                            <Skeleton className="mb-1" variant="text" width={'100%'} height={15}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
