import React, { useState,useEffect } from "react";
import { Grid, InputLabel} from "@material-ui/core";
import {
    // InputField,
    MuiUploader,
    // PhoneInput,
    // SelectField,
    CustomCheckbox,
    ColorPicker,
    InputField,
} from "../FormFields";
// import { Accordion, Card } from "react-bootstrap";
// import { OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
// import { BASE_URL } from "../../../../../env_config";
import { ImagePreview } from "../../../Popup/ImagePreview";
import { TimeZone } from "../../TimeZone";
import MuiBackgroundImage from "../FormFields/MuiBackgrounImageUploader";
import { OverlayTrigger,Tooltip } from "react-bootstrap";
import { FaTrashAlt} from 'react-icons/fa';

export default function ThemeFrom(props) {
    const {
        formField: {
            white_label_logo,
            wl_primarycolor,
            n_fontcolor,
            lab_primary,
            lab_fontcolor,
            theme,
            displayName,
            background_image,
            nav_bar_color,
            background_color,
            nav_bar_font
        },
        values,
        setCompanyLogo,
        setFieldValue,
        setBackGroundImage
    } = props;

    const theme_data = [
        {
            value: "theme1",
            label: "Theme 1",
        },
        {
            value: "theme2",
            label: "Theme 2",
        },
        {
            value: "theme3",
            label: "Theme 3",
        },
    ];
    useEffect(() => {
        if(props.ccmodesetting){
            props.setFieldValue("white_label_logo",props.ccmodesetting.Logo)
            props.setFieldValue("theme",props.ccmodesetting.Theme)
            props.setFieldValue("wl_primarycolor",props.ccmodesetting.NaturalPrimaryColor)
            props.setFieldValue("n_fontcolor",props.ccmodesetting.NaturalFontColor)
            props.setFieldValue("lab_primary",props.ccmodesetting.LabPrimaryColor)
            props.setFieldValue("lab_fontcolor",props.ccmodesetting.LabFontColor)
            props.setFieldValue("hideCertificateNumber", props.ccmodesetting.hideCertificateNumber);
            props.setFieldValue("hideEstimatedShippingDays", props.ccmodesetting.hideEstimatedShippingDays);            
            props.setFieldValue("displayName", props.ccmodesetting.displayName);
            props.setFieldValue("background_image", props.ccmodesetting.background_image);
            props.setFieldValue("nav_bar_color", props.ccmodesetting.nav_bar_color);
            props.setFieldValue("background_color", props.ccmodesetting.background_color);
            props.setFieldValue("nav_bar_font", props.ccmodesetting.nav_bar_font);
            // props.setFieldValue("muti_currency",props.ccmodesetting.Currency)
            // props.setFieldValue("tax_name",props.ccmodesetting.TaxName)
            // props.setFieldValue("tax_value",props.ccmodesetting.TaxValue)
            // props.setFieldValue("email",props.ccmodesetting.mail)
            // props.setFieldValue("password",props.ccmodesetting.Password)
        }
    },[props.ccmodesetting])
    // console.log(props.ccmodesetting,"props.ccmodesetting")
    const [preview,showPreview] = useState(false)
    const HandlePreview = () => {
        // console.log(values.theme,"values.theme")
        if(values.theme && values.theme.length){
            showPreview(true)
        }
    }
    return (
        <React.Fragment>
            <ImagePreview show={preview} image={values.theme} onClose={() => showPreview(false)} responseOnOk={() => showPreview(false)}/>
            <Grid container justify="start" alignItems="center" spacing={3} className="my-5" >
                <Grid item xs={12} md={10}>
                    <TimeZone ccmodesetting={props.ccmodesetting} setFieldValue={props.setFieldValue} />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={10}>
                    
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_THEME_SET_UPLOAD_LOGO}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiUploader
                                name={white_label_logo.name}
                                label={white_label_logo.label}
                                fullWidth
                                // setThirdFile={setThirdFile}
                                filefield={"white_label_logo"}
                                setFieldValue={setFieldValue}
                                setCompanyRegName={white_label_logo}
                                setCompanyLogo={setCompanyLogo}
                                values={values}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
               
                <Grid item xs={12} md={10}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language?.CCMODE_SETUP_THEME_SET_DISPLAY_NAME}
                            </InputLabel>
                        </Grid>                                      
                        <Grid item xs={6}>
                            <InputField
                                fullWidth
                                name={displayName.name}
                                label={displayName.label}
                                value={values.displayName}
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />   
                        </Grid>          
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                Select Theme *
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomCheckbox
                                name={theme.name}
                                label={theme.label}
                                data={theme_data}
                                classes={theme.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => HandlePreview()} disabled={values.theme && !values.theme.length}>Preview</button>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ColorPicker
                        name={wl_primarycolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_NATURAL}
                        fullWidth
                        onChange={(e) => setFieldValue("wl_primarycolor",e.target.value)}
                        value={values.wl_primarycolor}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ColorPicker
                        name={n_fontcolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_NATURAL_FONT}
                        fullWidth
                        onChange={(e) => setFieldValue("n_fontcolor",e.target.value)}
                        value={values.n_fontcolor}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ColorPicker
                        name={lab_primary.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_LAB}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_primary",e.target.value)}
                        value={values.lab_primary}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ColorPicker
                        name={lab_fontcolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_LAB_FONT}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_fontcolor",e.target.value)}
                        value={values.lab_fontcolor}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ColorPicker
                        name={nav_bar_color.name}
                        label={nav_bar_color.label}
                        fullWidth
                        onChange={(e) => setFieldValue("nav_bar_color",e.target.value)}
                        value={values.nav_bar_color}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ColorPicker
                        name={nav_bar_font.name}
                        label={nav_bar_font.label}
                        fullWidth
                        onChange={(e) => setFieldValue("nav_bar_font",e.target.value)}
                        value={values.nav_bar_font}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={10}>
                    
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_THEME_SET_BACKGROUNDIMG}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiBackgroundImage
                                name={background_image.name}
                                label={background_image.label}
                                fullWidth
                                // setThirdFile={setThirdFile}
                                filefield={"background_image"}
                                setFieldValue={setFieldValue}
                                setCompanyRegName={background_image}
                                setBackGroundImage={setBackGroundImage}
                                values={values}
                            />
                        </Grid>
                        <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                Remove Background Image
                            </Tooltip>
                        }
                    >
                        <button type="button" className="btn btn-danger btn-icon"onClick={() => {
                        setBackGroundImage()
                        setFieldValue("background_image","")
                    }}><FaTrashAlt/></button>
                    </OverlayTrigger>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={10}>
                    <ColorPicker
                        name={background_color.name}
                        label={background_color.label}
                        fullWidth
                        onChange={(e) => setFieldValue("background_color",e.target.value)}
                        value={values.background_color}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                        <InputLabel className="font-size-h6 font-weight-bold">
                            Display Certificate Number
                        </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                        <label className="switch">
                            <input
                            type="checkbox"
                            className="success"
                            checked={!values?.hideCertificateNumber}
                            value={values?.hideCertificateNumber}
                            onChange={() =>
                                setFieldValue(
                                "hideCertificateNumber",
                                !values.hideCertificateNumber
                                )
                            }
                            />
                            <span className="slider" />
                        </label>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>          
                <Grid item xs={12} md={10}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                        <InputLabel className="font-size-h6 font-weight-bold">
                        Display Estimated Shipping Days
                        </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                        <label className="switch">
                            <input
                            type="checkbox"
                            className="success"
                            checked={!values?.hideEstimatedShippingDays}
                            value={values?.hideEstimatedShippingDays}
                            onChange={() =>
                                setFieldValue( "hideEstimatedShippingDays", !values.hideEstimatedShippingDays)
                            }
                            />
                            <span className="slider" />
                        </label>
                        </Grid>
                    </Grid>                    
                </Grid>                
            </Grid>
        </React.Fragment>
    );
}
