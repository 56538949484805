import React, { useState } from "react";
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Grid
} from '@material-ui/core';



const ColorPicker1 = props => {


  return (
    // <Container>
      <span className="customColorPicker">
        <input className="colorPickerColorInput" type="color" {...props} />
        <input className="colorPickerTextInput" type="text" {...props} />
      </span>
    // </Container>
  );
};

export default function ColorPicker(props) {
  const { label, ...rest } = props;
  const [field, meta] = useField(props);
  const [color, setColor] = useState(meta.value);
  

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const handleInput = e => {
    setColor(e.target.value);
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={4}>
          <InputLabel className="font-size-h6 font-weight-bold">
            {label}
          </InputLabel>
      </Grid>
      <Grid item xs={8}>
      <ColorPicker1 onChange={handleInput} value={color} 
        {...rest} />
        {_renderHelperText()}
      </Grid>
  </Grid>
  );
}
