import React,{useEffect} from "react";
import { Field } from "formik";
import {MultiSelectCarat} from '../MultiSelectCarat'
import { GetCookies ,SetCookies,RemoveCookies} from "../../../../_helpers/Cookies";
export const Carat = ({formik,language,oldvalues}) => {
    const caratrangeData = ([
        {
            id: 1,
            value:"0.18 - 0.22",
            name:"CaratRange1"
        },
        {
            id: 2,
            value:"0.23 - 0.29",
            name:"CaratRange2"
        },
        {
            id: 3,
            value:"0.30 - 0.39",
            name:"CaratRange3"
        },
        {
            id: 4,
            value:"0.40 - 0.49",
            name:"CaratRange4"
        },
        {
            id: 5,
            value:"0.50 - 0.69",
            name:"CaratRange5"
        },
        {
            id: 6,
            value:"0.70 - 0.89",
            name:"CaratRange6"
        },
        {
            id: 7,
            value:"0.90 - 0.99",
            name:"CaratRange7"
        },
        {
            id: 8,
            value:"1.00 - 1.49",
            name:"CaratRange8"
        },
        {
            id: 9,
            value:"1.50 - 1.99",
            name:"CaratRange9"
        },
        {
            id: 10,
            value:"2.00 - 2.99",
            name:"CaratRange10"
        },
        {
            id: 11,
            value:"3.00 - 3.99",
            name:"CaratRange11"
        },
        {
            id: 12,
            value:"4.00 - 4.99",
            name:"CaratRange12"
        },
        {
            id: 13,
            value:"5.00 - 9.99",
            name:"CaratRange13"
        },
        {
            id: 14,
            value:"10+",
            name:"CaratRange14"
        }
    ]);
    useEffect(() => {
        if(oldvalues.diamond_type === "N"){
            GetCookies("range").then((res) => {
                if(res && res === "1"){
                    rangeClick()
                }
                else{
                    manualClick()
                }
            })
        }
        else{
            GetCookies("rangebasic").then((res) => {
                if(res && res === "1"){
                    rangeClick()
                }
                else{
                    manualClick()
                }
            })
        }
},[])
    const manualNav = React.useRef(null);
    const rangeNav = React.useRef(null);
    const manualBtn = React.useRef(null);
    const rangeBtn = React.useRef(null);
    const manualClick = (e) => {
        if(oldvalues.diamond_type === "N"){
            RemoveCookies("range")
            SetCookies("manual",1)
        }
        else{
            RemoveCookies("rangebasic")
            SetCookies("manualbasic",1)
        }
        formik.setFieldValue("CaratRange1","")
        formik.setFieldValue("CaratRange2","")
        formik.setFieldValue("CaratRange3","")
        formik.setFieldValue("CaratRange4","")
        formik.setFieldValue("CaratRange5","")
        formik.setFieldValue("CaratRange6","")
        formik.setFieldValue("CaratRange7","")
        formik.setFieldValue("CaratRange8","")
        formik.setFieldValue("CaratRange9","")
        formik.setFieldValue("CaratRange10","")
        formik.setFieldValue("CaratRange11","")
        formik.setFieldValue("CaratRange12","")
        formik.setFieldValue("CaratRange13","")
        formik.setFieldValue("CaratRange14","")
        formik.setFieldValue("caratRange",[])
        manualNav.current.classList.add("show");
        rangeNav.current.classList.remove("show");
        manualBtn.current.classList.remove("text-primary");
        rangeBtn.current.classList.add("text-primary");
      };
      const rangeClick = (e) => {
        if(oldvalues.diamond_type === "N"){
            RemoveCookies("manual")
            SetCookies("range",1)
        }
        else{
            RemoveCookies("manualbasic")
            SetCookies("rangebasic",1)
        }
        formik.setFieldValue("Min_Carat","")
        formik.setFieldValue("Max_Carat","")
        manualNav.current.classList.remove("show");
        rangeNav.current.classList.add("show");
        manualBtn.current.classList.add("text-primary");
        rangeBtn.current.classList.remove("text-primary");
      };
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        const validValue = value.match(/^\d*\.?\d{0,2}$/);
        if (validValue) {
          formik.setFieldValue(name, value);
        }
      };
    return (
        <>
            <div className="row my-5 border-bottom">
                <div className="col-lg-12 mb-2">
                    <p className="font-weight-bold mb-2">{language.CARAT_CARAT}</p>
                    <div className="submenu_link">
                        <span className="pr-1 submenu_link" onClick={manualClick} ref={manualBtn}>{language.CARAT_MANUAL}</span>/ 
                        <span className="submenu_link pl-1 text-primary " onClick={rangeClick} ref={rangeBtn}>{language.CARAT_RANGE}</span>
                    </div>
                </div>
                <div className="col-lg-12 carat show" ref={manualNav}>
                    <div className="row form-group">
                        <div className="col-md-6 mb-md-0 mb-4">
                            <Field
                                className="form-control form-control-sm manualCarat mr-2"
                                type="number"
                                name="Min_Carat"
                                min="0"
                                step="0.01"
                                id="Min_Carat"
                                placeholder="From"
                                autoComplete="off"
                                onChange={handleInputChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field
                                className="form-control form-control-sm manualCarat"
                                type="number"
                                name="Max_Carat"
                                min="0"
                                step="0.01"
                                id="Max_Carat"
                                placeholder="To"
                                autoComplete="off"
                                onChange={handleInputChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mb-3 carat flex-wrap " ref={rangeNav}>
                    <MultiSelectCarat language={language} options={caratrangeData} label={"Select Carat"} name={"caratRange"} formik={formik} oldvalues={oldvalues}/>
                    {/* {caratrangeData.map((item) => (
                        <label key={item.id} className="search_btn_check iframe_setup" >
                            <Field type="checkbox" className="caratRange" name={item.name} id={item.name} value={item.caratrange} />
                            <div className="flourBox selectBox">
                                <span>{item.caratrange}</span>
                            </div>
                        </label>
                    ))} */}
                </div>
            </div>
        </>
    );
};
