import React from "react";
import buy_diamonds from "../../Images/buy_diamonds.png"
import consolidate_orders from "../../Images/consolidate_orders.png"
import getdelivered from "../../Images/getdelivered.png"
import styles from "../../css/style.module.css"

const Howitworks = () =>{
    return(
        <>
            <div className="container-fluid bg-white">
                <div className={`container ${styles.hiw} my-15`}>
                    <div className="row py-8 justify-content-center " data-aos="fade-up" data-aos-once="true">
                        <div className="col-12 text-center ">
                            <h2 className={styles.customH2Title}>How it Works</h2>
                            <p className="mb-4">Buy Diamonds in 3 easy Steps </p>
                        </div>
                    </div>
                    <div className={`row ${styles.hiwborder1} justify-content-center align-items-center pb-6`} data-aos="fade-up" data-aos-once="true">
                        <div className={styles.hiwLeftborder}></div>
                        <span className={styles.circle1}>1</span>
                        <div className="col-md-6 p-6 d-md-flex align-content-center flex-wrap flex-column">
                            <h3 className={styles.customH3Title}>Buy Diamonds</h3>
                            <p>Search &amp; select diamonds from <br />live inventory with the user-friendly <br />interface, detailed parameters <br />&amp; HD Image / 360 Videos.</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={buy_diamonds} alt="Buy Diamonds" title="Buy Diamonds" className="my-md-12"/>
                        </div>
                    </div>
                    <div className={`row ${styles.hiwborder2} justify-content-center align-items-center pb-8`} data-aos="fade-up" data-aos-once="true">
                        <span className={styles.circle2}>2</span>
                        <div className="col-md-6 order-md-2 order-1 p-8 d-md-flex align-content-center flex-wrap flex-column">
                            <h3 className={styles.customH3Title}>Consolidate Orders</h3>
                            <p>All orders quality checked &amp; <br />consolidated under single shipment <br />as per your requirement, irrespective of <br />the location of diamond you choose.</p>
                        </div>
                        <div className="col-md-6 order-md-1 order-2 text-center">
                            <img src={consolidate_orders} alt="Consolidate Orders" title="Consolidate Orders" className="my-md-12"/>
                        </div>
                    </div>
                    <div className={`row ${styles.hiwborder3} justify-content-center align-items-center`} data-aos="fade-up" data-aos-once="true">
                        <div className={styles.hiwLeftborder}></div>
                        <span className={styles.circle3}>3</span>
                        <div className="col-md-6 p-4 d-md-flex align-content-center flex-wrap flex-column">
                            <h3 className={styles.customH3Title}>Get Delivered</h3>
                            <p>We partnered with the fastest <br /> logistics that deliver your order as <br /> fast as the next day, hassle-free.</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={getdelivered} alt="Get Delivered" title="Get Delivered" className="my-md-10"/>
                        </div>
                    </div>
                </div>
            </div>    
        </>
    )
}

export default Howitworks;