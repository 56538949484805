import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField,Field } from 'formik';
// import { FormHelperText } from '@material-ui/core';



function CustomCheckbox(props) {
  // console.log(props.name,props.values,"BALUESS")
  const { label, data, ...rest } = props;
  const [field, meta,helper] = useField(props);
  // console.log(props,"CustomCheckboxprops")
  // const { value: selectedValue } = field;
  // const [touched, error] = at(meta, 'touched', 'error');
  // const isError = touched && error && true;
  const { setValue } = helper;


  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <span className='text-danger small'>{error}</span>;
    }
  }
function _onChange(event) {
  const value = event.target.value;
  // console.log(value,"selected")
  if (value[value.length - 1] === "all") {
    // setSelected(selected.length === data.length ? [] : data);
    return;
  }
  // setSelected(value);
  props.setFieldValue(props.name,[value])
}
useEffect(() => {
  if(props.selectallfields){
    let allfields = data.map(item => {
      return item.value
    })
    props.setFieldValue(props.name,allfields)
    // props.setFieldValue(`${props.name}checked`,true)
  }
  else{
    props.setFieldValue(props.name,[])
    // props.setFieldValue(`${props.name}checked`,false)
  }
  props.setFieldValue(props.name,props.values[props.name])
},[props.selectallfields])

  return (
    <>
        {data.map((item, index) => (
            <>
                <label key={index} className={`search_btn_check iframe_setup ${rest.classes}`} >
                    <Field type="radio" name={props.name} id={`${item.value}`}  value={item.value} />
                    <div className={`finishBox selectBox ${meta.touched && meta.error ? 'border border-danger text-danger' : ''}`}>
                        <span>{item.label}</span>
                    </div>
                </label>
            </>
        ))}
        {/* <p>{_renderHelperText()}</p> */}
    </>
  );
}

CustomCheckbox.defaultProps = {
  data: []
};

CustomCheckbox.propTypes = {
  data: PropTypes.array.isRequired
};

export default CustomCheckbox;
