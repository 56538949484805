/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";

export const CcmodeRejected = (props) => {

    return (
        <>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">
                   <h3 className="text-danger mb-5">{props.language.CCMODE_REJECTED}</h3>
                  
                   <p className="text-muted">{props.language.CCMODE_REJECTED1}</p>
                   <p className="text-muted">{props.language.CCMODE_PENDING_REVIEW3}.</p>
                </div>
                
                <img src={toAbsoluteUrl("/media/svg/icons/Custom/ccmode_red.svg")} alt="ccmode" className="img-fluid" width="50%" style={{ position: "absolute", bottom: "0", right: "0"}}/>
            </div>
        </>
    );
};
