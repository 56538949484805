
if(process.env.REACT_APP_ENV === 'qc'){
    // console.log(process.env,"process.env")
    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT,
        FETCH_DETAIL:process.env.REACT_APP_FETCH_DETAIL_DIAMOND,
        INSERT_WISHLIST:process.env.REACT_APP_STORE_WISHLIST,
        FETCH_WISHLIST:process.env.REACT_APP_FETCH_WISHLIST,
        IVPCURRENCY:process.env.REACT_APP_FETCH_IVPCURRENCY,
        FETCH_DASHBOARD_DATA:process.env.REACT_APP_FETCH_DASHBOARD_DATA,
        FETCH_RECENTLY_DATA:process.env.REACT_APP_FETCH_RECENTLY_DATA,
        DELETE_WISHLIST:process.env.REACT_APP_FETCH_DELETE_WISHLIST,
        BASE_URL:process.env.REACT_APP_BASE_URL,
        REACT_BASE_URL:process.env.REACT_APP_MAINURL,
        HOLDORBUY:process.env.REACT_APP_HOLDORBUY,
        NODE_API_URL:process.env.REACT_APP_NODE_API_URL
    };

}else if (process.env.REACT_APP_ENV === 'local'){

    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN_LOCAL ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND_LOCAL,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT_LOCAL,
        FETCH_DETAIL:process.env.REACT_APP_FETCH_DETAIL_DIAMOND_LOCAL,
        INSERT_WISHLIST:process.env.REACT_APP_STORE_WISHLIST_LOCAL,
        FETCH_WISHLIST:process.env.REACT_APP_FETCH_WISHLIST_LOCAL,
        FETCH_DASHBOARD_DATA:process.env.REACT_APP_FETCH_DASHBOARD_DATA_LOCAL,
        IVPCURRENCY:process.env.REACT_APP_FETCH_IVPCURRENCY_LOCAL,
        FETCH_RECENTLY_DATA:process.env.REACT_APP_FETCH_RECENTLY_DATA_LOCAL,
        DELETE_WISHLIST:process.env.REACT_APP_FETCH_DELETE_WISHLIST_LOCAL,
        BASE_URL:process.env.REACT_APP_BASE_URL,  //beta link
        REACT_BASE_URL:process.env.REACT_APP_MAINURL_URL,
        HOLDORBUY:process.env.REACT_APP_HOLDORBUY_LOCAL,
        NODE_API_URL:process.env.REACT_APP_NODE_API_URL_LOCAL
    };
} else {
    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN_PROD ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND_PROD,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT_PROD,
        FETCH_DETAIL:process.env.REACT_APP_FETCH_DETAIL_DIAMOND_PROD,
        INSERT_WISHLIST:process.env.REACT_APP_STORE_WISHLIST_PROD,
        FETCH_WISHLIST:process.env.REACT_APP_FETCH_WISHLIST_PROD,
        FETCH_DASHBOARD_DATA:process.env.REACT_APP_FETCH_DASHBOARD_DATA_PROD,
        IVPCURRENCY:process.env.REACT_APP_FETCH_IVPCURRENCY_PROD,
        FETCH_RECENTLY_DATA:process.env.REACT_APP_FETCH_RECENTLY_DATA_PROD,
        DELETE_WISHLIST:process.env.REACT_APP_FETCH_DELETE_WISHLIST_PROD,
        BASE_URL:process.env.REACT_APP_BASE_URL_PROD,
        REACT_BASE_URL:process.env.REACT_APP_MAINURL_PROD,
        HOLDORBUY:process.env.REACT_APP_BASE_URL_PROD,
        NODE_API_URL:process.env.REACT_APP_NODE_API_URL_PROD
    };
}

//exporting apilink

module.exports = apilink;