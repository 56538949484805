import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

export const ConsumerPopup = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
//    console.log(props)
    return (
        <>
            <FaEye className="text-primary text-hover-success font-size-h6 cursor-pointer ml-2" onClick={() => handleShow()}/>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Consumer Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h6 className="mb-0">Full Name: </h6> <span>{props.FullName}</span>
                        </div>
                        <div className="col-12 mb-3">
                            <h6 className="mb-0">Email: </h6> <span>{props.Email}</span>
                        </div>
                        <div className="col-12 mb-3">
                            <h6 className="mb-0">Contact Number: </h6> <span>{props.ContactNumber}</span>
                        </div>
                        {props.comment ?
                            <div className="col-12 mb-3">
                                <h6 className="mb-0">Comment: </h6> <span>{props.comment}</span>
                            </div>
                        : null}
                    </div>
                </Modal.Body>
               <Modal.Footer>
                    <button className="btn btn-sm btn-primary" onClick={handleClose}>Close</button>
               </Modal.Footer>
            </Modal>
        </>
    );
};
