/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import KycUpdate from "./KycUpdate/KycUpdate"
import ProfileEdit from "./Profile/ProfileEdit"
import ProfileView from "./Profile/ProfileView"
export const Profile = ({isprofilecomplete,userinfo,language}) => {
    const [edit,setEdit] = React.useState(false)
    // console.log(edit,"setEdit")
    // console.log(userinfo.kyc_status,"userinfo")
    return (
        <div className="container">
           <div className="card custom-card border-0 gutter-b">
                <div className="card-body p-0">
                    {edit? <ProfileEdit userinfo={userinfo} language={language}/>:
                        userinfo.kyc_status === "NO_KYC"?<KycUpdate userinfo={userinfo} setEdit={setEdit} language={language}/> : <ProfileView userinfo={userinfo} setEdit={setEdit} language={language}/>
                    }
                    {/* <KycUpdate userinfo={userinfo} setEdit={setEdit} language={language}/>  */}
                    {/* <KycUpdate userinfo={userinfo} setEdit={setEdit}/> */}
                </div >
            </div >
        </div>
    );
};
