import React, { useEffect, useRef, } from "react";
import cusotmerserviceImg from "../../Images/customersupport.png"
import cusotmerserviceVideo from "../../videos/cusotmerservice.mp4"
import styles from "../../css/style.module.css"

const CustomerSupport = () =>{
    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };
    useEffect(() => {
        attemptPlay();
    }, []);
    return(
        <>
            <div className="container-fluid">
                <div className={styles.bgF6f8fc}>
                    <div className="container" id="story">
                        <div className="row align-items-center pt-3">
                            <div id="" className={`col-lg-6 ${styles.p03} ${styles.block1}`} data-aos="fade-up" data-aos-once="true">
                                <h3 className={`p-3 ${styles.titleH3}`}>24x7 Customer Support</h3>
                                <p className={`p-3 font-size-h4 ${styles.l30}`}>Our support executives are available 24x7 via phone, email and live chats to answer any queries and offer prompt assistance to Jewelers in their time zone. <b>Diamonds On Call</b> is committed to provide the highest level of service to all clients, no matter where you are based or what your business needs are. Each of our support executive has in-depth diamond knowledge and will provide advice on the best options available. Our team pride themselves on establishing excellent and long-standing relationships with their clients. </p>
                            </div>
                            <div className={`col-lg-6 text-center ${styles.videowhy}`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                                <video
                                    muted
                                    loop
                                    poster={cusotmerserviceImg}
                                    src={cusotmerserviceVideo}
                                    className=""
                                    id="home_video"
                                    ref={videoEl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerSupport;