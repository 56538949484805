import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid ,Button , makeStyles, Snackbar, SnackbarContent,} from '@material-ui/core';
import clsx from "clsx";
import green from "material-ui/colors/green";
export default function ProfileSuccess() {

  const [alertOpen, setAlertOpen] = React.useState(false);
  function handleClose(event, reason) {
      setAlertOpen(false);
  }

  const useStyles = makeStyles((theme) => ({
      success: {
          backgroundColor: green[600],
      },
      
  }));

  function MySnackbarContentWrapper(props) {
      const classes = useStyles();
      const { className, message, onClose, variant, ...other } = props;

      return (
          <SnackbarContent
              className={clsx(classes[variant], className)}
              aria-describedby="client-snackbar"
              message={
                  <span id="client-snackbar" className={classes.message}>
                      {message}
                  </span>
              }
              {...other}
          />
      );
  }
  const copyurl = () => {
    navigator.clipboard.writeText("https://ciccio2.diazoom.com/")
    // alert("Copied to clipboard")
    setAlertOpen(true);
}
  return (
    <React.Fragment>
        <Grid container justify='center' align="center">
          <Grid item xs={12} sm={7}>
            <Typography className="my-10 text-center" variant="h4" align="center">
                And it's done! You've successfully set up your CC Mode.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className="screenAlert-icon screenAlert-success animate">
                <span className="screenAlert-line screenAlert-tip animateSuccessTip"></span>
                <span className="screenAlert-line screenAlert-long animateSuccessLong"></span>
                <div className="screenAlert-placeholder"></div>
                <div className="screenAlert-fix"></div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
       
            <Typography className="font-weight-bold" align="center">
                Now you'll be able to enjoy a seamless interface with your brand identity to showcase Lab Grown and Natural Diamonds.
            </Typography>
            <Typography className="font-weight-bold" align="center"> 
                Simply set your multi-level mark up on next page and switch your CCMode on to be at the top of your game! 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} className="mt-10">
            <Typography className="font-weight-bold" align="center">
                Below is the link to access your personalised CCMode 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} className=' d-flex justify-content-center align-items-center'>
            <div className="form-group mb-0 w-50 mt-5">
                <div className="input-group">
                    <input type="text" value="https://ciccio2.diazoom.com/" disabled id="copyurl" className="form-control disabled m-0"/> 
                    <div className="input-group-append">
                        <span className="input-group-text">
                            <i className="fa fa-copy cursor-pointer" onClick={() => copyurl()}></i>
                        </span>
                    </div>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="my-10"
              component={Link} to="/ccmode"
              onClick={() => window.location.reload(true)}
            >
               Ok
            </Button>
          </Grid>
        </Grid>
        <Snackbar
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
              }}
              open={alertOpen}
              autoHideDuration={2000}
              onClose={handleClose}
          >
              <MySnackbarContentWrapper
                  onClose={handleClose}
                  variant="success"
                  message={`Copied to Clipboard`}
              />
          </Snackbar>
    </React.Fragment>
  );
}
