import React from "react";
import { Accordion, Card } from "react-bootstrap";
import {Eyeclean} from "./Tinge/Eyeclean"
import { Shade } from "./Tinge/Shade";
import { Media } from "./Preference/Media";
import { Price } from "./Preference/Price";
export const Advanced = ({language,formik}) => {
       return (
        <>
            <Accordion className="search_accordion" >
                <Card>
                    <Accordion.Toggle className="sticky-top" style={{top:"64px"}}  as={Card.Header} eventKey="1" key="1">
                        {language.MUI_ADVANCEDETAILS}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                           <Eyeclean language={language}/>
                           <Shade language={language}/>
                           <Price formik={formik} language={language}/>
                           <Media language={language}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
