import React from "react";
import about1 from "../../Images/about1.svg"
import bgImg  from "../../Images/bg-img.png"
import styles from "../../css/style.module.css"

const Title = () =>{
    return (
        <>
            <div className={`${styles.jumbotron} ${styles.abouttitle} text-center`}>
				<h1 className={styles.customH1Title}>About Us</h1>
			</div>
			<div className="container-fluid bg-white">
				<div className="container py-8 py-xl-15 px-xl-20">
					<div className="row justify-content-center p-6">
						<div className="col-md-6 col-sm-12 ">
							<img className="pb-6" src={about1} alt="transparency and trust" title="Transparency and trust" width="80" data-aos="fade-up" data-aos-once="true" />
							<h2 className={styles.titleH2} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">We spark diamonds with trust &amp; technology</h2>
						</div>
						<div className="col-md-6 col-sm-12 pt-10" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
							<p className="pt-10 l-30">World becomes a better place with transparency and trust, whether for work or for their dreams. To cope up with this pace we at Diamonds On Call are working to bring all segments of polished diamonds online at a single click, this leads Jewelers around the world to buy diamonds online with confidence and comfort on our platform.</p>
						</div>
					</div>
				</div>
				<div className="container about-image text-center pb-15 pt-4" data-aos="fade-up" data-aos-once="true">
					<img src={bgImg} alt="background img" title="Background Image" width="1140" />
				</div>
			</div>
        </>
    )
}

export default Title;