import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Topbar} from "./Topbar";
import {Searchbar} from "../extras/dropdowns/search/Searchbar";
import {HeaderMenuWrapper} from "./header-menu/HeaderMenuWrapper";
import {AnimateLoading} from "../../../_partials/controls";
import { Searchbarchurned } from "../extras/dropdowns/search/SearchbarChurned";
export function Header(props) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-center justify-content-between`}>
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}
          {/*begin::Searchbar*/}
          {props.userinfo.v_customer === "1" || props.userinfo.v_customer === "2" || props.userinfo.v_customer === "3" || props.userinfo.v_customer === "8" || props.userinfo.v_customer === "9" || props.userinfo.v_customer === "10"?<div className="d-none d-md-block"><Searchbar language={props.language} userinfo={props.userinfo}/></div>:""}
          {props.userinfo.v_customer === "12" && props.userinfo.kyc_status === "KYC_APPR"?<div className="d-none d-md-block"><Searchbarchurned language={props.language} userinfo={props.userinfo}/></div>:""}
          {/*end::Searchbar*/}
          {/*begin::Topbar*/}
          <Topbar language={props.language} userinfo={props.userinfo}/>
          {/*end::Topbar*/}
        </div>
        
        {/*end::Container*/}
      </div>
      {/* <div className="container-fluid bg-danger d-flex align-items-center justify-content-center">
        <div className="row">
          <div className="col-12 text-white font-weight-bold font-size-h2 text-center py-4">
          This site will be under maintenance between 13:00 to 19:00 IST, Please be informed
          </div>
        </div>
      </div> */}
      {/*end::Header*/}
    </>
  );
}
