import React , {useState, useEffect,useRef} from "react";
import { NavLink } from "react-router-dom";
import { FaSkype,FaPhoneAlt } from "react-icons/fa";
import logo from "../Images/Logo/logo_font_blue.png"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styles from "../css/style.module.css"

gsap.registerPlugin(ScrollTrigger);

const Header = () =>{

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const headerElem = useRef(null);
    
    useEffect(() => {
    const showAnim = gsap.from(headerElem.current, { 
        yPercent: -100,
        paused: true,
        duration: 0.2
        }).progress(1);

        ScrollTrigger.create({
            start: "240px",
            end: "100%",
            onUpdate: (self) => {
                self.direction === -1 ? showAnim.play() : showAnim.reverse()
            }
        });
    }, []);

    return(
        <>
            <div className={`styles.container-fluid ${styles.topbar}`}>
                <div className="container pr-0">
                    <div className="row justify-content-end mr-0">
                        <div className="col-12 text-nowrap text-right">
                            <FaPhoneAlt className="mr-2" />
                            <a className={` ${styles.topNumber}`} href="tel:+14158004257"><span className={` ${styles.topnumber}`}>+1 415 800 4257</span></a>
                            <a className={styles.topbarSkype} href="skype:live:marketing_28011?chat"> <FaSkype /> </a>
                        </div>
                    </div>
                </div>
            </div>
            <div  ref={headerElem} className="container-fluid bg-white p-0 sticky-top">
                <nav className={`navbar navbar-light navbar-expand-xl navigation-clean-button shadow-sm ${styles.navbar} ${styles.navbarLight} ${styles.navbnavbarExpandXl}  ${styles.navigationCleanButton}`}>
                    <div className="container">
                        <NavLink className={styles.navbarBrand} to="/">
                            <img src={logo} alt="diamondsoncall-blue-logo" title="Diamonds On Call"/>
                        </NavLink>
                        <button onClick={handleNavCollapse} data-toggle="collapse" aria-expanded={!isNavCollapsed ? true : false} className="navbar-toggler" data-target="#docheader">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="docheader">
                            <ul className={`nav navbar-nav ml-auto ${styles.nav} ${styles.navbarNav}`}>
                                <li className="nav-item"><NavLink exact activeClassName='active' className={`nav-link ${styles.navLink}`} to="/">Home</NavLink></li>
                                <li className="nav-item"><NavLink exact activeClassName='active' className={`nav-link ${styles.navLink}`} to="/about">About Us</NavLink></li>
                                <li className="nav-item"><NavLink exact activeClassName='active' className={`nav-link ${styles.navLink}`} to="/whyus">Why Us</NavLink></li>
                                <li className="nav-item"><a className={`nav-link ${styles.navLink}`} href="https://supplier.diamondsoncall.com/">For Supplier</a></li>
                                <li className="nav-item"><NavLink exact activeClassName='active' className={`nav-link ${styles.navLink}`} to="/contact">Contact</NavLink></li>
                                <li className="nav-item"><NavLink exact activeClassName='active' className={`nav-link ${styles.navLink}`}  to="/auth/login">Login</NavLink></li>
                            </ul>
                            <span className={`navbarText actions ${styles.navbarText}`}><NavLink className={`btn ${styles.actionButton}`} role="button" to="/registration">Join Now. It's free </NavLink></span>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
} 

export default Header;