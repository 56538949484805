import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function SearchGridSkeleton() {
    return (
        <>
             <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <Skeleton variant="text" width={120} />
                    </div>
                    <div className="card-toolbar flex-md-nowrap align-items-center">
                        <Skeleton className="mr-2" variant="rect" width={80} height={30} />
                        <Skeleton className="mr-3" variant="rect" width={40} height={30} />
                        <Skeleton className="mr-3" variant="rect" width={40} height={30} />
                        <Skeleton className="mr-2" variant="rect" width={80} height={30} />
                    </div>
                </div>
                <div className="card-body bg-white rounded">
                    <div className="d-flex flex-wrap justify-content-between gridData">
                        {Array.from(Array(12), (e, i) => {
                            return (
                                <div className="mb-5 px-2" style={{width:"280px"}} key={i}>
                                    <div className="row m-0 card rounded-0">
                                        <div className="col-12 p-0 d-flex align-items-center justify-content-center" >
                                            <Skeleton variant="rect" height={220} width={280} />
                                        </div>
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={60} />
                                            <Skeleton variant="text" width={60} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mt-3 mb-1 d-flex justify-content-between align-items-center">
                                            <div className="d-flex">
                                                <span className="mr-2"><Skeleton variant="text" width={40} /></span>
                                                <span className="mr-2"><Skeleton variant="text" width={40} /></span>
                                                <span><Skeleton variant="text" width={40} /></span>
                                            </div>
                                            <div className="d-flex">
                                                <span className="mr-2"><Skeleton variant="text" width={20} /></span>
                                                <span className="mr-2"><Skeleton variant="text" width={20} /></span>
                                                <span className=""><Skeleton variant="text" width={20} /></span>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex px-2 align-items-center">
                                            <span className="mr-3"><Skeleton variant="circle" width={35} height={35} /></span>
                                            <Skeleton variant="text" width={200} height={30}/>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-column">
                                                <span className="mb-2"><Skeleton variant="rect" width={20}  height={10}/></span>
                                                <Skeleton variant="rect" width={60}  height={10}/>
                                            </div>
                                            <div className="d-flex flex-column justify-content-end align-items-end">
                                                <span className="mb-2"><Skeleton variant="rect" width={20}  height={10}/></span>
                                                <Skeleton variant="rect" width={100}  height={15}/>
                                            </div>
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex justify-content-between align-items-center">
                                            <Skeleton variant="text" width={80} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={40} />
                                            <Skeleton variant="text" width={20} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 mb-2">
                                            <Skeleton variant="text" width={180} />
                                            <Skeleton variant="text" width={140} />
                                        </div>
                                        <hr className="w-100 my-2" />
                                        <div className="col-12 d-flex align-items-center justify-content-between mb-2">
                                            <Skeleton variant="rect" width={80} height={30} />
                                           <div className="d-flex">
                                                <span className="mr-2"><Skeleton variant="rect" width={50} height={30}/></span>
                                                <Skeleton variant="rect" width={50} height={30}/>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                
             </div>
        </>
    );
}
