import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField,Switch,FormControlLabel} from '@material-ui/core';
export const TaxPopUp = (props) => {
    const hideModel = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onClose();
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    const HandleTax = () => {
        // console.log(HandleTax,"HandleTax")
        setTaxInclusive(!taxinclusive)
        if(taxinclusive){
            props.setTaxName("")
            props.setTaxValue(0)
        }
    }
    const [taxinclusive,setTaxInclusive] = useState(false)
    useEffect(() => {
        console.log("TaxCheck",props.taxname,props.taxvalue)
        if(props.taxname && props.taxvalue){
            console.log("YES")
            setTaxInclusive(true)
        }
    },[props])
    console.log(taxinclusive,"taxinclusive")
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="text-center my-6">
                <h2 className="mb-10 mt-5 text-center">Enter Tax Details</h2>
                <div class="mb-10">
                <FormControlLabel
                        className='m-0'
                        control={
                        <Switch
                            checked={taxinclusive}
                            onClick={() => HandleTax()}
                            value={taxinclusive}
                            color="secondary"
                        />
                        }
                        label={taxinclusive?"Tax Inclusive":"Tax Exclusive"}
                    />
                 </div>   
                    {taxinclusive?<>
                <div class="mb-10">
                    <TextField
                        variant="outlined"
                        type="text"
                        name={"Tax Name"}
                        label={"Enter Tax Name"}
                        style={{ minWidth: 200 }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => props.setTaxName(e.target.value)}
                        value={props.taxname}
                        // disabled={true}
                    />
                   </div> 
                   <div class="mb-10">
                    <TextField
                        variant="outlined"
                        type="text"
                        name={"Tax Value"}
                        label={"Enter Tax Value(%)"}
                        style={{ minWidth: 200 }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            let numbersonly = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                            if(e.target.value && !numbersonly.test(e.target.value)){
                                e.preventDefault()
                                return;
                            }
                            props.setTaxValue(e.target.value)
                        }}
                        value={props.taxvalue}
                        // disabled={true}
                    />
                   </div> 
                   </>:""}
                   {/* <div class="mb-10"> */}
                   {props.onHide ? <Button
                        variant="secondary"
                        onClick={() => hideModel(props)}
                        className="mr-4 btn-square px-10"
                    >
                        cancel
                    </Button> : ""}
                    <Button className="btn-square px-10"
                        variant="primary"
                        onClick={() => {props.responseOnOk ? responseClose(props): closeModel(props) }}
                    >
                       {props.responseOnOk ? "Save" : "close"}
                    </Button>
                    {/* </div> */}
                </Modal.Body>
            </Modal>
        </div>
    );
};
