import React, { useState,useEffect } from "react";
import { Grid, InputLabel } from "@material-ui/core";
import {
    CustomCheckbox,
    ColorPicker,
} from "../FormFields";
// import { ImagePreview } from "../../../Popup/ImagePreview";
import { ImagePreviewIframe } from "../../../Popup/ImagePreviewIframe";
// import { TimeZone } from "../../TimeZone";
import {SelectField} from "../FormFields";
export default function ThemeFrom(props) {
    const {
        formField: {
            wl_primarycolor,
            n_fontcolor,
            lab_primary,
            lab_fontcolor,
            theme,
            multilanguage
        },
        values,
        setFieldValue,
    } = props;

    const theme_data = [
        {
            value: "theme1",
            label: "Theme 1",
        },
        {
            value: "theme2",
            label: "Theme 2",
        },
        {
            value: "theme3",
            label: "Theme 3",
        },
    ];
    useEffect(() => {
        if(props.iframesetting){
            props.setFieldValue("multilanguage",props.iframesetting.ifm_multilingual)
            props.setFieldValue("theme",props.iframesetting.ifm_theme)
            props.setFieldValue("wl_primarycolor",props.iframesetting.ifm_NaturalPrimaryColor)
            props.setFieldValue("n_fontcolor",props.iframesetting.ifm_NaturalFontColor)
            props.setFieldValue("lab_primary",props.iframesetting.ifm_LabPrimaryColor)
            props.setFieldValue("lab_fontcolor",props.iframesetting.ifm_LabFontColor)
        }
    },[props.iframesetting])
    const [preview,showPreview] = useState(false)
    const HandlePreview = () => {
        // console.log(values.theme,"values.theme")
        if(values.theme && values.theme.length){
            showPreview(true)
        }
    }
    const muti_lingual_data = [
        {
            value: 'en',
            label: `English`
        },
        {
            value: 'hi',
            label: `हिंदी`
        },
        {
            value: 'it',
            label: `italiano`
        },
        {
            value: 'ge',
            label: `Deutsch`
        },
        {
            value: 'ch',
            label: `粵語`
        },
        {
            value: 'es',
            label: `español`
        }
    ];
    return (
        <React.Fragment>
            <ImagePreviewIframe show={preview} image={values.theme} onClose={() => showPreview(false)} responseOnOk={() => showPreview(false)}/>
            <Grid container justify="start" alignItems="center" spacing={3} className="my-10" >
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                Select Theme *
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <CustomCheckbox
                                name={theme.name}
                                label={theme.label}
                                data={theme_data}
                                classes={theme.classes}
                                setFieldValue={setFieldValue}
                                values={values}
                                selectallfields={props.selectallfields}
                                // onChange={(e) => setFieldValue("diamondmarkup",e.target.value)}
                            />  
                        </Grid>
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => HandlePreview()} disabled={values.theme && !values.theme.length}>Preview</button>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <ColorPicker
                        name={wl_primarycolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_NATURAL}
                        fullWidth
                        onChange={(e) => setFieldValue("wl_primarycolor",e.target.value)}
                        value={values.wl_primarycolor}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <ColorPicker
                        name={n_fontcolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_NATURAL_FONT}
                        fullWidth
                        onChange={(e) => setFieldValue("n_fontcolor",e.target.value)}
                        value={values.n_fontcolor}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <ColorPicker
                        name={lab_primary.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_LAB}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_primary",e.target.value)}
                        value={values.lab_primary}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <ColorPicker
                        name={lab_fontcolor.name}
                        label={props.language.CCMODE_SETUP_THEME_SET_LAB_FONT}
                        fullWidth
                        onChange={(e) => setFieldValue("lab_fontcolor",e.target.value)}
                        value={values.lab_fontcolor}
                    />
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_MULTILINGUAL}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                name={multilanguage.name}
                                label={props.language.CCMODE_SETUP_MULTILINGUAL}
                                fullWidth
                                data={muti_lingual_data}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                {/* <Grid item xs={12} md={8}>
                    <TimeZone iframesetting={props.iframesetting} setFieldValue={props.setFieldValue} />
                    <hr className="w-100 m-0 mt-8" />
                </Grid> */}
            </Grid>
        </React.Fragment>
    );
}
