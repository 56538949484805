/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate,useHistory,useLocation } from "react-router-dom";
export const PreviewIframe = (props) => {
    // const navigate = useNavigate();
    let history = useHistory();
    const location = useLocation()
    return (
        <div className="container-fluid" >
            <div className="card custom-card">
                <div className="card-body" style={{height:"72vh"}}>
                    <iframe src={process.env.REACT_APP_ENV === "production"?`https://plugin.diazoom.com/docplugin/${location.state}`:`https://betaplugin.diamondsupload.com/docplugin/${location.state}`} title='Embeded Code' height='100%' width='100%' style={{border: 'none'}}></iframe>
                </div>
                <div className="card-footer text-right">
                    <button className="btn btn-primary" onClick={() => history.goBack()}>Close Preview</button>
                </div>
            </div>
        </div>
    );
};
