import React,{useState,useEffect} from "react";
import { Grid,  InputLabel } from "@material-ui/core";
import { InputField,   SelectField } from "../FormFields";
import { GetCookies } from "../../../../_helpers/Cookies";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function MarkupCurrency(props) {
    const [taxinclusive,SetTaxInclusiveValidation] = useState(false)
    useEffect(() => {
        if(props.iframesetting){
           
            props.setFieldValue("muti_currency",props.iframesetting.api_currency)
            props.setFieldValue("tax_name",props.iframesetting.api_taxname)
            props.setFieldValue("tax_value",props.iframesetting.api_taxvalue)
            props.setFieldValue("noprice",props.iframesetting.ifm_no_price)
            if(props.iframesetting.api_taxvalue){
                props.setFieldValue("taxinclusive",true)
            }
           
        }
        if(props.iframesetting && props.iframesetting.api_taxvalue){
            SetTaxInclusiveValidation(true)
          }
    },[props.iframesetting])

    const {
        formField: {
            muti_currency,
            tax_value,
            tax_name,
            noprice
        },
        values
    } = props;
    const [inr,setInr] = useState(0)
    const [cad,setCad] = useState(0)
    const [aud,setAud] = useState(0)
    const [hkd,setHkd] = useState(0)
    const [cny,setCny] = useState(0)
    const [eur,setEur] = useState(0)
    const [gbp,setGbp] = useState(0)
    const [nzd,setNzd] = useState(0)
    const [jpy,setJpy] = useState(0)
    const [chf,setChf] = useState(0)
    const [sgd,setSgd] = useState(0)
    const [thb,setThb] = useState(0)
    const [myr,setMyr] = useState(0)
    const [bnd,setBnd] = useState(0)
    const [dkk,setDkk] = useState(0)
    const [sek,setSek] = useState(0)
    const [usd,setUsd] = useState(0)

    useEffect(() => {
        GetCookies("conversionrates").then(response => {
            // console.log(response,"Conversionresponse")
            if(response && response[0]){
                const currencies = JSON.parse(response)
                // console.log(currencies,"currencies")
                setInr(Math.round(currencies[0].cur_inr*100)/100)
                setCad(Math.round(currencies[0].cur_cad*100)/100)
                setAud(Math.round(currencies[0].cur_aud*100)/100)
                setHkd(Math.round(currencies[0].cur_hkd*100)/100)
                setCny(Math.round(currencies[0].cur_cny*100)/100)
                setEur(Math.round(currencies[0].cur_eur*100)/100)
                setGbp(Math.round(currencies[0].cur_gbp*100)/100)
                setNzd(Math.round(currencies[0].cur_nzd*100)/100)
                setJpy(Math.round(currencies[0].cur_jpy*100)/100)
                setChf(Math.round(currencies[0].cur_chf*100)/100)
                setSgd(Math.round(currencies[0].cur_sgd*100)/100)
                setThb(Math.round(currencies[0].cur_thb*100)/100)
                setMyr(Math.round(currencies[0].cur_myr*100)/100)
                setBnd(Math.round(currencies[0].cur_bnd*100)/100)
                setDkk(Math.round(currencies[0].cur_dkk*100)/100)
                setSek(Math.round(currencies[0].cur_sek*100)/100)
                setUsd(Math.round(currencies[0].cur_usd*100)/100)
            }
        })
    },[])
    const SetTaxInclusive = (values) => {
        // console.log(values,"valuesvalues")
        props.setFieldValue("taxinclusive",!values.taxinclusive)
        SetTaxInclusiveValidation(!values.taxinclusive)
        // if(values.taxinclusive){
        //     props.setFieldValue("tax_name","")
        //     props.setFieldValue("tax_value",0)
        // }
      }
    // console.log(inr,usd,"inr,usd")
    const muti_currency_data = [
          {
            value: 'INR',
            label: `INR ₹ - ${inr.toFixed(2)}`
          },
          {
            value: 'USD',
            label: `USD $ - ${usd.toFixed(2)}`
          },
          {
            value: 'CAD',
            label: `CAD $ - ${cad.toFixed(2)}`
          },
          {
            value: 'AUD',
            label: `AUD $ - ${aud.toFixed(2)}`
          },
          {
            value: 'HKD',
            label: `HKD $ - ${hkd.toFixed(2)}`
          },
          {
            value: 'CNY',
            label: `CNY ¥ - ${cny.toFixed(2)}`
          },
          {
            value: 'EUR',
            label: `EUR € - ${eur.toFixed(2)}`
          },
          {
            value: 'GBP',
            label: `GBP £ - ${gbp.toFixed(2)}`
          },
          {
            value: 'NZD',
            label: `NZD $ - ${nzd.toFixed(2)}`
          },
          {
            value: 'JPY',
            label: `JPY ¥ - ${jpy.toFixed(2)}`
          },
          {
            value: 'CHF',
            label: `CHF ₣ - ${chf.toFixed(2)}`
          },
          {
            value: 'SGD',
            label: `SGD $ - ${sgd.toFixed(2)}`
          },
          {
            value: 'THB',
            label: `THB ฿ - ${thb.toFixed(2)}`
          },
          {
            value: 'MYR',
            label: `MYR RM - ${myr.toFixed(2)}`
          },
          {
            value: 'BND',
            label: `BND $ - ${bnd.toFixed(2)}`
          },
          {
            value: 'DKK',
            label: `DKK kr. - ${dkk.toFixed(2)}`
          },
          {
            value: 'SEK',
            label: `SEK kr - ${sek.toFixed(2)}`
          }
    ];
    // console.log(props.values.taxinclusive,"TaxInclusive")
    return (
        <React.Fragment>
            <Grid container justify="start" alignItems="center" spacing={3} className="my-10">
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                No Price
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <OverlayTrigger
                                placement="top"
                                className="text-capitalize"
                                overlay={
                                    <Tooltip id="tooltip">Hide Price on your website,<br /> but you can see price with your markup when customer sends inquiry </Tooltip>
                                }
                            >
                                <label className="switch ">
                                    <input type="checkbox" className="success"  name={noprice.name} id={`${noprice.name}`} checked={props.values["noprice"]?props.values["noprice"].toString() === "1":false} value={props.values["noprice"]?props.values["noprice"]:""} onChange={() => props.values["noprice"] && props.values["noprice"].toString() === "1"?props.setFieldValue("noprice","0"):props.setFieldValue("noprice","1")}/>
                                    <span className="slider"></span>
                                </label>
                            </OverlayTrigger>
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.CCMODE_SETUP_SELECT_CURRENCY}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectField
                                name={muti_currency.name}
                                label={props.language.CCMODE_SETUP_SELECT_CURRENCY}
                                fullWidth
                                data={muti_currency_data}
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">{values.taxinclusive?"Inclusive Tax":"Exclusive Tax"}</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                        <label className="switch ">
                            <input type="checkbox" className="success" checked={values.taxinclusive} value={values.taxinclusive} onClick={() => SetTaxInclusive(values)}/>
                            <span className="slider"></span>
                        </label>
                        </Grid>
                    </Grid>
                </Grid>
                {values.taxinclusive? <>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-h6 font-weight-bold">
                                    Tax Name
                                </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    name={tax_name.name}
                                    label={tax_name.label}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={4}>
                                <InputLabel className="font-size-h6 font-weight-bold">
                                    Tax Value
                                </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <InputField
                                    name={tax_value.name}
                                    label={tax_value.label}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>:""}
            </Grid>
        </React.Fragment>
    );
}
