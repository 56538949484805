import React from "react";
import Banner from "./Homepage/Banner";
import Whychooseus from "./Homepage/Whychooseus";
import Showcasevideo from "./Homepage/Showcasevideo";
import Product from "./Homepage/Product";
import Howitworks from "./Homepage/Howitworks";
import Benefits from "./Homepage/Benefits"
import Requestdemo from "./Homepage/Requestdemo";
import Blog from "./Homepage/Blog";
import Appdownload from "./Homepage/Appdownload";


const Home = () =>{
    return (
        <>
            <title>Buy Diamonds Online On Single Click - Diamonds On Call</title>
            <Banner />
            <Whychooseus />
            <Showcasevideo />
            <Product />
            <Howitworks />
            <Benefits />
            <Requestdemo />
            <Blog />
            <Appdownload />
        </>
    )
}
export default Home;