import React from "react";
import fourC from "../../Images/4c.gif"
import bgm from "../../Images/bgm.gif"
import eyeclean from "../../Images/eyeclen.gif"
import styles from "../../css/style.module.css"

const Qualitycheck = () =>{
    return (
        <>
            <div id="shipping" className={`${styles.teamClean} ${styles.bgF6f8fc} ${styles.py100}`}>
                <div id="categories-selections" className="container-fluid">
                    <div className={`${styles.intro} pt-6`}>
                        <h2 className={`text-center ${styles.customH2Title} pb-6`} data-aos="fade-up" data-aos-once="true">Independent Quality Check</h2>
                        <p className={`text-center font-size-h4 ${styles.l30}`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">We know the pain, when your ordered diamond quality is not as per standards. So at <b>Diamonds On Call</b> when you order a diamond, every diamond is quality checked by experienced grader to make sure the diamonds you ordered are free of any conflict and has excellent brilliance.</p>
                    </div>
                    <hr className={styles.newboarder} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-delay="500" data-aos-once="true" />
                    <div className="row flex-lg-row flex-column text-center d-flex mb-6" id={styles.checkingsteps}>
                        <div className={`order-className ${styles.flexcolumn} d-lg-block d-none ${styles.py80} w-auto`} data-aos="fade-up" data-aos-once="true">
                            <span className={styles.btnShipping}>Order</span>
                        </div>
                        <div className={styles.flexcolumn} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                            <img className={`${styles.imgbg} m-0 m-auto`} src={fourC} alt="4 c's of diamond" title="4 c's of diamond" />
                            <h4 className={styles.name}>4C’s of Diamond<br /></h4>
                            <p className={`${styles.description} ${styles.l30}`} >Inclusions and parameters of diamond are matched with the specs on the grading certificate. </p>
                        </div>
                        <div className={`${styles.flexcolumn} ${styles.px65}`} data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                            <img className={`${styles.imgbg} m-0 m-auto`} src={[bgm]} alt="bgm in diamond" title="bgm in diamond" />
                            <h4 className={styles.name}>Tinge &amp; Milky<br /></h4>
                            <p className={`${styles.description} ${styles.l30}`}>Color and haziness (BGM) in diamond is checked to make sure diamond has excellent luster. </p>
                        </div>
                        <div className={styles.flexcolumn} data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                            <img className={`${styles.imgbg} m-0 m-auto`} src={eyeclean} alt="eye clean clarity" title="eye clean clarity" />
                            <h4 className={styles.name}>100% Eye Clean<br /></h4>
                            <p className={`${styles.description} ${styles.l30}`} >Diamond is examined under naked eye to make sure 100% inclusions are not visible. </p>
                        </div>
                        <div className={`shipping-class ${styles.flexcolumn} d-lg-block d-none ${styles.py80} w-auto`} data-aos="fade-up" data-aos-delay="400" data-aos-once="true">
                            <span className={styles.btnShipping}>Shipping</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Qualitycheck; 