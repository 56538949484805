import React, { useEffect } from "react";
import {
    IncompleteProfileTile,
    ItemsInCartTile,
    TotalOrderPlaceTiles,
    TotalInvoiceTile,
    DiamondRequestTile,
    DashboardSearchTab,
    DashboardTracklist,
    RecentDiamondTile,
    SavedSearchtile,
    SalesExecutiveTile,
} from "../widgets";
import { Alert } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
// import { GetCookies } from "../../_helpers/Cookies";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { FETCH_DASHBOARD_DATA,NODE_API_URL } from "../../../env_config";
import DashboardSkeleton from "./DashboardSkeleton"

export const Dashboard = ({
    conversionrate,
    symbol,
    language,
    isprofilecomplete,
    userinfo,
}) => {
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    // console.log("userinfo",userinfo)
    const [dashData, setDashData] = React.useState([]);
    const [salesData, setSalesData] = React.useState([]);
    const [convinienceFee, setConvinienceFee] = React.useState([]);

    useEffect(() => {
        let isMounted = true;
        const finalData = {
            user_id: `${user.CustomerId}`,
        };
        const userCountry = {
            country: `${userinfo.country}`,
        };
        axios({
            method: "POST",
            url: FETCH_DASHBOARD_DATA,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then((res) => {
            if (res && res.data) {
                isMounted && setDashData(res.data);
                isMounted && setSalesData(res.data.salesExecutiveDetail);
                // console.log("data", res);
            }
        });
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/getConvinienceFee`,
            headers: { "Content-Type": "application/json" },
            data: userCountry,
        }).then((res) => {
            if (res && res.data) {
                isMounted && setConvinienceFee(res.data.data);
                // console.log("data", res);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [user.CustomerId,userinfo.country]);
    return (
        <> {userinfo.id ?
            <>
                
                <div className="row m-0 mb-3 rounded p-4 shadow-sm bg-white">
                    <div className="col-12 justify-content-md-between d-flex align-items-center">
                        <h1 className="text-capitalize text-primary mb-0">
                            Welcome {userinfo ? userinfo.cname : ""}{" "}
                            <SVG
                                src={toAbsoluteUrl("/media/General/ic_fest.svg")}
                            />
                        </h1>
                        <span className="font-size-lg font-weight-bold">
                            Convenience Fee
                            <OverlayTrigger
                                placement="bottom"
                                className="text-capitalize"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {convinienceFee.ups_charges?<div>UPS ${convinienceFee.ups_charges}</div>:""}
                                        {convinienceFee.fedex_charges?<div>FedEx ${convinienceFee.fedex_charges}</div>:""}
                                        {userinfo.country === "Hong Kong"?<div>Hk Delivery $0</div>:""}
                                    </Tooltip>
                                }
                            >
                                <FaInfoCircle className="text-hover-warning ml-2 cursor-pointer" />
                            </OverlayTrigger>
                        </span>
                    </div>
                    {user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                        <div className="col-12 mt-2">
                            <Alert variant="danger">
                                Your account is under Lite Mode, to get further
                                access please contact your sales representative.
                                Thank you
                            </Alert>
                        </div>
                    ) : (
                        ""
                    )}
                    {userinfo.kyc_status === "KYC_PEND" ? (
                        <div className="col-12 mt-2">
                            <Alert variant="warning">
                                Your updated KYC is under review, please wait for
                                approval.
                            </Alert>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="row">
                    {userinfo.kyc_status === "NO_KYC" ? (
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-12">
                                    <IncompleteProfileTile />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
    
                    <div
                        className={`${
                            userinfo.kyc_status === "NO_KYC"
                                ? "col-md-8 profileIncompleteDashboard"
                                : "col-md-12 profileIncompleteDashboard"
                        }`}
                    >
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <ItemsInCartTile
                                    holdCount={
                                        dashData && dashData.holdCount
                                    }
                                    language={language}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <TotalOrderPlaceTiles
                                    totalOrderCount={
                                        dashData && dashData.totalOrderCount
                                    }
                                    language={language}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <TotalInvoiceTile
                                    totalInvoiceCount={
                                        dashData && dashData.totalInvoiceCount
                                    }
                                    language={language}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <DiamondRequestTile language={language} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <DashboardSearchTab language={language} />
                    </div>
                    <div className="col-md-6">
                        <DashboardTracklist
                            language={language}
                            symbol={symbol}
                            conversionrate={conversionrate}
                            itemInWishList={dashData && dashData.itemInWishList}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <RecentDiamondTile
                            language={language}
                            symbol={symbol}
                            conversionrate={conversionrate}
                            userinfo={userinfo}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-12">
                                <SavedSearchtile language={language} />
                            </div>
                            <div className="col-12">
                                <SalesExecutiveTile
                                    language={language}
                                    salesData={salesData && salesData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </> : <DashboardSkeleton /> }
        </> 
    );
};
