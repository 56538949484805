import React from "react";
import google_play from "../../Images/google_play.png" 
import app_store from "../../Images/app_store.png"
import qrcode from "../../Images/diamondsoncall-qrcode.png"
import phoneimg from "../../Images/mobile-phone.png"
import styles from "../../css/style.module.css"

const Appdownload = () =>{
    return (
        <>
            <div className="container-fluid bg-white">
                <div className="container mt-12">
                    <div className="row justify-content-center m-0">
                        <div className={`col-lg-6 col-md-8 col-sm-12 align-items-center ${styles.appdownload} mb-5 pb-3`} data-aos="fade-up" data-aos-once="true">
                            <h3>Buy Diamonds on the go!</h3>
                            <p className="font-size-h4 my-2">Diamonds on Call is available for all devices. Share diamond details, Track your orders and Get notified.</p>
                            <p className="mt-3 font-size-h3">Scan qr code to download our app</p>
                            <div className="row align-items-center justify-content-center justify-content-md-start m-0 mt-3">
                                <div className="col-md-5 col-6 text-center text-md-left ">
                                    <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=diamondsoncall.com" title="DiamondsOnCall App">
                                        <img className="m-1" src={google_play} alt="google play store img" title="Google Play Store Image" height="46" width="153"/>
                                    </a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/diamonds-on-call/id1410197909" title="DiamondsOnCall App">
                                        <img className="m-1" src={app_store} alt="app store img" title="App Store Image" height="46" width="153"/>
                                    </a>
                                </div>
                                <div className="col-6 text-center text-md-left">
                                    <img className="m-1" src={qrcode} alt="app download qr code" title="App Download qr code" height="108" width="108"/>
                                </div>
                            </div>
                            <p className="mt-3"><i>*Available on iPhone, iPad and all Android devices</i></p>
                        </div>
                        <div className={`col-lg-6 col-md-12 col-sm-12 ${styles.phoneimg} text-right`} data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                            <img src={phoneimg} alt="phone img" title="Phone Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
 export default Appdownload;