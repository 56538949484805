import React from "react";
import liveinventory from "../../Images/liveinventory.svg"
import supportlady from "../../Images/supportlady.svg"
import qualityCheck from "../../Images/QualityCheck.svg"
import logisticManagement from "../../Images/LogisticManagement.svg"
import supplyManagement from "../../Images/SupplyManagement.svg"
import easyPayments from "../../Images/EasyPayments.svg"
import styles from "../../css/style.module.css"

const ForWhome = () =>{
    return (
        <>
			<div className="container-fluid bg-white">
				<div className="container">
					<div className="row justify-content-center">
						<hr className="w-75 text-center" />
					</div>
				</div>
				<div className="container py-4">
					<div className=" row justify-content-center pb-5">
						<div className="col-lg-7 col-md-12 col-sm-12">
							<h2 className={`text-center ${styles.customH2Title} pb-3`} data-aos="fade-up" data-aos-once="true">For Whom ?</h2>
							<p className={`text-center font-size-h4 ${styles.l30}`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">We serve Diamond Jewelers globally. Any jeweler who wants to buy diamonds online with hassle free diamond buying experience, instant checkout and delivery. Diamonds on call is for you.</p>
						</div>
					</div>
					<div className={`row ${styles.forwhom} pb-4 p-3`}>
						<div className={`col-lg-4 col-md-6 col-sm-12 ${styles.forwhomitem}`} data-aos="fade-up" data-aos-once="true">
							<img src={liveinventory} alt="diamonds live inventory" title="Diamonds live inventory" />
							<h4 className={styles.customH4Title}>Live Inventory</h4>
							<p>Live diamond inventory with image and video, from over 100+ manufacturers and sight-holders around the globe.</p>
						</div>
						<div className={`col-lg-4 col-md-6 col-sm-12 ${styles.forwhomitem}`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
							<img src={supportlady} alt="24x7 customer support" title="24x7 customer support" />
							<h4 className={styles.customH4Title}>24x7 support</h4>
							<p>Dedicated Executive works in your time zone to walk you through any difficulties you face.</p>
						</div>
						<div className={`col-lg-4 col-md-6 col-sm-12 ${styles.forwhomitem}`} data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
							<img src={qualityCheck} alt="independent diamond quality check certificate" title="independent diamond quality check certificate" />
							<h4 className={styles.customH4Title}>Quality Check</h4>
							<p>Every Diamonds Purchased goes under a strict quality check. So you don’t have to worry when you receive the diamond.</p>
						</div>
						<div className={`col-lg-4 col-md-6 col-sm-12 ${styles.forwhomitem}`} data-aos="fade-up" data-aos-once="true">
							<img src={logisticManagement} alt="Logistic Management" title="Logistic Management" />
							<h4 className={styles.customH4Title}>Logistic Management</h4>
							<p>All diamonds you choose can be shipped under one shipment with next day delivery and live tracking. So you don’t have to manage multiple shipments.</p>
						</div>
						<div className={`col-lg-4 col-md-6 col-sm-12 ${styles.forwhomitem}`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
							<img src={supplyManagement} alt="supply management" title="Supply Management" />
							<h4 className={styles.customH4Title}>Supply Management</h4>
							<p>Our platform is equipped with to manage all your current and future orders automatically. So you don’t miss on any orders.</p>
						</div>
						<div className={`col-lg-4 col-md-6 col-sm-12 ${styles.forwhomitem}`} data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
							<img src={easyPayments} alt="easy payments" title="Easy Payments" />
							<h4 className={styles.customH4Title}>Easy Payments</h4>
							<p>We have easy payments options like bank transfers, mobile payments, credit cards etc. So we have got you covered.</p>
						</div>
					</div>
				</div>
			</div>
		</>
    )
}

export default ForWhome;