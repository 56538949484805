import React, { useEffect, useRef, } from "react"
import deliveryImg from "../../Images/delivery.jpg"
import detiveryVideo from "../../videos/delivery.mp4"
import styles from "../../css/style.module.css"

const QuickShipp = () =>{
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);
    return (
        <>
            <div className="container-fluid bg-white">
                <div className="container" id="story">
                    <div className="row align-items-center pt-3">
                        <div className={`col-md-6 order-md-2 order-sm-1 order-lg-2 ${styles.block1}`} data-aos="fade-up" data-aos-once="true">
                            <h3 className={`p-3 ${styles.titleH3}`} >Quickest Shipment</h3>
                            <p className={`p-3 font-size-h4 ${styles.l30}`}>Once you order a diamond on <b>diamondsoncall.com</b>, irrespective of where the diamond is located or from which diamond dealer it is, all diamonds are consolidated under one shipment. So you don’t have hassle to track and manage multiple small shipments. Over that we provide overnight shipping for delivery, so that your customer has to never wait.</p>
                        </div>
                        <div className={`col-md-6 order-md-1 order-sm-2 order-lg-1 ${styles.videowhy} text-center pb-8`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                            <video
                                muted
                                loop
                                preload="none"
                                poster={deliveryImg}
                                src={detiveryVideo}
                                className="hw"
                                id="home_video"
                                ref={videoEl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuickShipp;