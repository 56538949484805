import React , {useState} from "react";
import { Link } from "react-router-dom";
import logo_white from "../Images/Logo/logo_white.png"
import app_store_white from "../Images/app-store-white.svg"
import google_play_white from "../Images/google-play-white.svg"
import styles from "../css/style.module.css"
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare, FaInstagramSquare  } from "react-icons/fa";
 

const Footer = () =>{
    const [isFooterCollapsed, setIsFooterCollapsed] = useState(true);
    const handleFooterCollapse = () => setIsFooterCollapsed(!isFooterCollapsed);
    const [isFooterCollapsed1, setIsFooterCollapsed1] = useState(true);
    const handleFooterCollapse1 = () => setIsFooterCollapsed1(!isFooterCollapsed1);
    const [isFooterCollapsed2, setIsFooterCollapsed2] = useState(true);
    const handleFooterCollapse2 = () => setIsFooterCollapsed2(!isFooterCollapsed2);
    return (
        <>
            <footer className={` ${styles.footerLarge} ${styles.myFooter}`}>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12 col-sm-6 col-md-3 mt-3 text-start">
                            <Link to="/" title="diamondsoncalllogo">
                                <div className={`text-left mb-5 ${styles.footerlogo}`}>
                                    <img src={logo_white} alt="diamondsoncall-white-logo" title="Diamonds On Call" />
                                </div>
                            </Link>
                            <p className="mt-3 text-left">905 Multifield plaza, <br />3 -7A Prat Ave,Tsim Sha Tsui,<br /> Kowloon, HONG KONG</p>
                            <div className="mt-2 text-left">
                                <Link to="tel:85281999581">+852 8199 9581</Link><br />
                                <Link title="info@diamondsoncall.com" to="mailto:&#105;&#110;&#102;&#111;&#064;&#100;&#105;&#097;&#109;&#111;&#110;&#100;&#115;&#111;&#110;&#099;&#097;&#108;&#108;&#046;&#099;&#111;&#109;">info@diamondsoncall.com</Link>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-2 mt-4 text-left offset-1">
                            <h5>OUR&nbsp;PRODUCTS</h5>
                            <ul>
                                <li><Link to="/search-diamond" title="White Diamonds" className={styles.cF0b33f90}>Natural Diamonds</Link>
                                    <ul className={styles.nestedA}>
                                        <li><Link to="/search-diamond" title="White Diamonds">White Diamonds</Link></li>
                                        <li><Link to="/search-diamond?fancy" title="Fancy colored Diamonds">Fancy colored Diamonds</Link></li>
                                        <li><Link to="/search-diamond" title="Parcel diamonds">Parcel Diamonds</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/lab-diamond" title="Lab Grown Diamonds" className={styles.cF0b33f90}>Lab Grown Diamonds</Link>
                                    <ul className={styles.nestedA}>
                                        <li><Link to="/lab-diamond" title="White Diamonds">White Diamonds</Link></li>
                                        <li><Link to="/lab-diamond?fancy" title="Fancy colored Diamonds">Fancy colored Diamonds</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-6 col-md-2 mt-4 text-left">
                            <h5>COMPANY</h5>
                            <ul>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/whyus">Why Us<br /></Link></li>
                                <li><a href="https://supplier.diamondsoncall.com/">For Supplier</a></li>
                                <li><Link to="/login">Login</Link></li>
                                <li><Link to="/registration">Sign Up</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-2 mt-4 text-left">
                            <h5>INFORMATION</h5>
                            <ul>
                                <li><Link to="/demo-request" title="Request a Demo">Request a Demo</Link></li>
                                <li><Link to="/blog" title="Blog">Blog</Link></li>
                                <li><Link to="/faqs" title="Faqs">Faqs</Link></li>
                                <li><Link to="/privacy" title="Privacy Policy">Privacy Policy</Link></li>
                                <li><Link to="/terms" title="Terms &amp; Conditions">Terms &amp; Conditions</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-2 text-left mt-4">
                            <h5>DOWNLOAD &nbsp;APP</h5>
                            <div className="mt-4">
                                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/diamonds-on-call/id1410197909" title="DiamondsOnCall App"><img src={app_store_white} alt="diamond on call mobile application download" width="130" height="39" title="diamond on call Apple application download" /></a>
                            </div>
                            <div className="mt-2">
                                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=diamondsoncall.com" title="DiamondsOnCall App"><img src={google_play_white} alt="diamond on call mobile application download" title="diamond on call Android application download" width="130" height="39"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className={` ${styles.footerSmall} ${styles.myFooter}`} >
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Link to="/" title="diamondsoncalllogo">
                                <div className={styles.footerlogo}>
                                    <img src={logo_white} alt="diamondsoncall-white-logo" title="Diamonds On Call" />
                                </div>
                            </Link>
                            <p className="mt-3 mb-0">905 Multifield plaza, <br />3 -7A Prat Ave,Tsim Sha Tsui,<br /> Kowloon, HONG KONG</p>
                            <Link to="tel:85281999581">+852 8199 9581</Link><br/>
                            <Link title="info@diamondsoncall.com" to="mailto:&#105;&#110;&#102;&#111;&#064;&#100;&#105;&#097;&#109;&#111;&#110;&#100;&#115;&#111;&#110;&#099;&#097;&#108;&#108;&#046;&#099;&#111;&#109;">info@diamondsoncall.com</Link>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <div id="accordion">
                                <div id="our-product-heading">
                                    <span className={` collapsed ${styles.footerSmHead}`} role="button" data-toggle="collapse" onClick={handleFooterCollapse} aria-expanded={!isFooterCollapsed ? true : false} aria-controls="our-Product">OUR&nbsp;PRODUCTS</span>
                                </div>
                                <div id="our-Product" className={`${isFooterCollapsed ? 'collapse' : ''}`} data-parent="#accordion" aria-labelledby="our-product-heading">
                                    <ul>
                                        <li><Link to="<?= base_url() ?>search-diamond" title="White Diamonds" className={styles.cF0b33f90}>Natural Diamonds</Link>
                                            <ul className={styles.nestedA}>
                                                <li><Link to="/search-diamond" title="White Diamonds">White Diamonds</Link></li>
                                                <li><Link to="/search-diamond?fancy" title="Fancy colored Diamonds">Fancy colored Diamonds</Link></li>
                                                <li><Link to="/search-diamond" title="Parcel diamonds">Parcel Diamonds</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/lab-diamond" title="Lab Grown Diamonds" className={styles.cF0b33f90}>Lab Grown Diamonds</Link>
                                            <ul className={styles.nestedA}>
                                                <li><Link to="/lab-diamond" title="White Diamonds">White Diamonds</Link></li>
                                                <li><Link to="/lab-diamond?fancy" title="Fancy colored Diamonds">Fancy colored Diamonds</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div id="company-heading">
                                    <span className={`collapsed ${styles.footerSmHead}`} role="button" data-toggle="collapse" onClick={handleFooterCollapse1} aria-expanded={!isFooterCollapsed1 ? true : false} aria-controls="company">COMPANY</span>
                                </div>
                                <div id="company" className={`${isFooterCollapsed1 ? 'collapse' : ''}`} data-parent="#accordion" aria-labelledby="company-heading">
                                    <ul>
                                        <li><Link to="/about">About Us</Link></li>
                                        <li><Link to="/whyus">Why Us<br /></Link></li>
                                        <li><a rel="noopener noreferrer" target="_blank" href="https://supplier.diamondsoncall.com/">For Supplier</a></li>
                                        <li><Link to="/login">Login</Link></li>
                                        <li><Link to="/registration">Sign Up</Link></li>
                                    </ul>
                                </div>
                                <div id="information-heading">
                                    <span className={` collapsed ${styles.footerSmHead}`} role="button" data-toggle="collapse" onClick={handleFooterCollapse2} aria-expanded={!isFooterCollapsed2 ? true : false} aria-controls="information">INFORMATION</span>
                                </div>
                                <div id="information" className={`${isFooterCollapsed2 ? 'collapse' : ''}`} data-parent="#accordion" aria-labelledby="information-heading">
                                    <ul>
                                        <li><Link to="/demo-request" title="Request a Demo">Request a Demo</Link></li>
                                        <li><Link to="/blog" title="Blog">Blog</Link></li>
                                        <li><Link to="/faqs" title="Faqs">Faqs</Link></li>
                                        <li><Link to="/privacy" title="Privacy Policy">Privacy Policy</Link></li>
                                        <li><Link to="/term" title="Terms & Conditions">Terms &amp; Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center mt-2">
                            <h5 className="mb-4">DOWNLOAD &nbsp;APP</h5>
                            <div className="justify-content-center">
                                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/diamonds-on-call/id1410197909" title="DiamondsOnCall App">
                                    <img src={app_store_white} alt="diamond on call mobile application download" width="130" height="39" title="diamond on call Apple application download" />
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=diamondsoncall.com" title="DiamondsOnCall App">
                                    <img src={google_play_white} alt="diamond on call mobile application download" title="diamond on call Android application download" width="130" height="39"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className={`container-fluid ${styles.bg002173}`}>
                <div className="container">
                    <div className={`row justify-content-between text-nowrap align-items-center ${styles.footerbottom}`}>
                        <div className="col-lg-6 col-md-6 col-sm-12 align-items-center p-1 text-md-left text-center">
                            <p>&#169; 2021 Diamondsoncall. All Rights Reserved.</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 text-md-right text-center p-1">
                            <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/diamondsoncall" title="Facebook"><FaFacebookSquare className={styles.fa} /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/diamondsoncall" title="Linkedin">< FaLinkedin className={styles.fa} /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/diamondsoncall" title="twitter"><FaTwitterSquare className={styles.fa} /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/diamondsoncall" title="instagram"><FaInstagramSquare className={styles.fa} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;