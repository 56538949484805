import React,{ useEffect, useRef, useState } from "react"
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import posterimg from "../../Images/showcase_poster.jpg";
import styles from "../../css/style.module.css"
import "./css/Homepage.css"

gsap.registerPlugin(ScrollTrigger);

const Showcasevideo = () =>{
    
    const [isMutedclass, setMutedclass] = useState("false");
    const [isMuted, setMuted] = useState("true");

    const videoEl = useRef();
    const showcaseDivElm = useRef();

    const ToggleClass = () => {
        setMutedclass(!isMutedclass);
        setMuted(!isMuted);
    };
    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 1450px)": function() {
                ScrollTrigger.create({
					trigger: showcaseDivElm.current,
					animation: gsap.fromTo(videoEl.current, {scale: 1 }, {scale: 1.12}),
					// pin: true,
					start: 'top top',
					end: 'center center',
					scrub: 1,
				})
            },
			"(min-width: 1025px)": function() {
                ScrollTrigger.create({
					trigger: showcaseDivElm.current,
					animation: gsap.fromTo(videoEl.current, {scale: 1 }, {scale: 1.16}),
					// pin: true,
					start: 'top top',
					end: 'center center',
					scrub: 1,
				})
            },
        });
        let allVideoDivs = gsap.utils.toArray(showcaseDivElm.current);
        allVideoDivs.forEach((videoDiv, i) => {
        let videoElem = videoDiv.querySelector('video')
            ScrollTrigger.create({
                trigger: videoElem,
                start: 'top 60%',
                end: 'top -40%',
                // markers: true,
                onEnter: () => videoElem.play(),
                onEnterBack: () => videoElem.play(),
                onLeave: () => videoElem.pause(),
                onLeaveBack: () => videoElem.pause(),
            });
        });
    }, []);
    
    return (
        <>
            <div className={`container-fluid ${styles.videoshowcasetitle}`}>
                <div className="row pt-10 justify-content-center " data-aos="fade-up" data-aos-once="true">
                    <div className="col-12 text-center">
                        <h2 className={`text-white m-0 ${styles.customH2Title}`}>See Diamonds On Call In action</h2>
                    </div>
                </div>
            </div>
            <div className={`showcase ${styles.showcase}`} id="videoshowcase">
                <div ref={showcaseDivElm} className={`${styles.showcaseInner} showcase-inner text-center`} >
                    <video
                        onClick={ToggleClass}
                        poster={posterimg}
                        className= {`showcase_video  ${isMutedclass ? "unmute" : "mute" }`}
                        id="showcase_video"
                        preload="auto"
                        loop
                        muted={isMuted ? true : false }
                        src="https://www.diamondsoncall.com/diamondsoncall_video.mp4"
                        ref={videoEl}
                    />
                </div>
		</div>
        </>
    )
}

export default Showcasevideo;