/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    firstName,
    lastName,
    email,
    contanct_no,
    passport_id,
    passport_id_file,
    work_profile,
    company_name,
    address1,
    address2,
    city,
    state,
    zipcode,
    country,
    telephone,
    fax,
    invoice_email,
    website,
    comp_reg_no,
    nature_of_business,
    business_est_date,
    name_of_partner,
    contact_of_partner,
    shipping_address1,
    shipping_address2,
    shipping_city,
    shipping_state,
    shipping_zipcode,
    shipping_country,
    shipping_telephone,
    shipping_email,
    tax_id,
    port_of_discharge,
    comp_reg_no_file
  }
} = kycFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/
const alphabatic = /^[aA-zZ\s]+$/
const pandcard =/^[A-Z0-9]*$/

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`).matches(alphabatic, 'Only Alphabets should allow'),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`).matches(alphabatic, 'Only Alphabets should allow'),
    [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
    [contanct_no.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').min(10, "Please enter at least 10 characters").required(`${contanct_no.requiredErrorMsg}`),
    [comp_reg_no_file.name]: Yup.string().required(`${comp_reg_no_file.requiredErrorMsg}`),
    [work_profile.name]: Yup.string().required(`${work_profile.requiredErrorMsg}`).nullable(),
    
    [company_name.name]: Yup.string().required(`${company_name.requiredErrorMsg}`),
    [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
    // [address2.name]: Yup.string().required(`${address2.requiredErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`).nullable(),
    [zipcode.name]: Yup.string()
    .required(`${zipcode.requiredErrorMsg}`),
    [country.name]: Yup.string().required(`${country.requiredErrorMsg}`),
    [passport_id_file.name]: Yup.string().required(`${passport_id_file.requiredErrorMsg}`),
    [passport_id.name]: Yup.string().required(`${passport_id.requiredErrorMsg}`),
    [telephone.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').min(10, "Please enter at least 10 characters").required(`${telephone.requiredErrorMsg}`),
    // [fax.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').required(`${fax.requiredErrorMsg}`),
    [invoice_email.name]: Yup.string() .email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${invoice_email.requiredErrorMsg}`),
    // [website.name]: Yup.string() .matches( /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url!' ) .required(`${website.requiredErrorMsg}`),
    [comp_reg_no.name]: Yup.string().required(`${comp_reg_no.requiredErrorMsg}`),
    [nature_of_business.name]: Yup.string().required(`${nature_of_business.requiredErrorMsg}`),
    [business_est_date.name]: Yup.string().required(`${business_est_date.requiredErrorMsg}`),
    [name_of_partner.name]: Yup.string().required(`${name_of_partner.requiredErrorMsg}`),
    [contact_of_partner.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').min(10, "Please enter at least 10 characters").required(`${contact_of_partner.requiredErrorMsg}`),
  
    [shipping_address1.name]: Yup.string().required(`${shipping_address1.requiredErrorMsg}`).nullable(),
    // [shipping_address2.name]: Yup.string().required(`${shipping_address2.requiredErrorMsg}`),
    // [shipping_city.name]: Yup.string().required(`${shipping_city.requiredErrorMsg}`),
    // [shipping_state.name]: Yup.string().required(`${shipping_state.requiredErrorMsg}`).nullable(),
    // [shipping_zipcode.name]: Yup.string()
    //   .required(`${shipping_zipcode.requiredErrorMsg}`),
    // [shipping_country.name]: Yup.string().required(`${shipping_country.requiredErrorMsg}`),
    [shipping_telephone.name]: Yup.string().matches(phoneRegExp, 'Contact number is not valid').min(10, "Please enter at least 10 characters").required(`${shipping_telephone.requiredErrorMsg}`),
    [shipping_email.name]: Yup.string() .email("Wrong email format") .min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols") .required(`${shipping_email.requiredErrorMsg}`),
    // [tax_id.name]: Yup.string().required(`${tax_id.requiredErrorMsg}`).min(15, "Enter Valid GST No").max(15, "Enter Valid GST No"),
    [port_of_discharge.name]: Yup.string().required(`${port_of_discharge.requiredErrorMsg}`),
  }),
];
