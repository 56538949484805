import React, { useState,useEffect,useRef} from "react";
import { useParams } from "react-router-dom";
// import { GetDiamondDB,StoreDiamondDB } from "../../_helpers/IndexedDB";
import {Modal,Button,OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import { FaCalendarAlt,FaInfoCircle,FaShareAlt } from "react-icons/fa";
import {toAbsoluteUrl} from "../../_helpers";
import SVG from "react-inlinesvg";
import { FETCH_DETAIL ,NODE_API_URL} from "../../../env_config";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Paper,
    Tabs,
    Tab,
    Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import axios from "axios";
import { BsLightningFill } from "react-icons/bs";
import { FaExternalLinkAlt} from "react-icons/fa";
import VideoIframe from "./VideoIframe";
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError } from "../Popup";
import DetailSkeleton from "./DetailSkeleton"
import { BuyDiamond } from "../Popup/BuyDiamond";
import { ImageSharePopup } from "../widgets/tiles/ImageSharePopup";
import { ImageExpandPopupCommon } from "../widgets/tiles/ImageExpandPopupCommon";
import { VideoExpandPopupCommon } from "../widgets/tiles/VideoExpandPopupCommon";
import { TracklistStone } from "../Popup/TracklistPopup";
// import { SearchGridDesignNew } from "../widgets/tiles/SearchGridDesignNew";
import { SimilarDiamonds } from "../widgets/tiles/SimilarDiamonds";
import SimilarSearchGridSkeleton from "../widgets/tiles/SimilarSearchGridSkeleton";
import { CopyUrlAlert } from "../Popup/CopyUrlAlert";
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function DetailPage({conversionrate,symbol,language,symbolname,userinfo,logStoredRef}){
    const [value, setValue] = React.useState(0); // for tab
    const {ProductId} = useParams()
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);
    const [confid,setConfId] = useState("")
    const [supstatus,setSupStatus] = useState(null)
    const [swalerror,setSwalError] = useState(false)
    const [errormsg,setErrorMsg] = useState("")
    const [data,setData] = useState(null)
    const [loader,setLoader] = useState(false)
    const [similarLoader,setSimilarLoader] = useState(true)
    const [SimiliarDiamonds,setSimilarDiamonds] = useState([])
    // console.log(data.diamond_type,"diamond_type")
    // const swalWarnOpenfn = (props) => {
    //     setSwalWarnOpen(true)
    // }

    const holdWarn = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("This stone already in your hold order")
    }

    // const closeSwal = (props) => {
    //     // props.onHide()
    //     setSwalWarnOpen(false)
    // }

    function handleTabChange(event, newValue) {
        setValue(newValue);
      }

    
    useEffect(() => {
        setLoader(true)
        const Diamond_Type = ProductId.split('-')[0]
        const Certi_No = ProductId.split('-')[1]
        const userData = JSON.parse(localStorage.getItem("userData"))

        const finalData = {
            "id":Certi_No,
            "Certi_No":Certi_No,
            "Diamond_Type":Diamond_Type,
            "Stock_Id":Certi_No,
            "user_id":userData.CustomerId
        }
        axios({
            method: "POST",
            url: FETCH_DETAIL,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(async(res) => {
            setLoader(false)

            if(res && res.data){
                setData(res.data)
                if(res.data.is_hold === 1){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/API/GetHoldOrder`,
                        headers: { "Content-Type": "application/json" },
                        data: {
                            user_id:userData.CustomerId,
                            certificate:res.data.Certi_NO
                        },
                    }).then(conres => {
                        if(conres && conres.data && conres.data.success){
                            setConfId(conres.data.conform_good_id)
                            setSupStatus(conres.data.sup_status)
                        }
                    })
                }
                if(!logStoredRef.current && userinfo.id){
                    logStoredRef.current = true
                    const getipadress = await axios.get("https://api.ipify.org")
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/API/expandAndDetailsLogAPI`,
                        headers: { "Content-Type": "application/json" },
                        data: {
                            userid:userinfo.id,
                            Certi_no:res.data.Certi_NO,
                            C_Name:res.data.C_Name,
                            type:"Details",
                            ip:getipadress.data
                        },
                    }).then(response => {
                    })
                }
                // console.log(data, res.data)
                let diamond_type = ""
                let color = ""
                if(res.data.diamond_type === "W" || res.data.diamond_type === "N"){
                    if(res.data.C_Color === "fancy"){
                        diamond_type = "NF"
                        color = res.data.f_color
                    }
                    else{
                        diamond_type = "N"
                        color = res.data.C_Color
                    }
                }
                else{
                    if(res.data.C_Color === "fancy"){
                        diamond_type = "LF"
                        color = res.data.f_color
                    }
                    else{
                        diamond_type = "L"
                        color = res.data.C_Color
                    }
                }
                let data = JSON.stringify({
                    "user_id": userinfo?.id,
                    "StockID": res.data.id,
                    "shape": res.data.C_Shape,
                    "clarity": res.data.C_Clarity,
                    "color": color,
                    "diamond_type": diamond_type
                  });
                  let config = {
                    method: 'post',
                    url: `${NODE_API_URL}/API/fetchSimilarDiamonds`,
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then((response) => {
                    setSimilarLoader(false)
                    if(response.data.data?.length){
                        setSimilarDiamonds(response.data.data)
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setSimilarLoader(false)
                  });
                  
            }
        }).catch(err => {
            setLoader(false)

        })
    },[])
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"))
        const Diamond_Type = ProductId.split('-')[0]
        const Certi_No = ProductId.split('-')[1]
        const finalData = {
            "user_id":userData.CustomerId,
            "productid":Certi_No,
            "WLDiamondType":Diamond_Type
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/StoreRecentlyViewedDiamond`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            // if(res && res.data){
            //     setData(res.data)
            //     console.log(data, res.data)
            // }
        })
    },[ProductId])
    // console.log(wishlistdata,"wishlistdata")
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    

    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };
    // -------------------------------------------------

    
    function handleClose(event, reason) {
        setSuccessOpen(false)
        setOpen(false);
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }
  
    // const openVideoModal = (event) => {
    //     if (event.ctrlKey || event.metaKey) {
    //         window.open(data.diamond_type === "N" || data.diamond_type === "W" ? `https://pro360video.com/video.php?refno=${data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${data.Certi_NO}`,"_blank")
    //       } else {
    //         setShowVideo(true)
    //       }
    // };
    
    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }

    function certificateLink(){
        if (data.Lab === "IGI"){
            const  certificate_link = `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
            return certificate_link
        } else if (data.Lab === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
            return certificate_link
        } else if (data.Lab === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
            return certificate_link
        } else if (data.Lab === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
            return certificate_link
        } else if (data.Certificate_link){
            const certificate_link = data.Certificate_link
            return certificate_link
        }
    }

    const [diamondselected,setDiamondSelected] = useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [buyholdpopup,setBuyHoldPopUp] = useState(false)
    const [showimage,setShowImage] = useState(false)
    console.log(showimage,":showimage")
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [show,setShow] = useState(false)
    const [diamonddetails,setDiamondDetail] = useState([])
    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };

    const BuyDiamondBtn = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.C_Weight
            temptotalourprice += data.our_price
            temptotalinrcar += data.our_rate
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.our_price,
                OurRate:data.our_rate
            }
        ]
        setDiamondSelected(selectedvalues)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalourprice/temptotalcarat)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(diamondcount)
        // console.log(selectedvalues,"selectedvalues")
      }
      const BuyDiamondHoldBtn = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        if(supstatus === 2 || supstatus === 4){
            setSwalError(true)
            setErrorMsg("Stone not available to buy, Please contact to your Sales Executive ..")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.C_Weight
            temptotalourprice += data.our_price
            temptotalinrcar += data.our_rate
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.our_price,
                OurRate:data.our_rate,
                shippingdays:data.shipping_days,
                "OurPrice(USD)":data.our_price,
                "USD/CT":data.our_rate,
                diamond_type:data.diamond_type === "L"?"L":"N",
                conform_good_id:confid
            }
        ]
        setDiamondSelected(selectedvalues)
        setBuyHoldPopUp(true)
        setBuyPopUpInr(temptotalourprice/temptotalcarat)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(1)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        if(data){
            temptotalcarat += data.C_Weight
            temptotalourprice += data.our_price
            temptotalinrcar += data.our_rate
        }
        let selectedvalues = [
            {
                ...data,
                temptotalcarat:temptotalcarat,
                temptotalourprice:temptotalourprice,
                temptotalinrcar:temptotalinrcar,
                OurPrice:data.our_price,
                OurRate:data.our_rate,
                discount_main:data.our_discount
            }
        ]
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setBuyPopUpInr(temptotalourprice/temptotalcarat)
        setBuyPopUpTotal(temptotalourprice)
        setDiamondcount(diamondcount)
        // console.log(selectedvalues,"selectedvalues")
      }
      const ShareBtn = () => {
        navigator.clipboard.writeText(window.location.href)
        setShowImage(true)
      }
      function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const AddToTracklist = (row) => {
        setShow(true)
        setDiamondDetail([{
            "Certi_NO":row.Certi_NO,
            "diamond_type":row.diamond_type,
            "tracklisted_price_usd":row.our_price,
            "tracklisted_price":Math.round(row.our_price * conversionrate * 100)/100
        }])
    }
return (
        <>
        <TracklistStone show={show} setShow={setShow} language={language} diamonddetails={diamonddetails} userinfo={userinfo} symbolname={symbolname} conversionrate={conversionrate}/>
        {loader?<DetailSkeleton/>
            :data?<div className={`container product_detail ${
                ProductId.split('-')[0] === "N" ? "natTab" : "labTab"
            }`}>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
            <SwalError show={swalerror} message={errormsg} onClose={() => setSwalError(false)} responseOnOk={() => setSwalError(false)}/>
            <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
            <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>
            <BuyDiamond show={buyholdpopup} onHide={() => setBuyHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} diamondcount={diamondcount} totalourprice={buypopupctotal} language={language}/>  
            <ImageSharePopup show={showimage} onClose={() => setShowImage(false)} src={window.location.href} msg={"Stone URL Copied"}/>
            
            <div className="card card-custom card-sticky gutter-b">
                <div className="card-body">
                    <div className="row">
                        {/* data: {JSON.stringify(data)} */}
                        {/* <div className="col-12">
                            <span className="cursor-pointer text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> {language.DETAIL_GOTORESULT}</span>
                        </div> */}
                        <div className="col-md-6 d-flex flex-lg-nowrap flex-wrap justify-content-center">
                            
                            <div className="detail_img_box mt-4 order-lg-2 ribbon ribbon-top ribbon-left">
                                {data.diamond_type === "N" || data.diamond_type === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                                {data.ext_status === "Success" ? (
                                    <img
                                        className="img-fluid"
                                        alt={data.C_Shape}
                                        src={data.ext_img_runtime}
                                    />
                                ) :data.aws_image ? (
                                    <img
                                        className="img-fluid"
                                        alt={data.C_Shape}
                                        src={data.aws_image}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/${data.C_Shape ? data.C_Shape.toLowerCase() : ""}-no.png`
                                                )
                                            }}
                                    />
                                ) : (
                                    <img
                                        className="img-fluid"
                                        alt="shape"
                                        src={toAbsoluteUrl(
                                            `/media/shape/${data.C_Shape.toLowerCase()}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                    />
                                )}
                            </div>
                            <ul className="img_thumb_box mt-4 ml-5 pl-0 d-flex d-lg-block order-lg-1">
                                <ImageExpandPopupCommon data={data}/>
                                <VideoExpandPopupCommon  data={data} userinfo={userinfo}/>
                                {data.aws_heart ? (
                                    <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openHeartModal()}>
                                        <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                    </li>
                                    
                                ):('')}
                                {data.aws_arrow ? (
                                    <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openArrowModal()}>
                                        <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                    </li>
                                ):('')}
                                {data.aws_asset ? (
                                    <li className="img_thumb cursor-pointer mr-md-0 mr-1" onClick={() => openAssetModal()}>
                                        <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                    </li>
                                ):('')}
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-12 my-3 d-flex justify-content-start align-items-center">
                                    <div className=" border-right pr-4 mr-4" >
                                        {data.Lab}:{data.Certi_NO ? (
                                            <>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="mx-2 text-dark text-left"
                                                    href={
                                                        data.Lab === "IGI"
                                                            ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`
                                                            : data.Lab === "GIA"
                                                            ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}`
                                                            : data.Lab === "HRD"
                                                            ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}`
                                                            : data.Lab === "GCAL"
                                                            ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}`
                                                            : data.Certificate_link
                                                    }
                                                >
                                                    {data.Certi_NO} <FaExternalLinkAlt />
                                                </a>
                                                <CopyUrlAlert text={data.Certi_NO}/>
                                            </>

                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="">{language.MUI_STOCKNO}: <div className=" d-inline-block">{data.diamond_type === "W" ? "N" : data.diamond_type === "L" ? "L" : " "}-{data.id}</div>
                                        <span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {
                                                        data.availability === 'Memo' || data.availability === 'Hold'
                                                            ? `Business Process`
                                                            : data.availability === "Guaranteed"
                                                            ? `Guaranteed`
                                                            : data.availability === "Not Available"
                                                            ? `Not Available`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `Subject to Prior sale`
                                                            : `Unknown`
                                                        }
                                                    </Tooltip>
                                                }
                                            >
                                                <img
                                                    className="img-fluid availIco ml-2"
                                                    alt={data.availability}
                                                    src={toAbsoluteUrl(`/media/svg/${
                                                        data.availability === 'Memo' || data.availability === 'Hold'
                                                            ? `availability_primary.svg`
                                                            : data.availability === "Guaranteed"
                                                            ? `availability_success.svg`
                                                            : data.availability === "Not Available"
                                                            ? `availability_danger.svg`
                                                            : data.availability === "Subject to Prior sale"
                                                            ? `availability_warning.svg`
                                                            : `availability_blank_white.svg`
                                                    }`)}
                                                />
                                            
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                   
                                    {/* <span className=""> 
                                        {language.SEARCHLIST_LOCATION}: {data.country ? data.country : "-"}
                                        {data.country ? (
                                            <OverlayTrigger
                                                placement="top"
                                                className="text-capitalize"
                                                overlay={<Tooltip id="tooltip">{data.country}</Tooltip>}
                                            >
                                                <img
                                                    className="img-fluid flagIco mx-4"
                                                    alt={data.country}
                                                    src={toAbsoluteUrl(`/media/map/${data.country.toLowerCase()}.png`)}
                                                />
                                            </OverlayTrigger>
                                        ) : (
                                            null
                                        )}
                                    </span> */}
                                    
                                </div>
                                <div className="col-10 align-self-center">
                                    <span className="text-dark font-weight-bold text-capitalize font-size-h3">
                                        <span className="pr-2 font-size-h2">
                                            {data.C_Shape}
                                        </span>
                                        <span className="pr-2 font-size-h2">
                                            {data.C_Weight.toFixed(2)}
                                        </span>
                                        { (data.C_Color === "fancy") ?
                                            (
                                                <>
                                                    <span className="pr-2 font-size-h2">{data.f_intensity}</span>
                                                    <span className="pr-2 font-size-h2">{data.f_overtone}</span>
                                                    <span className="pr-2 font-size-h2">{data.f_color}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span className="pr-2 font-size-h2">{data.C_Color}</span>
                                                </>
                                            )
                                        }
                                        <span className="pr-2 font-size-h2">{data.C_Clarity}</span>
                                        <span className="pr-2 font-size-h2">{data.C_Cut}</span>
                                        <span className="pr-2 font-size-h2">{data.C_Polish}</span>
                                        <span className="pr-2 font-size-h2">{data.C_Symmetry}</span>
                                        <span className="pr-2 font-size-h2">{data.C_Fluorescence}</span>
                                    </span>
                                </div>
                                {
                                    <div className="col-2">
                                        <OverlayTrigger
                                        placement="top"
                                        className="text-capitalize"
                                        overlay={<Tooltip id="tooltip">Click to Tracklist</Tooltip>}
                                    >
                                        <span className="btn btn-light text-dark btn-icon" onClick={() => AddToTracklist(data)}>
                                            <SVG className="ol text-dark" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="23px" width="23px" />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                                }
                                <div className="col-12 advTag mb-4">
                                    <>
                                        {/* shade */}
                                        {renderShadeLabel(data.shade)}
                                
                                        { data.Milky && data.Milky.toLowerCase() === "no milky" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            NO MILKY
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        { data.Milky && data.Milky.toLowerCase() === "luster low" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Luster Low
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        { data.Milky && data.Milky.toLowerCase() === "light milky" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            LIGHT MILKY
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        { data.Milky && data.Milky.toLowerCase() === "milky" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            MILKY
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                                {/* eyeclean */}
                                                { data.EyeC && data.EyeC.toLowerCase() === "yes" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            100% Eye Clean
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                        { data.EyeC && data.EyeC.toLowerCase() === "no" ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            No Eye Clean
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }

                                        {/* growthtype */}
                                        { data.diamond_type === "L" && (data.brown && data.brown === "CVD") ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            CVD
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                        { data.diamond_type === "L" && (data.brown && data.brown === "HPHT") ?
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            HPHT
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                                </OverlayTrigger>
                                            </span>
                                        :
                                            null
                                        }
                                        
                                        { (data.diamond_type === "L" ) && (data.lab_treat && data.lab_treat.toLowerCase() === "as grown") ? 
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            As Grown
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                                </OverlayTrigger>
                                            </span>
                                        : 
                                            null
                                        }
                                        {/* natural treated */}
                                        { (data.diamond_type === "N" || data.diamond_type === "W") && (data.green && data.green.toLowerCase() !== "none")  ? 
                                            <span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Treated
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                                </OverlayTrigger>
                                            </span>
                                        : 
                                            null
                                        }
                                    </>
                                </div>
                                <div className="col-12 mt-4 d-flex">
                                    {data.show_supplier === 0 ? (""):(
                                       <>
                                            <span className="font-size-sm mr-5"><span className="text-dark font-size-sm  text-capitalize">{language.MUI_SUPPLIER}:</span><span className="text-capitalize">{data.C_Name}</span ></span>
                                            <span className=""> 
                                                {language.SEARCHLIST_LOCATION}: {data.country ? data.country : "-"}
                                                {data.country ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        className="text-capitalize"
                                                        overlay={
                                                            <Tooltip id="tooltip">{data.country}</Tooltip>
                                                        }
                                                    >
                                                        <img
                                                            className="flagIco mr-1"
                                                            alt={data.country}
                                                            src={toAbsoluteUrl(
                                                                `/media/map/${data.country.toLowerCase()}.png`
                                                            )}
                                                            onError={e => {
                                                                e.currentTarget.src =toAbsoluteUrl(
                                                                    `/media/map/other.png`
                                                                    )
                                                                }}
                                                        />
                                                    </OverlayTrigger>
                                                ) : (
                                                    null
                                                )}
                                            </span>
                                       </>
                                    )}
                                </div>
                                <div className="col-12 mt-4 d-flex">
                                    <span className="font-size-lg text-dark-50"><span className="font-size-sm  text-capitalize">{language.MUI_LISTPRICE}: </span>${data.list_rate}</span>
                                    <span className="font-size-lg text-dark-50 ml-8"><FaCalendarAlt className="text-danger mr-2" /><span className="font-size-sm  text-dark text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {data.shipping_days}{" "}{language.MUI_DAYS} </>}</span>
                                </div>
                                {data.ct !== 1 ?<>
                                    <div className="col-12 mt-4">
                                        <div className="row">
                                            {data.show_supplier === 0 ? (""):(
                                                <div className="col-5">
                                                    <div className="price_border_right"></div>
                                                    <div className="px-0">
                                                        <h5 className="font-weight-bold">
                                                            {language.MUI_SUPPLIERPRICE}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        Price Provideed by Supplier
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                            </OverlayTrigger>
                                                        </h5>
                                                        <hr className="w-25 ml-0 my-2"/>
                                                        <div className={`d-flex small ${(data.supplier_discount > 0) ? 'text-success' : 'text-danger'} align-items-center`}> {data.supplier_discount}% {(data.supplier_discount > 0) ? "PR" : "Off"}<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                                        {
                                                            symbol !=="$" ? <>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            ${numberWithCommas(parseFloat((data.supplier_price)))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="h1">
                                                                <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat((data.supplier_price * conversionrate)))}</>
                                                            </span>
                                                                </OverlayTrigger>
                                                            </> :
                                                            <span className="h1">
                                                                <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat((data.supplier_price * conversionrate)))}</>
                                                            </span>
                                                        } 
                                                        {
                                                            symbol !=="$" ? <>
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            ${numberWithCommas(parseFloat(data.supplier_rate))}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat((data.supplier_rate * conversionrate)))}</span></span>
                                                                </OverlayTrigger>
                                                            </> :
                                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat((data.supplier_rate * conversionrate)))}</span></span>
                                                        } 
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-5">
                                                <div className="px-0">
                                                    <h5 className="font-weight-bold">
                                                        {language.MUI_OURPRICE}
                                                        {data.show_supplier === 0 ? (
                                                                ""
                                                            ):(
                                                                <>
                                                                    <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                                    <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                                        {props => (
                                                                            <Tooltip id="overlay-example" {...props}>
                                                                            Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Overlay>
                                                                </>
                                                            )}
                                                    </h5>
                                                    <hr className="w-25 ml-0 my-2"/>
                                                    <div className={`d-flex small ${(data.our_discount > 0) ? 'text-success' : 'text-danger'} align-items-center`}> {data.our_discount}% {(data.our_discount > 0) ? "PR" : "Off"}<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                                   
                                                    { symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(parseFloat(data.our_price))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="h1">
                                                                    <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat(data.our_price * conversionrate))}</>
                                                                </span>
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="h1">
                                                            <span className="small">{symbol}</span > <>{numberWithCommas(parseFloat(data.our_price * conversionrate))}</>
                                                        </span>
                                                    } 
                                                    { symbol !=="$" ? <>
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip id="tooltip">
                                                                        ${numberWithCommas(parseFloat(data.our_rate))}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat(data.our_rate * conversionrate))}</span></span>
                                                            </OverlayTrigger>
                                                        </> :
                                                        <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(parseFloat(data.our_rate * conversionrate))}</span></span>
                                                    }  
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 d-flex">
                                        <button type="button" className="btn btn-primary px-10 mr-2"  onClick={() => (data.is_hold !== 1) ?  BuyDiamondBtn([data.id]) : BuyDiamondHoldBtn([data.id]) }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                                        <button type="button" className="btn btn-light text-dark mr-2" onClick={() => (data.is_hold !== 1) ?  HoldDiamond([data.id]) : holdWarn() }> 
                                            <SVG className="ol mr-2 text-dark" src={toAbsoluteUrl("/media/svg/icons/Custom/Hold.svg")} height="23px" width="23px" />
                                            {language.MUI_HOLDDIAMONDBTN}
                                        </button>
                                        <button type="button" className="btn btn-light text-dark" onClick={() => ShareBtn()}><FaShareAlt className="mr-2"/>Share</button>
                                    </div>
                                </> :("")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card bg-white card-custom card-sticky gutter-b">
                <div className="card-header justify-content-center mb-3 min-card-header-height p-0">
                    <div className="card-title m-0">
                        <Paper position="static" className="shadow-none">
                            <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
                                <Tab label={language.DETAIL_DIAMONDDETAILS} />
                                <Tab label={language.MUI_PARAM_DETAILS} />
                                <Tab label={language.MUI_ADDITIONALDETAILS}/>
                            </Tabs>
                        </Paper>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            {value === 0 && (
                                <TabContainer >
                                    <div className="row">
                                         <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_STOCKID} </div>
                                                <div className="col-6 "> {ProductId.split('-')[0] === "N" ? "N" :"L"}-{data.id ? data.id : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_SHAPE} </div>
                                                <div className="col-6 ">  {data.C_Shape ? data.C_Shape : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_CARAT} </div>
                                                <div className="col-6 ">  {data.C_Weight ? data.C_Weight.toFixed(2) : "-"} </div>
                                            </div>
                                           
                                            { (data.C_Color ==="fancy") ? (
                                                <>
                                                    <div className="font-size-h5 text-dark font-weight-bold mb-3 ">Fancy Color Details </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_INTENSITY}</div>
                                                        <div className="col-6 ">  {data.f_intensity ? data.f_intensity : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6">{language.DETAIL_OVERTONE}</div>
                                                        <div className="col-6 ">  {data.f_overtone ? data.f_overtone : "-"} </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-6">{language.SEARCHLIST_COLOR}</div>
                                                        <div className="col-6 ">  {data.f_color ? data.f_color : "-"} </div>
                                                    </div>
                                                </>
                                            ):(
                                                <div className="row mb-2">
                                                    <div className="col-6"> {language.SEARCHLIST_COLOR} </div>
                                                    <div className="col-6 ">  {data.C_Color ? data.C_Color : "-"} </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_CLARITY} </div>
                                                <div className="col-6 ">  {data.C_Clarity ? data.C_Clarity : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_CUT} </div>
                                                <div className="col-6 ">  {data.C_Cut ? data.C_Cut : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_POL} </div>
                                                <div className="col-6 ">  {data.C_Polish ? data.C_Polish : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_SYMM} </div>
                                                <div className="col-6 ">  {data.C_Symmetry ? data.C_Symmetry : "-"} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.SEARCHLIST_FLOU}</div>
                                                <div className="col-6 ">  {data.C_Fluorescence ? data.C_Fluorescence : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHLIST_LAB}</div>
                                                <div className="col-6 ">  {data.Lab ? data.Lab : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHLIST_CERTIFICATE}</div>
                                                <div className="col-6 ">
                                                    <a
                                                        id={data.Certi_NO}
                                                        href={certificateLink()}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="font-size-sm text-dark"
                                                    >
                                                        {data.Certi_NO}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHLIST_LOCATION}</div>
                                                <div className="col-6 ">  {data.country ? data.country : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.SEARCHGRID_SHIPPINGDAYS}</div>
                                                <div className="col-6 ">  {data.shipping_days ? <> {data.shipping_days}{" "}{language.MUI_DAYS} </> : "-"} </div>
                                            </div>
                                        </div>
                                    </div >

                                </TabContainer>
                            )}
                            {value === 1 && (
                                <TabContainer >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_MEASUREMENT}</div>
                                                <div className="col-6 ">  {data.C_Length} * {data.C_Width} * {data.C_Depth}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_TABLE} %</div>
                                                <div className="col-6 ">  {data.C_TableP ? `${data.C_TableP}%` : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_DEPTH} %</div>
                                                <div className="col-6 ">  {data.C_DefthP ? `${data.C_DefthP}%` : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_CROWN}</div>
                                                <div className="col-6 ">  {data.Crn_Ht ? `${data.Crn_Ht}%` : "-"}, {data.Crn_Ag ? `${data.Crn_Ag}°` : "-"}  </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_PAVILION}</div>
                                                <div className="col-6 ">  {data.Pav_Dp ? `${data.Pav_Dp}%` : "-"}, {data.Pav_Ag ? `${data.Pav_Ag}°` : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_GIRDLE}</div>
                                                <div className="col-6 ">   {data.gridle_per ? data.gridle_per : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_GIRDLECONDTION}</div>
                                                <div className="col-6 ">   {data.gridle ? data.gridle : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_GIRDLETHICK}</div>
                                                <div className="col-6 ">   {data.girdle_thick ? data.girdle_thick : "-"}  </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6">{language.DETAIL_GIRDLETHIN}</div>
                                                <div className="col-6 ">   {data.girdle_thin ? data.girdle_thin : "-"}  </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6">{language.MUI_CULET}</div>
                                                <div className="col-6 ">   {data.cutlet ? data.cutlet : "-"}  </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </TabContainer>
                            )}
                            {value === 2 && (
                                <TabContainer >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.MUI_SHADE} </div>
                                                <div className="col-6 ">  {data.shade ? data.shade : "-"} </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-6"> {language.MUI_MILKY} </div>
                                                <div className="col-6 ">  {data.Milky ? data.Milky : "-"} </div>
                                            </div>
                                            <div className="row mb-2"> 
                                                <div className="col-6"> {language.MUI_EYECLEAN} </div>
                                                <div className="col-6 ">  {data.EyeC ? data.EyeC : "-"} </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            {ProductId.split('-')[0] === "L" ? 
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                        <div className="col-6 ">  {data.brown ? data.brown : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                        <div className="col-6 ">  {data.lab_treat === "Treated"?"Post Growth Treatment": data.lab_treat?data.lab_treat : "-"} </div>
                                                    </div>
                                                </>
                                            :   
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                        <div className="col-6 ">  {data.brown ? data.brown : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_BRAND} </div>
                                                        <div className="col-6 ">  {data.canada_mark ? data.canada_mark : "-"} </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                        <div className="col-6 ">  {data.green ? data.green : "-"} </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row mb-2"> 
                                                <div className="col-6"> {language.MUI_KEY} </div>
                                                <div className="col-6 ">  {data.Key_Symbols ? data.Key_Symbols : "-"} </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabContainer>
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* <div className="card card-custom card-sticky gutter-b">
                <div className="card-header justify-content-center mb-3">
                    <div className="card-title m-0 ">
                         {language.DETAIL_DIAMONDCERTIFICATE}
                    </div>
                </div>
                <div className="card-body text-center">
                    <iframe frameBorder="0" src={data.Lab === "IGI" ? `https://www.igi.org/verify-your-report/?r=LG${data.Certi_NO}`  :  data.Lab === "GIA"  ? `https://www.gia.edu/report-check?reportno=${data.Certi_NO}` : data.Lab === "HRD" ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.Certi_NO}` : data.Lab === "GCAL" ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.Certi_NO}` : data.Certi_link} width="100%" height="600px" title="certificate"></iframe>
                </div>
            </div> */}
            <div className="card card-custom card-sticky gutter-b">
                <div className="card-header justify-content-center">
                    <div className="card-title m-0 ">
                         SIMILAR DIAMONDS
                    </div>
                </div>
                <div className="card-body bg-white rounded">
                    {similarLoader ? <SimilarSearchGridSkeleton /> : 
                        <div className="d-flex flex-wrap justify-content-start gridData">
                            {SimiliarDiamonds.map((val,index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <SimilarDiamonds
                                        // shape={val.SHAPE}
                                        key={val.id}
                                        {...val}
                                        labelId={labelId}
                                        conversionrate={conversionrate}
                                        symbol={symbol}
                                        language={language}
                                    />
                                );
                            })}
                        </div>
                    }
                </div>
        </div>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={ProductId.split('-')[0] === "N" ? `https://pro360video.com/video.php?refno=${data.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${data.Certi_NO}` }
                // src={data.ext_video || ""}
                data={data}                           
            />
            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={data.aws_heart} alt={data.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={data.aws_arrow} alt={data.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={data.aws_asset} alt={data.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="Stone already in tracklist"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Stone tracklisted successfully"
                />
            </Snackbar>
            </div>:<div className="card custom-card gutter-b">
        <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
            <div className="text-center">
                <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                <div className="mt-8 font-size-h4 text-capitalize "><strong>No Records Found</strong></div>
                {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
            </div>
        </div>
    </div>}
        </>
    );
};
