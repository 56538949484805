import React, { useState, useEffect } from "react";
import { IoCloseOutline, IoShareOutline } from "react-icons/io5";
import { Modal, OverlayTrigger,Tooltip } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { ImageSharePopup } from "./ImageSharePopup";
import axios from "axios";
export const VideoExpandPopupCommon = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(
                props.data.diamond_type === "N" ||
                    props.data.diamond_type === "W"
                    ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}`
                    : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`,
                "_blank"
            );
        } else {
            setShow(true);
        }
    };

    const [frameloaded, setFrameLoaded] = useState(false);
    const [showimage, setShowImage] = useState(false);
    const [shareurl, setShareURL] = useState("");

    const FrameFunction = () => {
        setFrameLoaded(true);
    };

    const copyurl = (diamonddata) => {
        if (diamonddata.ext_status === "Success") {
            axios({
                method: "POST",
                url: `https://api.dia360.cloud/api/admin/revert-public-url`,
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": "26eca0a8-1981-11ee-be56-0242ac120002",
                },
                data: {
                    ext_video_id: diamonddata.ext_video_id.toString(),
                    client_id: props.userinfo.id.toString(),
                },
            }).then(function(response) {
                if (response && response.data && response.data.url) {
                    setShareURL(response.data.url);
                    navigator.clipboard.writeText(response.data.url);
                    setShowImage(true);
                }
            });
        } else if (diamonddata.video) {
            const videoUrl =
                props.data.diamond_type === "N" || props.data.diamond_type === "W"
                    ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}`
                    : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`;
            setShareURL(videoUrl);
            navigator.clipboard.writeText(videoUrl);
            setShowImage(true);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && show) {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [show]);

    const GetVideoUrl = (data) => {
        if (data.ext_status === "Success") {
            return data.m_video_path;
        } else if (data.video) {
            return props.data.diamond_type === "N" || props.data.diamond_type === "W"
                ? `https://pro360video.com/video.php?refno=${props.data.Certi_NO}`
                : `https://pro360video.com/labgrown.php?refno=${props.data.Certi_NO}`;
        }
        return "";
    };

    return (
        <>
            {GetVideoUrl(props.data) ? (
                <div>
                    <li className="img_thumb cursor-pointer" onClick={(e) => handleShow(e)}>
                        <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                    </li>

                    <ImageSharePopup
                        show={showimage}
                        onClose={() => setShowImage(false)}
                        src={shareurl}
                    />
                    <Modal
                        className="p-0 video-modal"
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-fullscreen"
                    >
                        <div className="modal-header-custom">
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Share </Tooltip> } >
                                <IoShareOutline  className="modal-icon" onClick={() => copyurl(props.data)} />
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Close </Tooltip> } >
                                <IoCloseOutline  className="modal-icon" onClick={handleClose} />
                            </OverlayTrigger>
                        </div>
                        <Modal.Body className="modal-body-custom">
                            {!frameloaded && (
                                <div className="loader-container">
                                    <CircularProgress />
                                    <h6>Please Wait...</h6>
                                </div>
                            )}
                            {GetVideoUrl(props.data) && (
                                <iframe
                                    id="iframedata"
                                    title="Video"
                                    src={GetVideoUrl(props.data)}
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    onLoad={FrameFunction}
                                    style={{ display: frameloaded ? "block" : "none" }}
                                ></iframe>
                            )}
                        </Modal.Body>
                    </Modal>
                </div>
            ) : ( null )}
        </>
    );
};
