import React,{useCallback,Component,useState} from "react";
import { at } from "lodash";
import {DropzoneDialog} from 'material-ui-dropzone'
import { useField } from "formik";
// import { TextField } from '@material-ui/core';
import {Button} from '@material-ui/core';

// export default function MuiUploader(props) {
//   const maxSize = 2000000;
//     const { errorText, setFirstFile,setSecondFile,setThirdFile ,filefield} = props;
//     const [field, meta] = useField(props);
//     // const { value: setFirstFile } = field;
//     const onDrop = useCallback(acceptedFiles => {
//       console.log(acceptedFiles);
//       console.log('handleExcelDrop' ,acceptedFiles[0])
//       // console.log('field' ,setFirstFile)
//       if(filefield === "passport_id_file"){
//       setFirstFile(acceptedFiles);
//       }
//       if(filefield === "logo"){
//         setSecondFile(acceptedFiles);

//       }
//       if(filefield === "reg_no"){
//         // console.log("Third")
//         setThirdFile(acceptedFiles);

//       }
//     }, []);

//     const {isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles} = useDropzone({
//         multiple: false,
//         // accept: {
//         //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
//         //     '.png',
//         //     '.jpeg',
//         //     '.jpg',
//         //     '.pdf',
//         //   ]
//         // },
//         accept: {
//           'image/jpeg': [],
//           'image/jpg': [],
//           'image/png': [],
//           'application/pdf':[]
//         },
//         minSize: 0,
//         maxSize,
//         onDrop
//       })

//     const files = acceptedFiles.map(file => (
//         <span className="small" key={file.path}>
//           {file.path} - {file.size} bytes
//         </span>
//       ));
//       const isFileTooLarge = acceptedFiles.length > 0 && acceptedFiles[0].size > maxSize;
// console.log("isFileTooLarge" ,rejectedFiles)
//     function _renderHelperText() {
//         const [touched, error] = at(meta, "touched", "error");
//         if (touched && error) {
//             return error;
//         }
//     }

//     return (
//         <>
//             <section className="wfp--dropzone">
//                 <div {...getRootProps({className: 'dropzone'})}>
//                     <input {...getInputProps()}   error={meta.touched && meta.error && true}
//                     helperText={_renderHelperText()} {...field}
//                     {...props}/>
//                     <div>
//                         <FaCloudUploadAlt className="text-primary font-size-h2 mr-2" /> Drag & Drop here or <span className="text-primary cursor-pointer">Browse</span >
//                     </div>
//                 </div>
//             </section>
//             {files}
//             <i className="small d-block mt-2">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</i>
//             {!isDragActive && 'Click here or drop a file to upload!'}
//             {isDragActive && !isDragReject && "Drop it like it's hot!"}
//             {isDragReject && "File type not accepted, sorry!"}
//             {isFileTooLarge && (
//               <div className="text-danger small mt-2">
//                 File is too large. 
//               </div>
//             )}
//         </>
       
//     );
// }

// export default class MuiUploader extends Component {
//   constructor(props) {
//       super(props);
//       this.state = {
//           open: false,
//           files: [],
//           filename:[]
//       };
//   }

//   handleClose(files) {
   
//       this.setState({
//           open: false
//       });
//   }

//   handleSave(files) {
//       //Saving files to state for further use and closing Modal.
//       console.log(this.props,"PROPS")
//       console.log(files[0].path,"aaa")
//       const filename = new Date().getTime() + "-" + files[0].path
//       const file = new File(files, filename);
//       console.log(file,"FILESSSS")
//       if(this.props.filefield === "passport_id_file"){
//         this.props.setFirstFile([file]);
//         this.props.setPassportFileName(filename)
//         }
//         if(this.props.filefield === "logo"){
//             this.props.setSecondFile(files);
  
//         }
//         if(this.props.filefield === "reg_no"){
//           // console.log("Third")
//           this.props.setThirdFile([file]);
//           this.props.setCompanyRegName(filename)
  
//         }
//       this.setState({
//           files: files,
//           open: false,
//           filename:files[0].path
//       });
//   }

//   handleOpen() {
//       this.setState({
//           open: true,
//       });
//   }

//   render() {
//       return (
//           <div className="border p-3 rounded muiUploader">
//               <Button variant="contained" color="primary" onClick={this.handleOpen.bind(this)}>
//                 Add File
//               </Button>
//               <span className="ml-3">{this.state.filename}</span >
//               {/* <i className="small d-block mt-2">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</i> */}
//               <DropzoneDialog
//                   open={this.state.open}
//                   onSave={this.handleSave.bind(this)}
//                   acceptedFiles={['image/jpeg','image/jpg', 'image/png', 'image/bmp','application/pdf']}
//                   showPreviews={false}
//                   maxFileSize={2100000}
//                   onClose={this.handleClose.bind(this)}
//                   filesLimit={1}
//                   showPreviewsInDropzone={true}
//                   previewText={true}
//                   dropzoneText={<span>Drag & Drop <br /> <span className="small font-italic text-muted">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</span ></span>}
//               />
//           </div>
//       );
//   }
// }

export default function MuiUploader(props){
  const [open,setOpen] = useState(false)
  const [field, meta] = useField(props);
  // console.log(props.values,"MUIVALUESSSSS")
    function handleSave(files) {
        //Saving files to state for further use and closing Modal.
        // console.log(props,"PROPS")
        // console.log(files[0].path,"aaa")
        const filename = new Date().getTime() + "-" + files[0].path
        const file = new File(files, filename);
        
          if(props.filefield === "white_label_logo"){
            // console.log("Third")
            // props.setFieldValue([file]);
            // props.setCompanyRegName(filename)
            props.setFieldValue("white_label_logo",filename)
            props.setCompanyLogo([file])
    
          }
          setOpen(false)
        // this.setState({
        //     files: files,
        //     open: false,
        //     filename:files[0].path
        // });
    }
    // console.log(meta.error,"meta.error")
    return (
        <div className={`muiuploader-border p-3 rounded muiUploader ${meta.error ? "uploaderErrorBorder" : ""}`}>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
              Add File
            </Button>
            <span className="ml-3">{props.filefield === "white_label_logo" && props.values && props.values.white_label_logo?props.values.white_label_logo:""}</span >
            {/* <span className="ml-3">{props.filefield === "reg_no" && props.values && props.values.comp_reg_no_file?props.values.comp_reg_no_file:""}</span > */}
            {/* <i className="small d-block mt-2">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</i> */}
            {meta.error?<span className="muiuploader-error">{meta.error}</span>:""}
            <DropzoneDialog
                open={open}
                onSave={handleSave}
                acceptedFiles={['image/jpeg','image/jpg', 'image/png', 'image/bmp']}
                showPreviews={false}
                maxFileSize={2100000}
                onClose={() => setOpen(false)}
                filesLimit={1}
                showPreviewsInDropzone={true}
                previewText={true}
                dropzoneText={<span>Drag & Drop <br /> <span className="small font-italic text-muted">File Format : Png, Jpeg, Jpg . Max size:2.00Mb</span ></span>}
            />
        </div>
    );
}