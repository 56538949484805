import React from "react";
import { Field } from "formik";
import {MultiSelect} from '../MultiSelect'


export const KeytoSymbol = ({language,formik}) => {
    const keytoSymbolData = ([
        {
            id: 1,
            value:"Bearding"
        },
        {
            id: 2,
            value:"Crystal"
        },
        {
            id: 3,
            value:"Internal Graining"
        },
        {
            id: 4,
            value:"Natural"
        },
        {
            id: 5,
            value:"Brown patch of color"
        },
        {
            id: 6,
            value:"Canada"
        },
        {
            id: 7,
            value:"Crystal Surface"
        },
        {
            id: 8,
            value:"Internal Inscription"
        },
        {
            id: 9,
            value:"Needle"
        },
        {
            id: 10,
            value:"Bruise"
        },
        {
            id: 11,
            value:"Etch Channel"
        },
        {
            id: 12,
            value:"Internal Laser Drilling"
        },
        {
            id: 13,
            value:"No Inclusion"
        },
        {
            id: 14,
            value:"Cavity"
        },
        {
            id: 15,
            value:"Extra Facet"
        },
        {
            id: 16,
            value:"Knot"
        },
        {
            id: 17,
            value:"Pinpoint"
        },
        {
            id: 18,
            value:"Chip"
        },
        {
            id: 19,
            value:"Feather"
        },
        {
            id: 20,
            value:"Laser Drill Hole"
        },
        {
            id: 21,
            value:"Reﬂecting Surface Graining"
        },
        {
            id: 22,
            value:"Cleavage"
        },
        {
            id: 23,
            value:"Flux Remnant"
        },
        {
            id: 24,
            value:"Manufacturing Remnant"
        },
        {
            id: 25,
            value:"Surface Graining"
        },
        {
            id: 26,
            value:"Cloud"
        },
        {
            id: 27,
            value:"Indented Natural"
        },
        {
            id: 28,
            value:"Minor Details of Polish"
        },
        {
            id: 29,
            value:"Twinning Wisp"
        }

    ]);
    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.MUI_KEY}</p>
                </div>
                <div className="col-lg-12">
                    <MultiSelect language={language} options={keytoSymbolData} label={"Select Key to symbol"} name={"keytoSymbol"} formik={formik}/>
                    {/* <div className="d-flex flex-wrap">
                        {keytoSymbolData.map((item) => (
                            <div key={item.id} className=" mr-3">
                                <label className="checkbox mb-2">
                                    <Field type="checkbox" name="keytoSymbol" id={`${item.value}_keytoSymbol`} value={item.value}/>
                                    <span className="mr-2"></span>
                                    {item.value}
                                </label>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
        </>
    );
}; 