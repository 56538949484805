/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from "react";
import { FaCog, FaLaptopCode } from 'react-icons/fa';
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import {ApiDataNatural} from "./ApiDataTable/ApiDataNatural"
import {ApiDataLab} from "./ApiDataTable/ApiDataLab"
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip,Alert } from "react-bootstrap";
import {
    Paper,
    Tabs,
    Tab,
    Typography
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  
  function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }}>
            {props.children}
        </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  

export const FeedApprovedFrame = (props) => {
    const history = useHistory()

    const [value, setValue] = React.useState(0); // for tab

    function handleTabChange(event, newValue) {
      setValue(newValue);
    }
    const [feedsetting,setFeedSetting] = useState(null)
    useEffect(() => {
        if(props.userinfo){
            axios({
                method: "POST",
                url: `${NODE_API_URL}/API/fetchIframeorAPISetting`,
                headers: { "Content-Type": "application/json" },
                data: {
                    "user_id":props.userinfo.id
                },
            }).then(supreq => {
                if(supreq && supreq.data && supreq.data.data){
                    // setFeedLoader(false)
                    setFeedSetting(supreq.data.data)
                }
            })
        }
    },[props.userinfo])

    return (
        <>
            {feedsetting?<div className="card card-custom">
                <div className="card-header align-items-center">
                    <div className="card-title m-0">
                        <h3 className="card-label font-weight-bolder text-dark">{props.language.DIAMOND_FEED_CENTER}</h3>
                    </div>
                    <div className="card-toolbar">
                        {feedsetting.feed_type === "API"?<button className="btn btn-text-dark btn-hover-light-dark" 
                        onClick={() => history.push({
                            pathname: "/diamondfeed",
                            state: "apisetting",
                            // state: decompress(res.data.ProductDetails),
                        })}><FaLaptopCode  className="mr-2 font-size-h4" /> API Dashboard</button>:""}
                        {feedsetting.feed_type === "IFrame Plug-in"?<button className="btn btn-text-dark btn-hover-light-dark mr-2" 
                        onClick={() => history.push({
                            pathname: "/diamondfeed",
                            state: "apisetting",
                            // state: decompress(res.data.ProductDetails),
                        })}><FaLaptopCode className="mr-2 font-size-h4"/>IFrame Dashboard</button>:""}
                        {feedsetting.feed_type === "IFrame Plug-in"?
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Iframe Settings
                                    </Tooltip>
                                }
                            >
                                <button className="btn btn-text-dark btn-hover-light-dark"  onClick={() => history.push({ pathname: "/iframe-setting", state: "embeddedcode"})}><FaCog className="mr-2"/> settings</button>
                            </OverlayTrigger>
                            :null
                        }
                    </div>
                </div>
                <div className="card-body bg-white" >
                {feedsetting.feed_type === "API" && feedsetting.api_live_mode !== 1?
                    <Alert variant="danger">
                            Once Testing is completed, Please contact to your sales administrator to Activate your Live API Key.
                    </Alert>
                :""}
                    <div className="border-bottom col-12 d-flex justify-content-center">  
                        <Paper position="static" className="shadow-none border-bottom">
                            <Tabs value={value} indicatorColor="primary" textColor="primary" className="API_data" onChange={handleTabChange}>
                                <Tab label={props.language.DIAMOND_FEED_NATURAL_LISTING} />
                                <Tab label={props.language.DIAMOND_FEED_LAB_LISTING}  />
                            </Tabs>
                        </Paper>
                    </div>
                    <div className="col-12">  
                        {value === 0 && (
                            <TabContainer >
                                <ApiDataNatural userinfo={props.userinfo} language={props.language} feedsetting={feedsetting}/>
                            </TabContainer>
                          )}
                          {value === 1 && (
                            <TabContainer >
                                <ApiDataLab userinfo={props.userinfo} language={props.language} feedsetting={feedsetting}/>
                            </TabContainer>
                          )}
                    </div>
                </div>
            </div>:""}
        </>
    );
};
