/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Payment} from "./Payment"
// import {PaymentSuccess} from "./PaymentSuccess"
// import {PaymentFailed} from "./PaymentFailed"
import { useLocation } from "react-router-dom";
export const Checkout = (props) => {
    const location = useLocation()
    return (
        <>
          <Payment {...props} details={location.state}/>
          {/* <PaymentSuccess /> */}
          {/* <PaymentFailed /> */}
        </>
    );
};
