import React from "react";
import { Field } from "formik";

export const Finish = ({language}) => {
    const CutData = ([
        {
            id: 1,
            cut:"ID"
        },
        {
            id: 2,
            cut:"EX"
        },
        {
            id: 3,
            cut:"VG"
        },
        {
            id: 4,
            cut:"GD"
        },
        {
            id: 5,
            cut:"FR"
        },
        {
            id: 6,
            cut:"PR"
        }
    ]);
    const PolishData = ([
        {
            id: 1,
            polish:"ID"
        },
        {
            id: 2,
            polish:"EX"
        },
        {
            id: 3,
            polish:"VG"
        },
        {
            id: 4,
            polish:"GD"
        },
        {
            id: 5,
            polish:"FR"
        },
        {
            id: 6,
            polish:"PR"
        }
    ]);
    const SymmetryData = ([
        {
            id: 1,
            symmetry:"ID"
        },
        {
            id: 2,
            symmetry:"EX"
        },
        {
            id: 3,
            symmetry:"VG"
        },
        {
            id: 4,
            symmetry:"GD"
        },
        {
            id: 5,
            symmetry:"FR"
        },
        {
            id: 6,
            symmetry:"PR"
        }
    ]);

    return (
        <>
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-lg font-weight-bold mb-0">{language.FINISH_FINISH}</p>
                </div>
                <div className="col-lg-10 d-flex flex-wrap">
                    <div className="mr-8">
                        <label htmlFor="">CUT</label><br />
                        {CutData.map((item) => (
                            <label key={`${item.id}_cut`} className="search_btn_check">
                                <Field type="checkbox" name="Cut" id={`${item.cut}_cut`} value={item.cut}/>
                                <div className="finishBox selectBox">
                                    <span>{item.cut}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                    <div className="mr-8">
                        <label htmlFor="">POLISH</label><br />
                        {PolishData.map((item) => (
                            <label key={`${item.id}_polish`} className="search_btn_check">
                                <Field type="checkbox" name="Polish" id={`${item.polish}_polish`} value={item.polish}/>
                                <div className="finishBox selectBox">
                                    <span>{item.polish}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                    <div>
                        <label htmlFor="">SYMMETRY</label><br />
                        {SymmetryData.map((item) => (
                            <label key={`${item.id}_symmetry`} className="search_btn_check">
                                <Field type="checkbox" name="Symmetry" id={`${item.symmetry}_symmetry`} value={item.symmetry}/>
                                <div className="finishBox selectBox">
                                    <span>{item.symmetry}</span>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};