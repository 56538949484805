/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";

export const IFrameRequested = (props) => {

    return (
        <>
            <div className="row align-content-start position-relative" style={{minHeight: "80vh"}}>
                <div className="col-12">
                   <h3>{props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG1}</h3>
                   <Alert className="my-5" variant="success">
                        {props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG2} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG3} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG4}.
                    </Alert>
                   <p className="text-muted">{props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG2} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG3} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN} {props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG4}.</p>
                   <p className="text-muted">{props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG5} {props.feed_type === "API"?props.language.DIAMOND_FEED_API:props.language.DIAMOND_FEED_IFRAME_PLUGIN}.</p>
                   <p className="text-muted">{props.language.DIAMOND_FEED_IFRAME_REQUESTEDMSG6}</p>
                </div>
                <div className="api-home-svg api-success"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div>
            </div>
        </>
    );
};
