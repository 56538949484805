import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
// import { login } from "../_redux/authCrud";
import styles from "./css/style.module.css"
import axios from "axios"
import {LOGIN} from "./../../../../env_config"

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  EMail: "",
  Password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    EMail: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      Password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        axios({
          method: 'POST',
          url: LOGIN,
          headers: { 
              'Access-Control-Allow-Origin': '*', 
              'Content-Type': 'application/json'
            },
          data: values
        })
          .then(function (res) {
             disableLoading();
            // const resData = JSON.stringify(res.data.Customer[0])
            // const currencyData = JSON.stringify(res.data.Currency)
            if( res.data.Status=== 1 ){
              const accessToken ="access-token-d2dff7b82f784de0aa10964abb20c1a5"
              props.login(accessToken);
              let resData = {"CustomerId":2399,"CompanyName":"Test2","EMail":"ios23user@gmail.com","ContactNo":"+91 7623901390","CompanyAddress":"test 1 test 1 surat 395007","SalesPerson":"test account","Currency":"INR","CustomerType": 3,"Country":"India"}
              localStorage.setItem("userData", JSON.stringify(resData))
              console.warn(res)
            } else {
              setStatus(
                res.data.Message
              );
              setSubmitting(false);
            }
            // window.localStorage.setItem("userData", resData)
            // console.log(LOGIN)
            // window.localStorage.setItem("userCurrency", currencyData)
            // console.warn(res)
          })
          .catch((res) => {
            disableLoading();
            setSubmitting(false);
            console.error(res)
            window.localStorage.setItem("userData", '')
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className={styles.web_login} id="kt_login_signin_form">
      <div className="container">
        {/* begin::Head */}
        <div className="text-center ">
          <h3 className="font-size-h1">
            Login to Diamonds on Call
          </h3>
          <p className="text-muted font-weight-bold">
            Enter your username and password
          </p>
        </div>
        {/* end::Head */}
        <div className={styles.login_form}>
          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            ) : (
              <div className="">
                {/* <div className="alert-text">
                  Use account <strong>admin@demo.com</strong> and password{" "}
                  <strong>demo</strong> to continue.
                </div> */}
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
                    name="email"
                    {...formik.getFieldProps("EMail")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
                    name="password"
                    {...formik.getFieldProps("Password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                  <label className="checkbox" htmlFor="rememberme">
                      <input type="checkbox" name="rememberme" id="rememberme"  />
                      <span className="mr-2"></span>
                      Remember me
                  </label>
                </div>
                <div className="col-md-6">
                    <div className="sum_tab text-right">
                    <Link
                      to="/auth/forgot-password"
                      className="text-dark-50 text-hover-primary my-3 mr-2"
                      id="kt_login_forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="sum_tab text-center">
                        <button
                          type="submit"
                          className={`submit btn ${styles.btnPrimary} px-10 py-3 shadow-sm my-4`}
                          disabled={formik.isSubmitting} 
                        >
                          Login
                          {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </div>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
