import React from 'react';
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

export const MockShortListColumn = (language,userinfo) => [

    {
        Header:"Full Name",
        accessor:'FullName',
        className:"align-middle text-center",
    },
    {
        Header:"Email",
        accessor:'Email',
        className:"align-middle text-center",
    },
    {
        Header:language.DATE_AND_TIME,
        accessor: (d) => {
            return (
                <>
                 { d.CreatedAt ? moment.tz(d.CreatedAt, userinfo.timezone ? userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}
                {/* { d.CreatedAt} */}
                </>
            );
        },
        className:"text-center align-middle",
    },
    {
        Header:language.CCMODE_SETUP_APPROVED_NOSTONES,
        accessor:'ShortListedStones',
        className:"text-center align-middle",
    },
    {
        Header:language.SEARCHLIST_TOTALCARAT,
        // accessor:'Carats',
        accessor: (d) => {
            return (
                <>
                    {d.Carats ? d.Carats.toFixed(2):null}
                </>
            );
        },
        className:"text-center align-middle",
    },
   
 
]