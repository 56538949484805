import React from "react";
import ForWhome from "./About/ForWhome";
import Title from "./About/Title";
import VisionMision from "./About/VisionMision";
import WhatWeDo from "./About/WhatWeDo";
import WhyitStarted from "./About/WhyitStated";
import Requestdemo from "./Homepage/Requestdemo";


const About = () =>{
    return (
        <>
            <title>About Us - Diamonds on Call Limited</title>
            <Title />
            <VisionMision />
            <WhyitStarted />
            <WhatWeDo />
            <ForWhome />
            <Requestdemo />
        </>
    )
}
export default About;