import React from "react";
import { Accordion, Card } from "react-bootstrap";
// import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";
import { Brand } from "./Additional/Brand";
import { KeytoSymbol } from "./Additional/KeytoSymbol";
import { Origin } from "./Additional/Origin";
import { Treatment } from "./Additional/Treatment";
// import { Type } from "./Additional/Type";

export const Additional = (props) => {
       return (
        <>
            <Accordion className="search_accordion">
                <Card>
                    <Accordion.Toggle className="sticky-top" style={{top:"64px"}}  as={Card.Header} eventKey="1" key="1">
                        {props.language.MUI_ADDITIONALDETAILS}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body >
                            <Brand language={props.language}/>
                            <Origin language={props.language}/>
                            <Treatment language={props.language}/>
                            {/* <Type /> */}
                            <KeytoSymbol language={props.language}/>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
