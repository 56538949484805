/* eslint-disable */
export default {
  formId: 'kycForm',
  formField: {
    naturaldiamond: {
      name: 'naturaldiamond',
      label: 'Colorless Diamonds',
      icon: 'white_dia_icon',
      requiredErrorMsg: 'Please Select'
    },
    naturalfancydiamond: {
      name: 'naturalfancydiamond',
      label: 'Fancy Color Diamonds',
      icon: 'fancy_dia_icon',
      requiredErrorMsg: 'Please Select'
    },
    lgwhitediam: {
      name: 'lgwhitediam',
      label: 'Colorless Diamonds',
      icon: 'lab_dia_icon',
      requiredErrorMsg: 'Please Select'
    },
    lgfancydiam: {
      name: 'lgfancydiam',
      label: 'Fancy Color Diamonds',
      icon: 'lab_fancy_dia_icon',
      requiredErrorMsg: 'Please Select'
    },
    diamondshape: {
      name: 'diamondshape',
      label: 'Diamond Shape',
      requiredErrorMsg: 'Please Select Diamond Shape'
    },
    carat_from: {
      name: 'carat_from',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    carat_to: {
      name: 'carat_to',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    diamondcolor: {
      name: 'diamondcolor',
      label: 'Colorless Diamond',
      requiredErrorMsg: 'Please Select Colorless Diamond'
    },
    diamondfancy_color: {
      name: 'diamondfancy_color',
      label: 'Colors',
      requiredErrorMsg: 'Please Select Fancy'
    },
    diamondfancy_intensity: {
      name: 'diamondfancy_intensity',
      label: 'Intensity',
      requiredErrorMsg: 'Please Select Intensity'
    },
    diamondfancy_overtone: {
      name: 'diamondfancy_overtone',
      label: 'Overtone',
      requiredErrorMsg: 'Please Select Overtone'
    },
    diamondclarity: {
      name: 'diamondclarity',
      label: 'Diamond Clarity',
      requiredErrorMsg: 'Please Select Diamond Clarity'
    },
    lab_val: {
      name: 'lab_val',
      label: 'Diamond Lab',
      classes: 'labBox',
      requiredErrorMsg: 'Please Select Diamond Lab'
    },
    cuts: {
      name: 'cuts',
      classes: 'cuts',
      label: 'Diamond Cut',
      requiredErrorMsg: 'Please Select Diamond Cut'
    },
    polishes: {
      name: 'polishes',
      classes: 'polishes',
      label: 'Diamond Polish',
      requiredErrorMsg: 'Please Select Diamond Polish'
    },
    symmetries: {
      name: 'symmetries',
      classes: 'symmetries',
      label: 'Diamond Symm',
      requiredErrorMsg: 'Please Select Diamond Symm'
    },
    diamondflour: {
      name: 'diamondflour',
      // classes: 'diamondflour',
      label: 'Diamond Fluorescence',
      requiredErrorMsg: 'Please Select Diamond Fluorescence'
    },
    diamondshade: {
      name: 'diamondshade',
      // classes: 'diamondflour',
      label: 'Shade',
      requiredErrorMsg: 'Please Select Shade'
    },
    milky: {
      name: 'milky',
      classes: 'milky',
      label: 'Milky',
      requiredErrorMsg: 'Please Select Milky'
    },
    eyeclean: {
      name: 'eyeclean',
      classes: 'eyeclean',
      label: 'eyeclean',
      requiredErrorMsg: 'Please Select Eyeclean'
    },
    price_from: {
      name: 'price_from',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    price_to: {
      name: 'price_to',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    total_price_from: {
      name: 'total_price_from',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    total_price_to: {
      name: 'total_price_to',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    media: {
      name: 'media',
      label: 'Media Assets',
      value: "0",
      requiredErrorMsg: 'Please Select Media Assets'
    },


    lab_shape: {
      name: 'lab_shape',
      label: 'Diamond Shape',
      requiredErrorMsg: 'Please Select Diamond Shape'
    },
    lab_carat_from: {
      name: 'lab_carat_from',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    lab_carat_to: {
      name: 'lab_carat_to',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    lab_color: {
      name: 'lab_color',
      label: 'Colorless Diamond',
      requiredErrorMsg: 'Please Select Colorless Diamond'
    },
    lab_fancy_color: {
      name: 'lab_fancy_color',
      label: 'Colors',
      requiredErrorMsg: 'Please Select Colors'
    },
    lab_fancy_intensity: {
      name: 'lab_fancy_intensity',
      label: 'Intensity',
      requiredErrorMsg: 'Please Select Intensity'
    },
    lab_fancy_overtone: {
      name: 'lab_fancy_overtone',
      label: 'Overtone',
      requiredErrorMsg: 'Please Select Overtone'
    },
    lab_clarity: {
      name: 'lab_clarity',
      label: 'Diamond Clarity',
      requiredErrorMsg: 'Please Select Diamond Clarity'
    },
    lab_lab: {
      name: 'lab_lab',
      label: 'Diamond Lab',
      classes: 'labBox',
      requiredErrorMsg: 'Please Select Diamond Lab'
    },
    lab_cuts: {
      name: 'lab_cuts',
      classes: 'cuts',
      label: 'Diamond Cut',
      requiredErrorMsg: 'Please Select Diamond Cut'
    },
    lab_polishes: {
      name: 'lab_polishes',
      classes: 'polishes',
      label: 'Diamond Polish',
      requiredErrorMsg: 'Please Select Diamond Polish'
    },
    lab_symmetries: {
      name: 'lab_symmetries',
      classes: 'symmetries',
      label: 'Diamond Symm',
      requiredErrorMsg: 'Please Select Diamond Symm'
    },
    lab_flourselect: {
      name: 'lab_flourselect',
      // classes: 'diamondflour',
      label: 'Diamond Fluorescence',
      requiredErrorMsg: 'Please Select Diamond Fluorescence'
    },
    lab_shadeselect: {
      name: 'lab_shadeselect',
      // classes: 'diamondflour',
      label: 'Shade',
      requiredErrorMsg: 'Please Select Shade'
    },
    lab_milky: {
      name: 'lab_milky',
      classes: 'milky',
      label: 'Milky',
      requiredErrorMsg: 'Please Select Milky'
    },
    lab_eyeclean: {
      name: 'lab_eyeclean',
      classes: 'eyeclean',
      label: 'Eyeclean',
      requiredErrorMsg: 'Please Select Eyeclean'
    },
    lab_min_dollarperct: {
      name: 'lab_min_dollarperct',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    lab_max_dollarperct: {
      name: 'lab_max_dollarperct',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    lab_total_price_from: {
      name: 'lab_total_price_from',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    lab_total_price_to: {
      name: 'lab_total_price_to',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    lab_all_media: {
      name: 'lab_all_media',
      label: 'Media Assets',
      value: "0",
      requiredErrorMsg: 'Please Select Media Assets'
    },


    primarycolor: {
      name: 'primarycolor',
      label: 'First Main Color',
      requiredErrorMsg: 'First Main Color is Required'
    },
    secondary: {
      name: 'secondary',
      label: 'Second Main Color',
      requiredErrorMsg: 'Second Main Color is Required'
    },
    fontcolor: {
      name: 'fontcolor',
      label: 'Font Color',
      requiredErrorMsg: 'Font Color is Required'
    },
    diamondmarkup: {
      name: 'diamondmarkup',
      label: 'Natural White Diamond Markup',
      requiredErrorMsg: 'Natural White Diamond Markup is Required',
      inputProps: '%'
    },
    diamondfancymarkup: {
      name: 'diamondfancymarkup',
      label: 'Natural Fancy Diamond Markup',
      requiredErrorMsg: 'Natural Fancy Diamond Markup is Required',
      inputProps: '%'
    },
    lab_diamondmarkup: {
      name: 'lab_diamondmarkup',
      label: 'Lab Grown White Diamond Markup',
      requiredErrorMsg: 'Lab Grown White Diamond Markup is Required',
      inputProps: '%'
    },
    lab_diamondfancymarkup: {
      name: 'lab_diamondfancymarkup',
      label: 'Lab Grown Fancy Diamond Markup',
      requiredErrorMsg: 'Lab Grown Fancy Diamond Markup is Required',
      inputProps: '%'
    },
    website_url: {
      name: 'website_url',
      label: 'https://',
      requiredErrorMsg: 'Website is required'
    },
    inquiry_email: {
      name: 'inquiry_email',
      label: 'Email',
      requiredErrorMsg: 'Email id is required'
    },
    inquiry_cc_email: {
      name: 'inquiry_cc_email',
      label: 'CC Email',
      requiredErrorMsg: 'CC Email id is required'
    },
    multi_currency: {
      name: 'multi_currency',
      label: 'Select Curreny',
      requiredErrorMsg: 'Please Select Any currency'
    },
    multi_language: {
      name: 'muti_currency',
      label: 'Select Curreny',
      requiredErrorMsg: 'Please Select Any currency'
    },
    dbfetch: {
      name: 'dbfetch',
      label: 'dbfetch',
      requiredErrorMsg: 'Please Select Any dbfetch'
    },
    rulename:{
      name: 'rulename',
      label: 'Rule Name',
      requiredErrorMsg: 'Rule Name is required'
    },
    markupvalue:{
      name: 'markup',
      label: 'MarkUp',
      requiredErrorMsg: 'MarkUp is required'
    },
    markupoption:{
      name: 'markupoption',
      label: 'Carat or Price',
      requiredErrorMsg: 'Please Carat or Price'
    },
    suppliers:{
      name: 'suppliers',
      label: 'Select Supplier',
      requiredErrorMsg: 'Please Select Suppliers'
    },
    measurementlengthfrom: {
      name: 'measurementlengthfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    measurementlengthto: {
      name: 'measurementlengthto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    measurementwidthfrom: {
      name: 'measurementwidthfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    measurementwidthto: {
      name: 'measurementwidthto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    measurementheightfrom: {
      name: 'measurementheightfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    measurementheightto: {
      name: 'measurementheightto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    tablefrom: {
      name: 'tablefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    tableto: {
      name: 'tableto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    depthfrom: {
      name: 'depthfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    depthto: {
      name: 'depthto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    ratiofrom: {
      name: 'ratiofrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    ratioto: {
      name: 'ratioto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    crownheightfrom: {
      name: 'crownheightfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    crownheightto: {
      name: 'crownheightto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    crownanglefrom: {
      name: 'crownanglefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    crownangleto: {
      name: 'crownangleto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    pavheightfrom: {
      name: 'pavheightfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    pavheightto: {
      name: 'pavheightto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    pavanglefrom: {
      name: 'pavanglefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    pavangleto: {
      name: 'pavangleto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    brand:{
      name: 'brand',
      label: 'Select Brand',
      requiredErrorMsg: 'Please Select Brand'
    },
    origin:{
      name: 'origin',
      label: 'Select Origin',
      requiredErrorMsg: 'Please Select Origin'
    },
    treatment:{
      name: 'treatment',
      label: 'Select Treatment',
      requiredErrorMsg: 'Please Select Treatment'
    },
    keytosymbol:{
      name: 'keytosymbol',
      label: 'Select Key To Symbol',
      requiredErrorMsg: 'Please Select Key To Symbol'
    },
    labmeasurementlengthfrom: {
      name: 'labmeasurementlengthfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labmeasurementlengthto: {
      name: 'labmeasurementlengthto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labmeasurementwidthfrom: {
      name: 'labmeasurementwidthfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labmeasurementwidthto: {
      name: 'labmeasurementwidthto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labmeasurementheightfrom: {
      name: 'labmeasurementheightfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labmeasurementheightto: {
      name: 'labmeasurementheightto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labtablefrom: {
      name: 'labtablefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labtableto: {
      name: 'labtableto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labdepthfrom: {
      name: 'labdepthfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labdepthto: {
      name: 'labdepthto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labratiofrom: {
      name: 'labratiofrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labratioto: {
      name: 'labratioto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labcrownheightfrom: {
      name: 'labcrownheightfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labcrownheightto: {
      name: 'labcrownheightto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labcrownanglefrom: {
      name: 'labcrownanglefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labcrownangleto: {
      name: 'labcrownangleto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labpavheightfrom: {
      name: 'labpavheightfrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labpavheightto: {
      name: 'labpavheightto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labpavanglefrom: {
      name: 'labpavanglefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    labpavangleto: {
      name: 'labpavangleto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    labbrand:{
      name: 'labbrand',
      label: 'Select Brand',
      requiredErrorMsg: 'Please Select Brand'
    },
    laborigin:{
      name: 'laborigin',
      label: 'Select Growth Type',
      requiredErrorMsg: 'Please Select Growth Type'
    },
    labtreatment:{
      name: 'labtreatment',
      label: 'Select Treatment',
      requiredErrorMsg: 'Please Select Treatment'
    },
    labkeytosymbol:{
      name: 'labkeytosymbol',
      label: 'Select Key To Symbol',
      requiredErrorMsg: 'Please Select Key To Symbol'
    },
    caratrangeonefrom: {
      name: 'caratrangeonefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    caratrangeoneto: {
      name: 'caratrangeoneto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    caratrangeonemarkupvalue: {
      name: 'caratrangeonemarkupvalue',
      label: 'Enter a Value',
      requiredErrorMsg: 'Please Enter a Value'
    },
    caratrangeonemarkupoption: {
      name: 'caratrangeonemarkupoption',
      label: 'Absolute/Percentage',
      requiredErrorMsg: 'Please Select Dollar or Percentage'
    },
    caratrangetwofrom: {
      name: 'caratrangetwofrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    caratrangetwoto: {
      name: 'caratrangetwoto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    caratrangetwomarkupvalue: {
      name: 'caratrangetwomarkupvalue',
      label: 'Enter a Value',
      requiredErrorMsg: 'Please Enter a Value'
    },
    caratrangetwomarkupoption: {
      name: 'caratrangetwomarkupoption',
      label: 'Absolute/Percentage',
      requiredErrorMsg: 'Please Select Dollar or Percentage'
    },
    caratrangethreefrom: {
      name: 'caratrangethreefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    caratrangethreeto: {
      name: 'caratrangethreeto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    caratrangethreemarkupvalue: {
      name: 'caratrangethreemarkupvalue',
      label: 'Enter a Value',
      requiredErrorMsg: 'Please Enter a Value'
    },
    caratrangethreemarkupoption: {
      name: 'caratrangethreemarkupoption',
      label: 'Absolute/Percentage',
      requiredErrorMsg: 'Please Select Dollar or Percentage'
    },

    pricerangeonefrom: {
      name: 'pricerangeonefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    pricerangeoneto: {
      name: 'pricerangeoneto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    pricerangeonemarkupvalue: {
      name: 'pricerangeonemarkupvalue',
      label: 'Enter a Value',
      requiredErrorMsg: 'Please Enter a Value'
    },
    pricerangeonemarkupoption: {
      name: 'pricerangeonemarkupoption',
      label: 'Absolute/Percentage',
      requiredErrorMsg: 'Please Select Dollar or Percentage'
    },
    pricerangetwofrom: {
      name: 'pricerangetwofrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    pricerangetwoto: {
      name: 'pricerangetwoto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    pricerangetwomarkupvalue: {
      name: 'pricerangetwomarkupvalue',
      label: 'Enter a Value',
      requiredErrorMsg: 'Please Enter a Value'
    },
    pricerangetwomarkupoption: {
      name: 'pricerangetwomarkupoption',
      label: 'Absolute/Percentage',
      requiredErrorMsg: 'Please Select Dollar or Percentage'
    },
    pricerangethreefrom: {
      name: 'pricerangethreefrom',
      label: 'From',
      requiredErrorMsg: 'Please Select From value'
    },
    pricerangethreeto: {
      name: 'pricerangethreeto',
      label: 'To',
      requiredErrorMsg: 'Please Select To Value'
    },
    pricerangethreemarkupvalue: {
      name: 'pricerangethreemarkupvalue',
      label: 'Enter a Value',
      requiredErrorMsg: 'Please Enter a Value'
    },
    pricerangethreemarkupoption: {
      name: 'pricerangethreemarkupoption',
      label: 'Absolute/Percentage',
      requiredErrorMsg: 'Please Select Dollar or Percentage'
    },
    caratpricemarkups:{
      name: 'caratpricemarkups',
      label: 'Markups',
      requiredErrorMsg: 'Please Select Markups'
    },
    rule_id:{
      name: 'rule_id',
      label: 'Rule ID',
      requiredErrorMsg: 'Please Select rule_id'
    },
    overlap:{
      name: 'overlap',
      label: 'overlap',
      requiredErrorMsg: 'your rules are overlapping'
    },
    perctinclusive:{
      name: 'perctinclusive',
      label: 'perctinclusive',
      requiredErrorMsg: 'your rules are perctinclusive'
    }
  }
};
