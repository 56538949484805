import React from "react"
import styles from "../../css/style.module.css"

const ContactForm = () =>{
    return(
        <>
            <div className={`${styles.web_send_us_message} container-fluid pt-5`}>
	        <div className="container">
	            <div className="row justify-content-center">
	                <div className="col-lg-8 col-md-10 col-sm-12 text-center">
	                    <h2 className={styles.customH2Title} data-aos="fade-up" data-aos-once="true">Send us Message</h2>
	                    <p data-aos="fade-up" data-aos-delay="100" data-aos-once="true">Have a Questions? Write Us</p>
                        {/* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
                        <script>
                          {hbspt.forms.create({
                            region: "na1",
                            portalId: "8674579",
                            formId: "51d44dbb-5ad0-4d55-8196-8ab1fdf0ab7a"
                        })}
                        </script> */}
	                </div>
	            </div>
	        </div>
	    </div>
        </>
    )
}
export default ContactForm;