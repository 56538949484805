import React, { useEffect, useRef, } from "react";
import savetime from "../../Images/savetime.png"
import saveVideo from "../../videos/save.mp4"
import localTime from "../../Images/local_time.svg"
import kyc from "../../Images/kyc.svg"
import payment from "../../Images/payment.svg"  
import styles from "../../css/style.module.css"

const Savetime = () =>{
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);
    return(
        <>
            <div className={`container-fluid ${styles.bgF6f8fc}`}>
                <div className="container" id="story">
                    <div className="row align-items-center pt-8" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                        <div id="block1" className="col-md-6">
                            <h3 className={styles.titleH3}>Saves Time &amp; Increases Profit</h3>
                            <p className={`pt-3 font-size-h4 ${styles.l30}`}>Diamonds on call provides one stop platform for buying diamond. We save your time on managing multiple suppliers, coordinating time-zones, filling KYCs, managing shipments etc and increases your profit by reducing returns, wire-fee and shipment charges.
                            </p>
                        </div>
                        <div className={`col-md-6 text-center ${styles.videowhy}`}>
                            <video
                                preload="none"
                                poster={savetime}
                                id="home_video"
                                src={saveVideo}
                                muted
                                loop
                                ref={videoEl}
                            >
                            </video>
                        </div>
                    </div>
                </div>
                <div className="container pb-12">
                    <div className="row justify-content-center ">
                        <div className="col-md-6 col-lg-4  pt-8" data-aos="fade-up" data-aos-once="true">
                            <img src={localTime} alt="local time zone" title="Local time zone" />
                            <h4 className="pt-6">Local Time Zone</h4>
                            <p className="pt-4">Dedicated Executive will work in your time-zone. So, you don’t have to work late nights to contact different diamond dealers. </p>
                        </div>
                        <div className={`col-md-6 col-lg-4 ${styles.newimg} pt-8`} data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                            <img src={kyc} alt="hassle free kyc" title="hassle free kyc" />
                            <h4 className="pt-6">Single KYC</h4>
                            <p className="pt-4">Upload your KYC just once get free of hassle to provide KYC every time you buy from different diamond dealer.</p>
                        </div>
                        <div className={`col-md-6 col-lg-4 ${styles.newimg} pt-8`} data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                            <img src={payment} alt="payment and shipping" title="payment and shipping" />
                            <h4 className="pt-6">Save on Payment &amp; Shipping</h4>
                            <p className="pt-4">Order diamonds from multiple diamond dealers, but pay once and get a single consolidated shipment. </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Savetime;