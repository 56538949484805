import React ,{useEffect} from "react";
import { Grid, Typography,InputLabel } from "@material-ui/core";
import { InputField } from "../FormFields";

export default function LoginFrom(props) {
    const {
        formField: {
            email,
            cc_email,
            website_url
        },
      
    } = props;
    useEffect(() => {
        props.setFieldValue("email",props.userinfo.mail)
    },[])
    return (
        <React.Fragment>
            <Typography className="mb-2 font-weight-bolder text-primary" variant="h4" align="center">
                {props.language.IFRAME_SETUP_WEBSITE_FORM}
            </Typography>
            <p className="mb-5 text-center">
                {props.language.IFRAME_SETUP_WEBSITE_MSG1}
            </p>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.IFRAME_SETUP_WEBSITEURL} *
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                name={website_url.name}
                                label={props.language.IFRAME_SETUP_WEBSITEURL}
                                fullWidth
                                placeholder="https://"
                                
                            />
                        </Grid>
                    </Grid>
                    <hr className="w-100 m-0 mt-8" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.IFRAME_SETUP_EMAIL} *
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                name={email.name}
                                label={props.language.IFRAME_SETUP_EMAIL}
                                fullWidth
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                            <InputLabel className="font-size-h6 font-weight-bold">
                                {props.language.IFRAME_SETUP_ADDITIONAL_EMAIL} *
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <InputField
                                name={cc_email.name}
                                label={props.language.IFRAME_SETUP_ADDITIONAL_EMAIL}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
