/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {Alert } from "react-bootstrap";

export const IFrameSuspended = (props) => {

    return (
        <>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">
                   <h3>{props.language.DIAMOND_FEED_CENTER}</h3>
                   <Alert className="my-5" variant="danger">
                   {props.language.DIAMONDFEED_SUSPENDED}
                    </Alert>
                </div>
                <div className="api-home-svg api-failed"><SVG src={toAbsoluteUrl("/media/General/diamond_feed_request.svg")} /></div>
            </div>
        </>
    );
};
