import React from "react";
import {MultiSelect} from '../MultiSelect'
// import { Field } from "formik";
// import { toAbsoluteUrl } from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import PropTypes from "prop-types";
// import { at } from "lodash";
// import { useField } from "formik";

export const Shape = ({ language ,formik}) => {

    const shapeNames = [ "ROUND", "PEAR", "OVAL", "MARQUISE", "HEART", "RADIANT", "PRINCESS","EMERALD", "ASSCHER", "SQUARE RADIANT", "CUSHION BRILLIANT",  "CUSHION MODIFIED", "BAGUETTE", "EUROPEAN CUT",  "OLD MINER", "BRIOLETTE", "BULLETS","CADILLAC", "CALF","CIRCULAR BRILLIANT","CRISS","EPAULETTE","FLAME",  "FLANDERS", "HALF MOON", "HEXAGON",  "KITE", "LOZENGE", "OCTAGON", "PENTAGON", "ROSE", "SHIELD",  "SQUARE", "STAR",  "TAPERED BAGUETTE", "TAPERED BULLET", "TRAPEZOID",  "TRIANGLE", "TRILLIANT", "OTHER" ];

    const ShapeData = shapeNames.map((value, index) => ({
        id: index + 1,
        value
    }));
    return (
        <>
            
            <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-0">
                        {language.SHAPE_SHAPE}
                    </p>
                </div>
                <div className="col-lg-12 flex-wrap d-flex">
                    <MultiSelect language={language} options={ShapeData} label={"Select Shape"} name={"Shape"} formik={formik}/>
                </div>
            </div>
            {/* <div className="row mt-5 pb-10 border-bottom">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">
                        {language.SHAPE_SHAPE}
                    </p>
                </div>
                <div className="col-lg-12 flex-wrap d-flex">
                    {ShapeData.map((item) => (
                        <label
                            key={item.id}
                            className="search_btn_check iframe_setup"
                        >
                            <Field
                                type="checkbox"
                                name="Shape"
                                id={`${item.shape}_shape`}
                                value={item.value}
                            />
                            <div className="labBox selectBox ">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            {item.shape}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-uppercase text-truncate">
                                        {item.shape}
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </label>
                    ))}
                </div>
            </div> */}
        </>
    );
};
