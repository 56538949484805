import React, { useState } from "react";
// import { Field } from "formik";
// import { toAbsoluteUrl } from "../../../../_helpers";
// import SVG from "react-inlinesvg";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    Checkbox,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    FormControl,
    Select,
    makeStyles,
} from "@material-ui/core";
// import PropTypes from "prop-types";
// import { at } from "lodash";
// import { useField } from "formik";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    indeterminateColor: {
        color: "#f50057",
    },
    selectAllText: {
        fontWeight: 500,
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    variant: "menu",
};

export const MultiSelect = ({ language,options,label,name,formik }) => {
    // console.log(formik,"formik.values[name]",name,formik.values[name])
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const isAllSelected =
        options.length > 0 && selected.length === options.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            return;
        }
        setSelected(value);
        formik.setFieldValue(name,value)
    };
    return (
        <>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id={label}>
                    {label}
                </InputLabel>
                <Select
                    name={name}
                    labelId={label}
                    multiple
                    value={formik.values[name]?formik.values[name]:[]}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                >
                    {/* <MenuItem value="all" classes={{ root: isAllSelected ? classes.selectedAll : "", }} >
                        <ListItemIcon>
                            <Checkbox classes={{ indeterminate: classes.indeterminateColor, }}
                                checked={isAllSelected}
                                indeterminate={ selected.length > 0 && selected.length < options.length }
                            />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                    </MenuItem> */}
                    {options.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                            <ListItemIcon>
                                <Checkbox checked={ formik.values[name]?formik.values[name].indexOf(option.value) > -1:false } />
                            </ListItemIcon>
                            <ListItemText primary={option.value} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};
