import * as React from "react";
import { Skeleton } from "@material-ui/lab";
// import { Grid } from "@material-ui/core";
export default function SearchTableSkeleton() {
    return (
        <>
             <div className="card card-custom card-sticky gutter-b">
                <div className="card-header py-3 mb-3">
                    <div className="card-title align-items-start flex-column">
                        <Skeleton variant="text" width={120} />
                    </div>
                    <div className="card-toolbar flex-md-nowrap align-items-center">
                        <Skeleton className="mr-2" variant="rect" width={80} height={30} />
                        <Skeleton className="mr-3" variant="rect" width={40} height={30} />
                        <Skeleton className="mr-3" variant="rect" width={40} height={30} />
                        <Skeleton className="mr-2" variant="rect" width={80} height={30} />
                    </div>
                </div>
                <div className="card-body rounded bg-white">
                    <div className="table-responsive bg-white custom-scrollbar w-100">
                        <table className="table table-hover">
                            <thead className="custom_datatable_head">
                                <tr>
                                    <th><Skeleton className="mx-auto" variant="text" width={20} height={25} /></th>
                                    {Array.from(Array(19), (e, i) => {
                                        return (
                                            <th key={i}><Skeleton className="mx-auto" variant="text" width={50} height={20} /></th>
                                        );
                                    })}
                                </tr>
                            </thead>
                           <tbody >
                                {Array.from(Array(12), (e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Skeleton className="mx-auto" variant="text" width={20} height={25} /></td>
                                            <td className="text-center">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Skeleton className="mr-2" variant="circle" width={15} height={15} />
                                                    <Skeleton className="" variant="text" width={60} height={20} />
                                                    <Skeleton className="ml-2" variant="circle" width={15} height={15} />
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {Array.from(Array(3), (e, i) => {
                                                        return (
                                                            <Skeleton className="mr-3" variant="text" width={20} height={25} key={i}/>
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                            <td className="py-5"><Skeleton className="mx-auto" variant="circle" width={30} height={30} /></td>
                                            {Array.from(Array(8), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={30} height={20} /></td>
                                                );
                                            })}
                                            {Array.from(Array(2), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={100} height={20} /></td>
                                                );
                                            })}
                                            {Array.from(Array(2), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={40} height={20} /></td>
                                                );
                                            })}
                                            <td><Skeleton className="mx-auto" variant="text" width={130} height={20} /></td>
                                            {Array.from(Array(2), (e, i) => {
                                                return (
                                                    <td key={i}><Skeleton className="mx-auto" variant="text" width={40} height={20} /></td>
                                                );
                                            })}
                                            <td><Skeleton className="mx-auto" variant="text" width={130} height={20} /></td>
                                        </tr>
                                    );
                                })}
                           </tbody>
                        </table>
                    </div>
                </div>
             </div>
        </>
    );
}
