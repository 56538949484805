/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CcmodeSetupFrom from "./CcmodeSetupNew/CcmodeSetupFrom"

export const CcmodeSetupNew = (props) => {

    return (
        <>
            <div className="row bg-white align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">  
                    <CcmodeSetupFrom userinfo={props.userinfo} language={props.language}/>
                </div>
            </div>
        </>
    );
};
