import React from "react";
import { Field } from "formik";

export const Lab = ({language}) => {
    const LabData = ([
        {
            id: 1,
            lab:"GIA"
        },
        {
            id: 2,
            lab:"IGI"
        },
        {
            id: 3,
            lab:"HRD"
        },
        {
            id: 4,
            lab:"AGS"
        },
        {
            id: 5,
            lab:"OTHER"
        }
    ]);
    return (
        <>
            <div className="row mt-5 pb-10 border-bottom"> 
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.LAB_LAB}</p>
                </div>
                <div className="col-lg-12">
                    {LabData.map((item) => (
                        <label key={item.id} className="search_btn_check iframe_setup">
                            <Field type="checkbox" name="Lab" id={`${item.lab}_lab`} value={item.lab}/>
                            <div className="labBox selectBox">
                                <span>{item.lab}</span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};