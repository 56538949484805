/* eslint-disable */
import * as Yup from 'yup';
import moment from 'moment';
import kycFormModel from './kycFormModel';
const {
  formField: {
    email,
    cc_email,
    website_url
  }
} = kycFormModel;

const passworMatch =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/
const webregex = /^(https?|ftp):\/\/[^\s\/$.?#]+\/?[^\/\s]*$/;
export default [
  Yup.object().shape({
    [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
    // [cc_email.name]: Yup.string().required(`${cc_email.requiredErrorMsg}`),
    [website_url.name]: Yup.string().required(`${website_url.requiredErrorMsg}`).matches(webregex,"Provide Domain Name in Proper Format. Example: https://example.com"),
  }),

  // Yup.object().shape({
  //   [muti_currency.name]: Yup.string().required(`${muti_currency.requiredErrorMsg}`),
  // }),
  
  // Yup.object().shape({
  //   [email.name]: Yup.string().email("Wrong email format") .min(3, "Minimum 3 symbols") .max(50, "Maximum 50 symbols") .required(`${email.requiredErrorMsg}`),
  //   [password.name]: Yup.string().min(8, "Password should be of minimum 8 characters length") .required(`${password.requiredErrorMsg}`) .matches(passworMatch,"Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
  //   [cofirm_password.name]: Yup.string() .min(8, "Password should be of minimum 8 characters length").oneOf([Yup.ref("password"), null], "Password must match") .required(`${cofirm_password.requiredErrorMsg}`) .matches(passworMatch,"Password Must Contain Atleast One UpperCase, One LowerCase, One Number and special symbols"),
  // }),
];
