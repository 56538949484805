import React from "react";
import { Link } from "react-router-dom";
import styles from "../../css/style.module.css"

const Requestdemo = () =>{
    return(
        <>
            <div className="container-fluid bg-white">
                <div className="container py-12 px-6">
                    <div className={`row ${styles.jumbotron} align-items-center text-center text-md-left m-0`} data-aos="fade-up" data-aos-once="true">
                        <div className={`col-md-6 col-sm-12 ${styles.phead} pl-md-12`}>
                            <h2 className={`${styles.customH2Title} mb-3`}>Request For Demo</h2>
                            <p>Start using Diamondsoncall today by booking a demo with one of our experts.</p>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center">
                            <Link to="/registration" className={`btn ${styles.btnSecondary} m-1`}>Join now. It's free</Link>
                            <Link to="/demo-request" className={`btn ${styles.btnOutlineSecondary} m-1`}>Book a demo</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Requestdemo;