import React, { useState,useEffect } from 'react';
import { Grid, Typography,InputLabel } from '@material-ui/core';
import { InputField, CheckboxField, SelectField,PhoneInput } from '../FormFields';
import MuiUploader from '../FormFields/MuiUploader';
import axios from 'axios';
import { NODE_API_URL } from '../../../../../env_config';
const work_profile_data = [
  
  {
    value: 'CEO',
    label: 'CEO'
  },
  {
    value: 'Sales-Manager',
    label: 'Sales-Manager'
  },
  {
    value: 'Purchase-Manager',
    label: 'Purchase-Manager'
  },
  {
    value: 'account-manager',
    label: 'account-manager'
  },
  {
    value: 'secretary',
    label: 'secretary'
  },
  {
    value: 'business-development-executives',
    label: 'business-development-executives'
  },
  {
    value: 'other',
    label: 'other'
  }
];

export default function PersonalInfoForm(props) {
  const {
    formField: {
      firstName,
      lastName,
      email,
      contanct_no,
      work_profile,
      passport_id,
      passport_id_file,
    //   useAddressForPaymentDetails
    },
    setFieldValue,
    prev,
    setPassportFileName,
    FirstFile,
    setFirstFile,
    values,
    setPrev,
   } = props;
  useEffect(() => {
    if(!prev){
      const userData = JSON.parse(localStorage.getItem("userData"))
      const finalData = {
          "id":userData.CustomerId
      }
  
      axios({
          method: "POST",
          url: `${NODE_API_URL}/API/KYC/viewData`,
          headers: { "Content-Type": "application/json" },
          data: finalData,
      }).then(res => {
          if(res && res.data){
              setPrev(false)
             const GetWorkProfile = (workprofile) => {
              let id = null
              if(workprofile){
                work_profile_data.map(value => {
                  if(value.label.toLowerCase() === workprofile){
                    id = value.value
                  }
                })
              }
              // console.log(id,"idididid")
              return id
             }
            setFieldValue("firstName",res.data.fname)
            setFieldValue("lastName",res.data.lname)
            setFieldValue("email",res.data.mail)
            setFieldValue("contanct_no",res.data.mobile)
            setFieldValue("passport_id",res.data.passport_id)
            setFieldValue("work_profile",GetWorkProfile(res.data.work_profile))
            setFieldValue("company_name",res.data.cname)
            setFieldValue("address1",res.data.address)
            setFieldValue("address2",res.data.secaddress)
            setFieldValue("city",res.data.city)
            setFieldValue("state",res.data.state)
            setFieldValue("zipcode",res.data.com_zip)
            setFieldValue("country",res.data.country)
            setFieldValue("telephone",res.data.contact2)
            setFieldValue("fax",res.data.fax)
            setFieldValue("invoice_email",res.data.invoiceemail)
            setFieldValue("website",res.data.website)
            setFieldValue("comp_reg_no",res.data.com_reg_no)
            setFieldValue("nature_of_business",res.data.nature_buss)
            setFieldValue("business_est_date",res.data.buss_esta_date)
            setFieldValue("name_of_partner",res.data.director_name)
            setFieldValue("contact_of_partner",res.data.directory_contact)
            setFieldValue("shipping_address1",res.data.shipping_address)
            setFieldValue("shipping_city",res.data.city)
            setFieldValue("shipping_state",res.data.state)
            setFieldValue("shipping_zipcode",res.data.com_zip)
            setFieldValue("shipping_country",res.data.country)
            setFieldValue("shipping_telephone",res.data.shipping_phone)
            setFieldValue("shipping_email",res.data.shipping_email)
            setFieldValue("tax_id",res.data.company_tax)
            setFieldValue("port_of_discharge",res.data.port_of_discharge)
          }
      }).catch(err => {
      })
    }
  },[])
  return (
    <React.Fragment>
      <Typography className="mb-10" variant="h4" align="center">
        {props.language.PROFILEVIEW_USER_INFORMATION}
      </Typography>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={7}>
          <InputField name={firstName.name} label={props.language.PROFILE_FIRST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={lastName.name} label={props.language.PROFILE_LAST_NAME} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <InputField name={email.name} label={props.language.PROFILE_EMAIL} fullWidth disabled={true}/>
        </Grid>
        <Grid item xs={12} sm={7}>
        <InputLabel className="font-size-h6 font-weight-bold">
            {props.language.PROFILE_CONTACT_NUMBER}
          </InputLabel>
          <PhoneInput name={contanct_no.name} label={props.language.PROFILE_CONTACT_NUMBER} fullWidth onChange={(value) => setFieldValue("contanct_no",value)}/>
          
        </Grid>
        <Grid item xs={12} sm={7}>
                    <InputField name={passport_id.name} label={props.language.PROFILE_IDENTITY_NUMBER} fullWidth />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputLabel className="font-size-h6 font-weight-bold">
                        {props.language.PROFILE_IDENTITY_PROOF}
                    </InputLabel>
                    <MuiUploader
                        name={passport_id_file.name}
                        label={passport_id_file.label}
                        data={passport_id_file}
                        FirstFile={FirstFile}
                        filefield={"passport_id_file"}
                        setFirstFile={setFirstFile}
                        setPassportFileName={setPassportFileName}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                    {/* <Uploader
                        name={passport_id_file.name}
                        label={passport_id_file.label}
                        data={passport_id_file}
                        FirstFile={FirstFile}
                        filefield={"passport_id_file"}
                        setFirstFile={setFirstFile}
                        fullWidth
                    /> */}
                </Grid>
        <Grid item xs={12} sm={7}>
          <SelectField
            name={work_profile.name}
            label={props.language.PROFILE_WORK_PROFILE}
            data={work_profile_data}
            fullWidth
          />
           {/* <MuiUploader /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
