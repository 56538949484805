import React from "react";
import whyus from "../../Images/whyus.jpg"
import styles from "../../css/style.module.css"

const WhyitStarted = () =>{
    return (
        <>
            <div className={`container-fluid ${styles.parallax} py-15 mt-10`}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-6 align-self-center order-lg-2 order-sm-1 " data-aos="fade-up" data-aos-once="true">
							<h2 className={`pb-8 ${styles.customH2Title}`}>Why it Started ?</h2>
							<p>We are the 3rd generation Diamond Dealer &amp; Manufacturer. Our customers/jewelers always complains that sourcing diamond is challenging, time consuming and costly. Every time a jeweler has an order for diamond jewelry, he has to go through different diamond dealers, looking for the right diamond in different zones, which takes days and nights to coordinate. Once a jeweler finds right diamond, he can’t fly to different dealers every time to check the quality of diamond. So he places order, pay each of them separately and get separate shipments. After all the hassle, when quality doesn’t match the standard, returns become more stressful and expensive. Sometimes it costs them more than 20% of value of the diamond to ship and return. This consumes lot of time, money and energy.<br /> <br />In the automation era, why a jeweler should go through this hassle, when everything can be done in one go. With this thought, In 2016 we founded a start-up called <b>Diamonds On Call</b>. With technology and innovation, we focus on how to make Diamond buying process Easy, Fast and Secure. So no matter, where you are, we make sure that the diamond you select is quality checked and reaches you on time.</p>
						</div>
						<div className={`col-lg-6 align-self-center text-center pt-5 order-lg-1 order-sm-2 ${styles.whyitstarted}`}>
							<img src={whyus} width="450" data-aos="fade-up" data-aos-once="true" alt="why us" title="Why us" />
						</div>
					</div>
				</div>
			</div>
        </>
    )
}
export default WhyitStarted;