/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { Searchbarchurneddashboard } from "../../../layout/components/extras/dropdowns/search/SearchbarChurnedDashboard";
export const SearchBarTile = (props) => {

  
    return (
        <>
            <div className={`card card-custom gutter-b`}>
                <div className="card-body salesExecutiveTile">
                    <span className="small_title">Search Diamonds By Certificate/StockID/LoatID</span>
                    <div className="text-center mt-10">
                        <div className="natTab">
                        {props.userinfo.v_customer === "12"?<Searchbarchurneddashboard  language={props.language} userinfo={props.userinfo}/>:""}                      
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
