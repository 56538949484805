import React from 'react';
import { FaSearch } from "react-icons/fa";

export const GlobleFilter = ({filter, setFilter}) => {
    return (
        <>
            <div className="input-group globleFilter input-group-sm" >
                <input className="form-control border-right-0 input-search" value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
                <div className="input-group-append">
                    <span className="input-group-text bg-white border-left-0 line-height-0 py-0"><FaSearch /></span>
                </div>
            </div>  
        </>
    )
}

export default GlobleFilter