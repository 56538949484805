import React from "react";
import { Field } from "formik";
import {MultiSelect} from '../MultiSelect'

export const Location = ({language,formik}) => {
    const locationData = ([
        {
            id: 1,
            value:"INDIA"
        },
        {
            id: 2,
            value:"HONGKONG"
        },
        {
            id: 3,
            value:"USA"
        },
        {
            id: 4,
            value:"UAE"
        },
        {
            id: 5,
            value:"UK"
        },
        {
            id: 6,
            value:"ISRAEL"
        },
        {
            id: 7,
            value:"BELGIUM"
        },
        {
            id: 8,
            value:"OTHER"
        }
    ]);
    const DeliveryData = ([
        {
            id: 0,
            delivery:"Select"
        },
        {
            id: 1,
            delivery:"1 to 5 days"
        },
        {
            id: 2,
            delivery:"11 to 15 days"
        },
        {
            id: 3,
            delivery:"16 or more"
        }
    ]);

    return (
        <>
            <div className="row mt-5 pb-10">
                <div className="col-lg-12">
                    <p className="font-weight-bold mb-2">{language.LOCATION_LOCATION}</p>
                </div>
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-md-12 d-flex flex-wrap mb-5">
                            <MultiSelect language={language} options={locationData} label={"Select Location"} name={"Location"} formik={formik}/>
                            {/* {locationData.map((item) => (
                                <label key={item.id} className="search_btn_check iframe_setup">
                                    <Field type="checkbox" name="Location" id={`${item.value}_loc`} value={item.value}/>
                                    <div className="flourBox selectBox">
                                        <span>{item.value}</span>
                                    </div>
                                </label>
                            ))} */}
                        </div>
                        {/* <div className="col-md-12">
                            <p className="font-weight-bold mb-2">ESTIMATE SHIPPING IN DAYS</p>
                            <Field as="select" className="form-control" name="delivery">
                                {DeliveryData.map((item) => (
                                    <option key={item.id} value={item.delivery}>{item.delivery}</option>
                                ))}
                            </Field>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};