import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../_helpers";
import { useHistory } from "react-router-dom";
import { IoCloseSharp } from 'react-icons/io5';
import { Backdrop,CircularProgress,} from "@material-ui/core";
import { makeStyles ,Paper, InputBase} from '@material-ui/core';
import axios from "axios";
import { NODE_API_URL } from "../../../env_config";
import red from "material-ui/colors/red";

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
const useStyles = makeStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      boxShadow:'none!important',
      backgroundColor: '#F9FAFF',
      border:'solid 2px #E0E7FF',
      height: '36px',
    },
    input: {
      marginLeft: 8,
      flex: 1,
      fontWeight: 500,
      color : "#8798AD"
    },
    iconButton: {
      padding: 5,
      color : "#8798AD"
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
    error: {
      backgroundColor: red[600],
    },
  });
export const AddCompareDiamond = (props) => {
  const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [errormsg,setErrorMsg] = React.useState('');
    const [word,setWord] = useState('')
    const [isSubmitting ,SetIsSubmitting] = useState(false)
    const [openLoader, setOpenLoader] = React.useState(false);
    const history = useHistory();
    // const [data, setData] = useState(props.diamondselected);
    // console.log(data);
    const hideModel = () => {
        props.onHide();
    };
    const CompareClick = () => {
        props.onHide();
    };
    const closeModel = () => {
        props.onHide();
        setWord('')
        setErrorMsg(null)
    };
    const responseClose = (props) => {
        props.responseOnOk()
    };
    const AddCompare = () => {
        if(!word){
        setErrorMsg("Please Enter a Stock ID/Certificate")
        return
        }
        setOpenLoader(true)
        const userData = JSON.parse(localStorage.getItem("userData"))
        const finalData = {
          "Stock_id":word.replaceAll('-','').replaceAll('N','').replaceAll('L',''),
          "Certi_NO":word.replaceAll('-','').replaceAll('N','').replaceAll('L',''),
          "CustomerId":userData.CustomerId
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/API/searchByCertAndId`,
            headers: {
                "Content-Type": "application/json",
            },
            data: finalData,
          })
          .then(function(res) {
        setOpenLoader(false)
            console.log(res.data.data,"res.data.data")
            if(res.data.data && res.data.data.length > 0){
                const getlocalstorage = localStorage.getItem("comparestones")
        console.log(getlocalstorage,"AddCompare")
        if(getlocalstorage && word){
            let comparestones = JSON.parse(getlocalstorage)
            if(comparestones.includes(res.data.data[0].id.toString())){
              setErrorMsg("This Stone Already Available in Compare list")
              return
            }
            comparestones.push(res.data.data[0].id.toString().replaceAll('-','').replaceAll('N','').replaceAll('L',''))
            localStorage.setItem("comparestones",JSON.stringify(comparestones))
            props.setUpdate(!props.update)
            props.onHide()
            setErrorMsg(null)
            setWord('')
        }else{
            localStorage.setItem("comparestones",JSON.stringify([res.data.data[0].id.toString().replaceAll('-','').replaceAll('N','').replaceAll('L','')]))
            props.setUpdate(!props.update)
            props.onHide()
            setErrorMsg(null)
            setWord('')

        }
            }
            else{
                setErrorMsg("Stone Not Found!")
            }
            // setCompareData(res.data.data)
          })
          .catch(function(res) {
        setOpenLoader(false)
            setErrorMsg("Stone Not Found!")
          });
        
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                // size="xl"
                // backdrop="static"
                keyboard={false}
                // centered
            >
                <Modal.Header>
                    <Modal.Title>
                        Add Compare Diamond
                        <div className="text-muted font-size-sm">Enter a Stock ID/Certificate</div>
                    </Modal.Title>
                    <button type="button" className="close" onClick={() => closeModel()}><span aria-hidden="true">&times;</span><span className="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body >
                <InputBase
                    className={`${classes.input}`}
          placeholder={"Stock ID/Certificate"}
          inputProps={{ 'aria-label': 'Enter Stock ID/Certificate' }}
          onChange={(e) => {
            const re = /^[0-9-(NL)]+$/;
        
            // if value is not blank, then test the regex
        
            if (e.target.value === '' || re.test(e.target.value)) {
               setWord(e.target.value)
            }
        }}
          value={word}
          onKeyDown={(e) => e.keyCode === 13?AddCompare():""}
        />
        <br/>
        {errormsg?<div><span className="text-danger mt-2 font-weight-bold">{errormsg}</span></div>:""}
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="secondary" className="mr-2 font-weight-bold" onClick={() => closeModel(props)}>
                        Close
                    </Button>
                    <Button disabled={openLoader} className="px-8 font-weight-bold" variant="primary" onClick={() =>AddCompare()}>
                        Add Diamond
                        {/* {loading && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop
                            sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                            open={openLoader}
                            // onClick={handleCloseLoader}
                        >
                            <CircularProgress color="secondary" />
                        </Backdrop> */}
                        {openLoader && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop
                                sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                open={openLoader}
                                // onClick={handleCloseLoader}
                            >
                                <CircularProgress color="secondary" />
                            </Backdrop>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
