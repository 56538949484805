import React from "react";
import CustomerSupport from "./Whyus/CustomerSupport";
import GetinTouch from "./Whyus/GetinTouch";
import Liveinv from "./Whyus/LiveInv";
import Qualitycheck from "./Whyus/Qualitycheck";
import QuickShipp from "./Whyus/QuickShipp";
import Savetime from "./Whyus/Savetime";
import Title from "./Whyus/Title";

const Whyus = () =>{
    return (
        <>
            <title>Why Us? - Diamonds on Call Limited</title>
            <Title />
            <Savetime />
            <Liveinv />
            <Qualitycheck />
            <QuickShipp />
            <CustomerSupport />
            <GetinTouch />
        </>
    )
}
export default Whyus;